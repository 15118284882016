<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{
            'readonly': !editor?.isEditable,
            '!max-w-full': node.attrs.fullWidth,
            '!mx-24': node.attrs.fullWidth && editor.isEditable,
            'border rounded-md p-4': !loading && !error
        }"
    >
        <FileCard :loading="loading" :media="media"/>
        <CardError v-if="!loading && error"/>
    </node-view-wrapper>
</template>

<script setup>

import {onMounted, ref} from 'vue';
import {NodeViewWrapper} from '@tiptap/vue-3';
import CardError from "../../../components/ui/CardError.vue";
import FileCard from "../../../components/ui/file/FileCard.vue";

const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
})

const loading = ref(false);
const error = ref(false);
const media = ref(null);

onMounted(() => getMedia(props.node.attrs.id))

async function getMedia(id) {
    try {
        const {data} = await axios.get(`/media/${id}`)
        media.value = data
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}
</script>
