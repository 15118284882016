<template>
    <div class="flex justify-end group-hover:opacity-100">
        <Link :href="'/users/' + value.id" class="text-blue-600 group-hover:opacity-100">
            <Button variant="ghost" size="small" aria-label="Edit">
                <Icon name="Pencil"></Icon>
            </Button>
        </Link>
        <DeleteRow @onDelete="() => onDelete(value)"/>
        <Button
            v-if="value.deleted_at !== null"
            @click="() => restore(value)"
            size="small"
            variant="ghost"
            icon="pi pi-send"
            text aria-label="Resend"
        >
            <Icon name="RefreshCcw"></Icon>
        </Button>
    </div>
</template>
<script setup>
import { router, useForm } from '@inertiajs/vue3';
import { defineProps } from 'vue';
import { Link } from '@inertiajs/vue3';
import Button from '@/tiptap/components/ui/button/Button.vue';
import DeleteRow from '@/Components/DeleteRow.vue';
import { useToast } from 'primevue/usetoast';
import { displayErrorMessagesAsToast } from '@/utils/errorUtils.js';
import Icon from '@/tiptap/components/ui/Icon.vue';

const toast = useToast();
const props = defineProps({
    value: {
        type: Object
    },
    users: {
        type: Array,
    },
    onUpdate: {
        type: Function
    },
})

const form = useForm({});

function onDelete(data) {
    form.delete('/users/' + data.id, {
        preserveScroll: true,
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
        onSuccess: () => {
            const newUsers = props.users.value.map(user => {
                if (user.id === data.id) {
                    user.deleted_at = (new Date).toDateString();
                }
                return user;
            });

            props.onUpdate(newUsers)
        }
    });
}
function restore(data) {
    form.post('/users/' + data.id + '/restore', {
        preserveScroll: true,
        onSuccess: () => {
            const newUsers = props.users.value.map(user => {
                if (user.id === data.id) {
                    user.deleted_at = null;
                }
                return user;
            });

            props.onUpdate(newUsers);

            router.reload();
        }
    });
}
</script>
