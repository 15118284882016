<template>
    <AuthenticatedLayout>
        <template #title>Create account</template>
        <AccountForm
            @submit="submit"
        />
    </AuthenticatedLayout>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import {displayErrorMessagesAsToast} from '../../utils/errorUtils.js';
import AccountForm from "@/Components/accounts/AccountForm.vue";
import {router} from "@inertiajs/vue3";
import { useToast } from 'primevue/usetoast';
const toast = useToast();

const submit = (form) => {
    form.post('/accounts', {
        forceFormData: true,
        onSuccess: () => {
            form.reset()
            router.visit('/accounts')
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
};
</script>
