export const planItemStatuses = [{
    name: 'To-do',
    value: 'To-do',
    color: '#FF9A62',
}, {
    name: 'In-progress',
    value: 'In-progress',
    color: '#4E87EE',
}, {
    name: 'Blocked',
    value: 'Blocked',
    color: '#f24e1e',
}, {
    name: 'Done',
    value: 'Done',
    color: '#4ECB71',
},  ]
