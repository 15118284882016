<template>
    <div class="text-sm">
        <FormField v-slot="{ componentField }" name="action" v-if="!model.action">
            <FormItem v-auto-animate class="col-span-6">
                <FormLabel>Action</FormLabel>
                <FormControl>
                    <Select v-bind="componentField" @update:modelValue="onActionChange">
                        <FormControl>
                            <SelectTrigger>
                                <SelectValue placeholder="Select the action"/>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent style="z-index: 9999">
                            <SelectGroup>
                                <SelectItem v-for="model in actions" :value="model.id">
                                    <div class="text-sm">{{ model.name }}</div>
                                </SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </FormControl>
                <FormMessage/>
            </FormItem>
        </FormField>

        <div v-if="model.action">
            <div class="flex gap-2 items-center justify-between">
                <div class="flex items-center gap-2">
                    <Label class="p-0 text-gray-950">Action</Label>

                    <Button
                        v-if="model.action === 'external_link'"
                        @click="openExternalLink()"
                        variant="secondary"
                        button-size="small"
                        class="flex items-center text-gray-950"
                    >
                        {{model.action_name}}
                        <SquareArrowOutUpRightIcon class="w-4 h-4"/>
                    </Button>

                    <Button
                        v-if="model.action === 'request_files'"
                        @click="requestFileDialog = true"
                        variant="secondary"
                        button-size="small"
                        class="flex items-center text-gray-950"
                    >
                        Upload files

                        <CloudUpload class="w-4 h-4"/>
                    </Button>

                    <Button
                        v-if="model.action === 'file_download'"
                        @click="requestFileDialog = true"
                        variant="secondary"
                        button-size="small"
                        class="flex items-center text-gray-950"
                    >
                        Download files

                        <ArrowDownToLine class="w-4 h-4"/>
                    </Button>
                </div>

                <a
                    href=""
                    class="text-sm text-gray-500 gap-2"
                       @click="(e) => {
                         e.preventDefault();
                         removeAction()
                       }">
                    <i class="pi pi-times" style="color: #ccc; font-size: 12px"></i>
                </a>
            </div>

            <ul class="my-4" v-if="model.action === 'request_files'">
                <template v-for="file in medias" :key="file.uuid">
                    <PlanItemMediaRow
                        :media="file"
                        @delete="(id) => medias = medias.filter(m => m.id !== id)"
                    />
                </template>
            </ul>
        </div>

        <Dialog v-model:visible="externalLinkDialog" modal header="Create link CTA">
            <p class="text-gray-500">Add a call-to-action based on links</p>

            <BaseFormCard
                @submit="onSaveExternalLink"
                :loading="createLink.processing"
                save-text="Update"
                :form="createLink"
                form-class="flex items-start w-full gap-2 flex-col"
            >
                <FormField v-slot="{ componentField }" name="name"  class="w-full">
                    <FormItem v-auto-animate class="w-full">
                        <FormLabel>Link name</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField"  class="w-full"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
                <FormField v-slot="{ componentField }" name="url">
                    <FormItem v-auto-animate class="w-full">
                        <FormLabel>URL</FormLabel>
                        <FormControl>
                            <Input v-bind="componentField"/>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>

                <template #footer>
                    <div class="flex gap-2 w-full">
                        <Button class="w-full mt-4" size="small" variant="tertiary" @click="externalLinkDialog = false">
                            Cancel
                        </Button>
                        <Button class="w-full mt-4" variant="primary" size="small" type="submit" :loading="createLink.processing">
                            Save
                        </Button>
                    </div>
                </template>
            </BaseFormCard>
        </Dialog>

        <template v-if="requestFileDialog">
            <PlanItemUploader
                :title="'Upload files'"
                :description="'One or more files'"
                :todo="props.model"
                @close="requestFileDialog = null"
                @update="getMedia()"
            />
        </template>

        <template v-if="requestFileDialog">
            <PlanItemUploader
                :title="'Add files to download'"
                :description="'One or more files'"
                :todo="props.model"
                @close="requestFileDialog = null"
                @update="getMedia()"
            />
        </template>
    </div>
</template>
<script setup lang="ts">
import Button from "@/tiptap/components/ui/button/Button.vue";
import { onMounted, ref } from "vue";
import useValidatedForm from '../../../../hooks/useValidatedFormNew.js';
import { Input } from '@/Components/ui/input';
import { displayErrorMessagesAsToast } from '../../../../utils/errorUtils.js';
import * as yup from "yup";
import { Label } from "radix-vue";
import axios from "axios";
import PlanItemMediaRow from "./PlanItemMediaRow.vue";
import PlanItemUploader from "./PlanItemUploader.vue";
import { SquareArrowOutUpRightIcon, CloudUpload, ArrowDownToLine } from 'lucide-vue-next';

import BaseFormCard from "@/Components/forms/BaseFormCard.vue";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'

const actions = ref([
    {id: 'external_link', name: 'External link'},
    {id: 'file_download', name: 'File download'},
    {id: 'request_files', name: 'Request files'},
])


const props = defineProps({
    model: {
        type: Object,
        default: null
    }
})

const externalLinkDialog = ref(false);
const requestFileDialog = ref(false);
const downloadFileDialog = ref(false);

const medias = ref(props.model.media?.map(m => ({
    name: m.name,
    id: m.id,
})) || []);

onMounted(() => {
    getMedia()
})

function getMedia() {
    axios.get(`/plan-item/${props.model.id}/media`)
        .then(response => {
            medias.value = response.data;
        })
        .catch(error => {
            console.error('Error fetching media:', error);
        });
}

const schema = yup.object().shape({
    name: yup.string().required('Please enter the link name.'),
    url: yup.string().required('Please enter valid url.'),
});

const createLink = useValidatedForm({
    name: '',
    url: '',
}, schema)

const emit = defineEmits(['update']);

function onActionChange(action: string) {
    axios.post(`/plan-item/${props.model.id}/action`, {
        action: action,
    }).then(response => {
    }).catch(error => {
        console.error('Error performing action:', error);
        // Optionally handle error visually or log it
    }).finally(() => {
        // Handle UI updates based on the action performed
        if (action === 'external_link') {
            externalLinkDialog.value = true;
            medias.value = [];
            props.model.action = 'external_link';
        } else if (action === 'request_files') {
            props.model.action_name = 'Upload files';
            props.model.action = 'request_files';
        }
    });
}

function onSaveExternalLink() {
    console.log('11111', createLink.name);
    axios.post(`/plan-item/${props.model.id}/action`, {
        action: 'external_link',
        name: createLink.name,
        url: createLink.url
    }).then(response => {
        externalLinkDialog.value = false;
        props.model.action_name = createLink.name;
        props.model.action_url = createLink.url;
        props.model.action = 'external_link';
    }).catch(error => {
        // Handle errors
        displayErrorMessagesAsToast(error);
    });
}

function removeAction() {
    axios.delete(`/plan-item/${props.model.id}/action`)
        .then(() => {
            props.model.action_name = null;
            props.model.action_url = null;
            props.model.action = null;
        })
        .catch(error => {
            console.error('Failed to delete action:', error);
        });
}

function openExternalLink() {
    window.open(props.model.action_url, '_blank');
}


</script>
