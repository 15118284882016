<template>
  <div class="flex items-center gap-2">
    <input
        class="h-2 bg-neutral-200 border-0 rounded appearance-none fill-neutral-300"
        type="range"
        min="25"
        max="100"
        step="25"
        @input="handleChange"
        v-model="currentValue"
    />
    <span class="text-xs font-semibold text-neutral-500 select-none">{{ value }}%</span>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  value: Number
})

const currentValue = ref(props.value)
const emit = defineEmits(['change'])

watch(() => props.value, (newValue) => {
  currentValue.value = newValue
})

const handleChange = (event) => {
  const value = parseInt(event.target.value)
  emit('change', value)
}
</script>
