import './bootstrap';
import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import primeVuePlugin from "./primeVuePlugin.js";
import { createPinia } from 'pinia';
import VueApexCharts from "vue3-apexcharts";
import VueTailwindDatepicker from "vue-tailwind-datepicker";

createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./pages/**/*.vue', { eager: true })
        return pages[`./pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const pinia = createPinia()

        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(primeVuePlugin)
            .use(pinia)
            .use(VueTailwindDatepicker)
            .use(VueApexCharts)
            // .use({
            //     install(app) {
            //         // eslint-disable-next-line no-undef
            //         app.config.globalProperties.$route = route;
            //     },
            // })
            .mount(el)
    },
})
