import Vibrant from "node-vibrant/dist/vibrant";

export const getPalette = async (url= 'https://www.heart.org/-/media/Images/Health-Topics/Congenital-Heart-Defects/50_1683_44bc_ASD-Repairs.jpg?h=361&w=600&hash=3DAB3DD67F5AD7433CC98C2629B99224') => {
    return new Promise((resolve, reject) => {
        Vibrant.from(url).getPalette((err, palette) => {
            return resolve(transformKeys(palette));
        })
    });
};

export function rgbToHex(rgb) {
    return '#' + rgb.map(x => {
        const hex = Math.round(x).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }).join('');
}

function getRandomKey() {
    return Math.floor(Math.random() * 1000000);  // Generates a random number between 0 and 999999
}

function transformKeys(obj) {
    const newObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[getRandomKey()] = obj[key];  // Assigns old value to a new random key
        }
    }
    return newObj;
}
