<template>
    <div class="text-center my-4">
        <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"
             aria-hidden="true">
            <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"/>
        </svg>
        <h3 class="mt-2 text-sm font-semibold text-gray-900">
            {{ title }}
        </h3>
        <p class="mt-1 text-sm text-gray-500">
            {{ description }}
        </p>
        <div class="mt-6 flex justify-center" v-if="addText">
            <Button
                :label="addText"
                size="small"
                variant="outline"
                @click="emit('click')">
                <PlusIcon class="w-4 h-4"/>
                <span>
                    Invite Members
                </span>
            </Button>
        </div>
    </div>
</template>

<script setup>
import Button from "@/tiptap/components/ui/button/Button.vue";
import { PlusIcon } from "lucide-vue-next";

const props = defineProps({
    addText: {
        type: String,
    },
    title: {
        type: String,
    },
    description: {
        type: String,
    }
})

const emit = defineEmits(['click'])
</script>
