<template>
    <div class="flex items-start p-4 pt-0 gap-2 mt-auto w-full h-full">
        <form @submit.prevent="sendMessage" class="flex items-center w-full gap-2 flex-col h-full">
            <div v-if="allMessages.length === 0">
                <div class="mb-5 mt-24">
                    <img class="h-12 w-auto mx-auto" src="/images/logo_symbol.png" alt="Roombriks"/>
                </div>
                <h2 class="text-xl text-center mb-8">How can I help you today?</h2>
                <div class="grid grid-cols-2 gap-3 w-full">
                    <button class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                        <span>Summarize My Active Rooms</span>
                        <span class="text-sm text-gray-600">Display a summary of information for your active rooms</span>
                    </button>

                    <button class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                        <span>Overview of Software Adoption by Accounts</span>
                        <span class="text-sm text-gray-600">Displays the count and status of software adoption</span>
                    </button>

                    <button class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                        <span>Which customers require more attention?</span>
                        <span class="text-sm text-gray-600">Find out where you need to spend your time</span>
                    </button>

                    <button class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                        <span>Which customer questions remain unanswered?</span>
                        <span class="text-sm text-gray-600">Displays any unresolved questions in your rooms</span>
                    </button>

                </div>
            </div>
            <div class="w-full flex flex-col gap-3 overflow-y-auto max-h-full" ref="scrollContainer">
                <ChatDialogMessage
                    v-for="(message, index) in allMessages"
                    :key="index"
                    :comment="message.comment"
                    :onComplete="message.onComplete"
                />
            </div>
            <div class="flex w-full mt-auto sticky">
                <form @submit.prevent="sendMessage" class="flex items-end w-full">
                    <FormField name="name">
                        <FormItem v-auto-animate class="w-full">
                            <FormControl>
                                <div class="flex">
                                    <Input
                                        placeholder="Insert your message"
                                        v-model="input"
                                        class="rounded-r-none border-r-0"
                                    />
                                    <Button
                                        class="rounded-l-none !border-l-0"
                                        variant="secondary"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </form>
            </div>
        </form>
    </div>
</template>
<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import Button from '../../../tiptap/components/ui/button/Button.vue';
import ChatDialogMessage from "./ChatDialogMessage.vue";
import { usePage } from "@inertiajs/vue3";
import { useOpenAi } from "../../../hooks/useOpenAi";
import { FormControl, FormField, FormItem, FormMessage, } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import { useAuth } from '@/hooks/useAuth.js';
const input = ref();
const scrollContainer = ref();

const pageProps = usePage().props;
const currentPageUlid = pageProps.currentPage.ulid
const {isGemeni, isOpenAi} = useOpenAi();
const {user} = useAuth()

const allMessages = ref([

]);

watch(allMessages, scrollToBottom, { immediate: true });

function createChatMessage(message = null) {
    allMessages.value.push({
        comment: {
            comment: "",
            created_at: new Date().toISOString(),
            creator: {
                name: '',
                avatar: '/images/logo2.png'
            }
        },
        onComplete: null
    });

    return allMessages.value.length - 1;
}

function createUserMessage(message) {
    allMessages.value.push({
        comment: {
            comment: message,
            created_at: new Date().toISOString(),
            creator: {
                name: user.name,
                avatar: user.avatar
            }
        },
        onComplete: null
    });
    scrollToBottom();
}

const formatForBE = computed(() => {
    return allMessages.value.map((message) => {
        return {
            role: message.comment.creator.name === user.name ? 'user' : 'assistant',
            content: message.comment.comment,
        }
    });
});

function sendMessage() {
    const inputValue = input.value;
    input.value = ""

    const requestController = new AbortController();

    createUserMessage(inputValue);
    const index = createChatMessage();
    fetch("/gpt-prompt", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.api+json',
        },
        body: JSON.stringify({ messages: formatForBE.value, currentPageUlid: currentPageUlid}),
        signal: requestController.signal,
    })
        .then(response => {
            if (response.status !== 200) {
                toast({
                    variant: 'destructive',
                    title: 'Error',
                    description: 'Something wrong. Please check you AI credentials and try again.',
                    life: 3000,
                });
                allMessages.value.pop()
                return;
            }
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            reader.read().then(function processText({ done, value }) {
                if (done) {
                    console.log("Stream complete");
                    return;
                }

                // Decode the chunk and log it
                const chunk = decoder.decode(value, { stream: true });

                if (isGemeni) {
                    allMessages.value[index].comment.comment += chunk;
                } else {
                    getNewContent(chunk, index);
                }

                // Read the next chunk
                return reader.read().then(processText);
            });
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

function getNewContent(event, index) {
    const res = '';
    try {
        // if (event.data === CONTENT_DONE) {
        //     return CONTENT_DONE
        // }

        if (event === '[DONE]') {
            return;
        }

        event.split('\n\n').forEach((line) => {
            try {
                const data = JSON.parse(line.replace('data: ', ''))
                const content = data?.choices[0]?.delta?.content || ''

                allMessages.value[index].comment.comment += content;
                scrollToBottom();
            } catch (err) {
                // console.warn(err)
            }
        });

        return res;
    } catch (err) {
        console.error('Error parsing data from BE', err)
        // console.warn(err)
    }
}


function scrollToBottom() {
    nextTick(() => {
        if (scrollContainer.value) {
            // Scroll to the bottom of the container
            const {scrollHeight, clientHeight} = scrollContainer.value;
            scrollContainer.value.scrollTop = scrollHeight - clientHeight;
        }
    });
}

</script>
