import { ref } from 'vue';

export function useTaskActivityType() {
    const activityTypeOptions = ref([
        {label: 'Demo', value: 'demo'},
        {label: 'Proof of value / Concept', value: 'proof_of_value'},
        {label: 'Request for proposal', value: 'request_for_proposal'},
        {label: 'Workshop', value: 'workshop'},
        {label: 'Internal development', value: 'internal_development'},
        {label: 'Partner enablement', value: 'partner_enablement'},
        {label: 'Account development', value: 'account_development'},
    ])

    return {
        activityTypeOptions
    }
}
