import { format } from 'date-fns'

export default function formatDate(date, dateFormat = 'MMM dd yyyy') {
    return format(new Date(date), dateFormat)
}

export function formatISOToDate(dateString) {
    try {
        if (!dateString) {
            return null;
        }

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = months[date.getMonth()];
        const day = date.getDate().toString().padStart(2, '0');
        return `${month} ${day}, ${year}`;
    } catch (e) {
        return null;
    }
}

export function formatDateToISO(dateString) {
    try {
        if (!dateString) {
            return null;
        }

        const parts = dateString.match(/(\w{3}) (\d{2}), (\d{4})/);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthIndex = months.indexOf(parts[1]);
        const year = parts[3];
        const day = parts[2];

        // Constructing the ISO string manually to avoid time zone issues
        return `${year}-${(monthIndex + 1).toString().padStart(2, '0')}-${day}`;
    } catch (e) {
        console.log('e', e);
        return null;
    }
}
