<template>
    <div class=" rounded-t-md duration-300 transition-all overflow-hidden"
        :class="{
            'w-16': !visible,
            'w-96': visible,
            'border': visible,
        }"
    >
        <div class="bg-primary-500 py-2 flex items-center justify-between px-4 cursor-pointer"
             :class="{
                'rounded-t-md': visible,
                'rounded-full': !visible,
             }"
             @click="visible = !visible">
            <div class="flex items-center gap-2">
                <MessageCircleIcon class="h-8 w-8 cursor-pointer text-white" />
                <h1 class="text-left text-lg font-bold text-white" v-if="visible">Chat</h1>
            </div>
            <ChevronDownIcon
                v-if="visible"
                class="h-6 w-6 text-white duration-300 transition-all cursor-pointer"
                :class="{
                    'rotate-180': !visible,
                    'rotate-0': visible
                }"
                aria-hidden="true"

            />
        </div>
        <div
            class="flex flex-col bg-gray-50 duration-300 transition-all"
            :class="{
                'h-0': !visible,
                'h-[700px]': visible
            }"
        >
            <div class="flex flex-grow overflow-y-auto" ref="scrollContainer">
                <div class="flex flex-col space-y-2 p-4 mt-auto w-full">
                    <template v-for="comment in comments">
                        <MyMessage v-if="comment.email === user?.email" :comment="comment"/>
                        <SenderMessage v-else :comment="comment"/>
                    </template>
                </div>
            </div>
            <div class="flex items-center p-4 pt-0 gap-2">
                <form @submit.prevent="sendMessage" class="flex items-end w-full">
                    <FormField name="name">
                        <FormItem v-auto-animate class="w-full">
                            <FormControl>
                                <div class="flex">
                                    <Input
                                        placeholder="Insert your message"
                                        v-model="form.input"
                                        class="rounded-r-none border-r-0"
                                    />
                                    <Button
                                        class="rounded-l-none !border-l-0"
                                        variant="secondary"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </form>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ChevronDownIcon } from '@heroicons/vue/24/outline/index.js';
import { ref } from 'vue';
import { FormControl, FormField, FormItem, FormMessage, } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import { usePage } from '@inertiajs/vue3';
import SenderMessage from './SenderMessage.vue';
import MyMessage from './MyMessage.vue';
import { useAuth } from '../../hooks/useAuth.js';
import { MessageCircleIcon} from 'lucide-vue-next';
import { useRoomComments } from '../../hooks/useRoomComments.js';
import Button from '@/tiptap/components/ui/button/Button.vue';

const scrollContainer = ref(null);
const page = usePage();
const {user} = useAuth()

const { sendMessage, form, comments } = useRoomComments(scrollContainer, false);

const visible = ref(false);

</script>
