<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{'readonly': !editor?.isEditable }"
    >
        <div
            ref="resizableNode"
            class="resizable-node-container"
            :class="{
                'w-full': node.attrs.html && !node.attrs.small,
                'card-width': node.attrs.html && node.attrs.small,
              }"
            :draggable="isDraggable"
            :data-drag-handle="isDraggable"
            @click="isResizing = false"
            @mousedown="isResizing = true"
            @mouseup="isResizing = false"
        >
            <div v-if="node.attrs.html" class="relative">
                <div
                    v-html="node.attrs.html"
                    :draggable="isDraggable"
                    :data-drag-handle="isDraggable"
                >
                </div>
                <div v-if="isResizing" class="absolute top-0 w-full h-full left-0 z-10"></div>
            </div>
            <component
                v-else
                :is="node.attrs.tag || 'img'"
                v-bind="node.attrs"
                :src="src"
                :draggable="isDraggable"
                :data-drag-handle="isDraggable"
            />
        </div>
    </node-view-wrapper>
</template>

<script>
import { ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import * as FileUtils from '../../../../utils/fileUtils.js';
export default {
    components: {
        NodeViewWrapper
    },
    props: nodeViewProps,
    setup(props) {
        const resizableNode = ref(null)
        const attrs = props.node?.attrs || {}
        let originalWidth = attrs?.width || 0
        let originalHeight = attrs?.height || 0
        let aspectRatio = originalWidth / originalHeight

        let containerWidth = ref(originalWidth)
        let containerHeight = ref(originalHeight)

        useResizeObserver(resizableNode, (entries) => {
            const [entry] = entries
            let { width } = entry.contentRect
            containerWidth.value = width
            containerHeight.value = width / aspectRatio
        })
        return {
            resizableNode,
            containerWidth,
            containerHeight,
        }
    },
    data() {
        return {
            isResizing: false,
            src: this.node.attrs.src || null,
        }
    },
    computed: {
        isDraggable() {
            return this.node?.attrs?.isDraggable;
        },
        fileUuid() {
            return this.node.attrs.uuid;
        },
    },
    methods: {
        async updateSize() {
            if (this.containerWidth === 0 || this.containerHeight === 0) {
                return
            }
            await this.$nextTick()
            this.updateAttributes({ width: this.containerWidth, height: this.containerHeight })
        },
        getTransformedUrl() {
            let src = this.src || ''
            if (!this.fileUuid) {
                return
            }
            if (src?.includes('media/download') || !this.fileUuid) {
                return
            }
            this.src = FileUtils.createFileURL({
                uuid: this.fileUuid,
            })
        }
    },
    created() {
        this.width = this.node.attrs.width
        this.height = this.node.attrs.height
        this.getTransformedUrl()
    },
    watch: {
        containerWidth() {
            this.updateSize()
        },
        containerHeight() {
            this.updateSize()
        },
    }
};
</script>
<style lang="scss">
.resizable-node-container {
    @apply inline-block relative overflow-auto border-2 border-transparent;
    @apply resize-x hover:border-primary-500 max-w-full;
}

.resizable-node.readonly .resizable-node-container {
    @apply resize-none hover:border-transparent;
}

.resizable-node-container img {
    @apply m-0;
    height: inherit;
}

.resizable-node-container.card-width {
    width: 320px;
}
</style>
