<template>
  <component
      :is="icon"
      :size="size"
      :color="color"
      :stroke-width="strokeWidth"
      :default-class="defaultClass"
      class="h-4 w-4"
      v-bind="$attrs"
  />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import * as icons from "lucide-vue-next";

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  size: Number,
  color: String,
  strokeWidth: {
    type: Number,
    default: 2.5
  },
  defaultClass: String
})

const icon = computed(() => icons[props.name]);
</script>
