<template>
  <PopoverRoot>
    <PopoverTrigger asChild>
      <ToolbarButton tooltip="Set Link">
        <Icon name="Link" />
      </ToolbarButton>
    </PopoverTrigger>
    <PopoverContent>
      <LinkEditorPanel @set="emit('set')" />
    </PopoverContent>
  </PopoverRoot>
</template>
<script setup>
import { PopoverRoot, PopoverTrigger, PopoverContent } from "radix-vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import LinkEditorPanel from "@/tiptap/components/menus/panels/LinkEditor/LinkEditorPanel.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";

const emit = defineEmits(['set'])
</script>
