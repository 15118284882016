import { router, usePage } from '@inertiajs/vue3';
import * as FileUtils from '../utils/fileUtils.js';
import { displayErrorMessagesAsToast } from '../utils/errorUtils.js';


export function useBlockImage() {
    const page = usePage();
    function uploadImage(file, room, callback, errorCallback) {
        const url = room ? `/rooms/${room.ulid}/library` : '/library';
        const formData = new FormData();
        formData.append('image', file); // Assuming 'file' is a File object

        axios.post(url, formData)
            .then(response => {
                const baseUrl = page.props.tenantBaseUrl;
                const media = response.data.media;

                const url = FileUtils.createFileURL(baseUrl, {
                    uuid: media.uuid,
                });

                callback(url, response);
                return url;
            })
            .catch(error => {
                displayErrorMessagesAsToast([error.response.data.error]);
                if (errorCallback) {
                    errorCallback(error); // Ensure errorCallback is defined to handle errors
                }
            });
    }

    return {uploadImage}
}
