import { ref } from 'vue';
import { GlobeLock } from 'lucide-vue-next';

export function useRoomSharingType() {
    const shareOptions = ref([
        {
            value: 'hidden',
            name: 'Hidden',
            description: 'Not visible to anyone',
            icon: 'pi pi-eye-slash',
            iconColor: 'border-gray-400 text-gray-600 bg-gray-25'
        },
        {
            value: 'anyone',
            name: 'With link',
            description: 'Email gate, no verification',
            icon: 'pi pi-link',
            iconColor: 'border-blue-400 text-blue-600 bg-blue-25'
        },
        {
            value: 'restricted',
            name: 'Restricted',
            description: 'Only people with access can view the room',
            iconColor: 'border-red-400 text-red-600 bg-red-25',
            svgIcon: GlobeLock,
        },
        {
            value: 'public',
            name: 'Public',
            description: 'No email required to view the room. Comments and message removed',
            icon: 'pi-globe',
            iconColor: 'border-green-400 text-green-600 bg-green-25'
        },
    ]);

    return {
        shareOptions
    }
}
