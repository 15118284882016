<script setup lang="ts">
import { ref } from 'vue'
// @ts-ignore
import VueApexCharts from 'vue3-apexcharts'

const chartData = {
    series: [14],
}

const chart = ref(null)

const apexOptions = {
    series: [20],
    chart: {
        height: 350,
        type: 'radialBar',
    },
    plotOptions: {
        radialBar: {
            hollow: {
                size: '70%',
            }
        },
    },
    colors: ['#6d28d9'],
    labels: ['Lost rooms'],
}
</script>

<template>
    <div
        class="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-3"
    >
        <div>
            <div class="flex items-left flex-row w-full mb-4">
                <div class="flex items-left flex-col w-full">
                    <h4 class="text-title-md font-bold text-black dark:text-white">Lost rooms</h4>
                    <p class="text-sm font-medium text-gray-450 mt-2">Total Rooms To Completed Rooms</p>
                </div>
            </div>
            <div id="chartOne" class="-ml-5">
                <VueApexCharts
                    type="radialBar"
                    height="350"
                    :options="apexOptions"
                    :series="chartData.series"
                    ref="chart"
                />
            </div>
        </div>
    </div>
</template>
