<template>
    <node-view-wrapper
        as="div"
        class="h-full"
        :class="{
            'readonly': !editor?.isEditable,
            '!max-w-full': node.attrs.fullWidth,
            '!mx-24': node.attrs.fullWidth && editor.isEditable,
        }"
    >
        <Toast/>
        <RoomCard v-if="!error" class="relative group h-full">
            <template #header v-if="currentPrice">
                <div>
                    <RoomCardTitle>{{ currentPrice.name }}</RoomCardTitle>
                    <RoomCardDescription>{{ currentPrice.description }}</RoomCardDescription>
                </div>
            </template>
            <template #default>
                <div v-if="!loading && !error">
                    <div class="flex flex-col py-4" v-if="currentPrice.name !== ''">
                        <div class="text-gray-950 text-sm pb-3">{{ currentPrice.tooltip }}</div>
                        <div class="flex flex-col space-y-1">
                            <span class="text-gray-950 text-sm">Price</span>
                            <span class="font-bold text-lg">{{ formatPrice(currentPrice.price) }}/{{ getPriceType(currentPrice.frequency) }}</span>
                        </div>
                    </div>
                    <div v-else
                         class="min-h-20 flex items-center justify-center cursor-pointer"
                         @click="() => {
                             if (editable) {
                                showDialog = true
                            }
                         }"
                    >
                        <i class="pi pi-plus-circle text-2xl"></i>
                    </div>

                    <div class="card-buttons absolute top-[5px] right-[5px] opacity-0 group-hover:opacity-100">
                        <div class="flex">
                            <Button
                                variant="ghost"
                                @click="deleteNode"
                            >
                                <Icon name="Trash2"/>
                            </Button>
                            <Button
                                variant="ghost"
                                @click="openDialog"
                            >
                                <Icon name="Pencil"/>
                            </Button>
                        </div>
                    </div>

                    <Dialog v-model:visible="showDialog" modal header="Edit Price Card"
                            :style="{ width: '48rem', zIndex: 999 }">
                        <BaseFormCard @submit="onSubmit" :form="form">
                            <div class="flex align-items-center gap-3 mb-3">
                                <FormField v-slot="{ componentField }" name="name">
                                    <FormItem v-auto-animate class="w-full">
                                        <FormLabel>Name</FormLabel>
                                        <FormControl>
                                            <Input v-bind="componentField" class="w-full"/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                </FormField>
                            </div>
                            <div class="flex flex-col gap-3 mb-3">
                                <FormField v-slot="{ componentField }" name="description">
                                    <FormItem v-auto-animate class="col-span-6">
                                        <FormControl>
                                            <FormLabel>Description</FormLabel>
                                            <Textarea v-bind="componentField" class="w-full"/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                </FormField>
                            </div>
                            <div class="flex flex-col gap-3 mb-3">
                                <FormField v-slot="{ componentField }" name="tooltip">
                                    <FormItem v-auto-animate class="col-span-6">
                                        <FormControl>
                                            <FormLabel>Additional Information</FormLabel>
                                            <Textarea v-bind="componentField" class="w-full"/>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                </FormField>
                            </div>
                            <div class="flex align-items-center gap-3 mb-3">
                                <FormField v-slot="{ componentField }" name="price">
                                    <FormItem v-auto-animate class="w-full">
                                        <FormLabel>Price</FormLabel>
                                        <FormControl>
                                            <div class="relative">
                                                <Input type="number" step=".01" placeholder="0,00" v-model="form.price" v-bind="componentField" class="pl-5"  />
                                                <div class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">$</div>
                                            </div>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                </FormField>
                            </div>
                            <div class="flex align-items-center gap-3 mb-3">
                                <FormField v-slot="{ componentField }" name="frequency">
                                    <FormItem v-auto-animate class="w-full">
                                        <FormLabel>Frequency</FormLabel>
                                        <FormControl>
                                            <Select v-bind="componentField">
                                                <FormControl>
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select frequency"/>
                                                    </SelectTrigger>
                                                </FormControl>
                                                <SelectContent style="z-index: 99999">
                                                    <SelectGroup>
                                                        <SelectItem v-for="value in priceTypesOptions" :value="value.value">
                                                            {{ value.name }}
                                                        </SelectItem>
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                </FormField>
                            </div>
                            <template #footer>
                                <div class="flex justify-center gap-2 mt-4">
                                    <Button class="w-full mt-4" size="small" variant="secondary" @click="onClose">
                                        Cancel
                                    </Button>
                                    <Button
                                        class="w-full mt-4" :loading="form.processing" size="small" type="submit"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </template>
                        </BaseFormCard>
                    </Dialog>
                </div>
            </template>
        </RoomCard>
        <CardError v-if="!loading && error"/>
    </node-view-wrapper>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { NodeViewWrapper } from '@tiptap/vue-3';
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import { displayErrorMessagesAsToast } from '../../../../utils/errorUtils.js';
import Textarea from 'primevue/textarea';
import Button from '../../../components/ui/button/Button.vue';
import useValidatedForm from '../../../../hooks/useValidatedFormNew';
import * as yup from 'yup';
import CardError from '../../../components/ui/CardError.vue';
import Icon from '@/tiptap/components/ui/Icon.vue';
import useContentItemActions from '@/tiptap/components/menus/ContentItemMenu/composables/useContentItemActions';
import RoomCard from '../../../../pages/RoomDetail/components/RoomCard.vue';
import RoomCardTitle from '../../../../pages/RoomDetail/components/RoomCardTitle.vue';
import RoomCardDescription from '../../../../pages/RoomDetail/components/RoomCardDescription.vue';
import { formatPrice } from '../../../../utils/formatPrice.js';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'

const toast = useToast();
const showDialog = ref(false);
const error = ref(false);

const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
    updateAttributes: {
        type: Function,
        required: true,
    },
})
const actions = useContentItemActions(props.editor)
const {
    editable,
} = useRoomPage();
const currentPrice = ref(null);
const loading = ref(true);
const priceTypesOptions = ref([
    {name: 'Month', value: 'month'},
    {name: 'Quarter', value: 'quarter'},
    {name: 'Year', value: 'year'},
    {name: 'One time', value: 'one_time'},
]);

function deleteNode() {
    //actions.deleteNode()

    const { editor, getPos, node } = props;

    const from = getPos()
    const to = from + node.nodeSize

    editor.commands.deleteRange({ from, to })
}

const handleEditNode = (event) => {
    const {type, id} = event.detail;

    if (props.node.attrs.id === id && props.node.type.name === type) {
        openDialog();
    }
};

onMounted(() => {
    window.addEventListener('edit-node', handleEditNode);
})

onUnmounted(() => {
    window.removeEventListener('edit-node', handleEditNode);
})
watch(() => showDialog.value, (value) => {
    if (value) {
        getPrice(props.node.attrs.id)
    }
})

function getPriceType(value) {
    return priceTypesOptions.value.find((option) => option.value === value)?.name;
}

const schema = yup.object().shape({
    name: yup.string().required('Product name is required'),
    price: yup.number().required('Price is required'),
});

const form = useValidatedForm({
    name: undefined,
    description: undefined,
    price: undefined,
    frequency: undefined,
    tooltip: undefined,
}, schema);

onMounted(() => {
    getPrice(props.node.attrs.id)
});

function onClose() {
    showDialog.value = false;

}

async function getPrice(id) {
    try {
        const {data} = await axios.get(`/prices/${id}`)
        currentPrice.value = data
        console.log('data', data);
        form.vee.setValues(currentPrice.value)
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}

function openDialog() {
    if (!editable) {
        return;
    }

    showDialog.value = true;
}

async function onSubmit() {
    if (!editable) {
        return;
    }

    const formData = form.data();
    formData._method = 'put';
    try {
        const response = await axios({
            method: 'post',
            url: `/prices/${currentPrice.value.id}`,
            data: formData,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const priceData = response.data.price || {};
        currentPrice.value = {
            id: currentPrice.value.id,
            ...formData,
            ...priceData,
        };

        props.updateAttributes({
            id: currentPrice.value.id,
            data: JSON.stringify(currentPrice.value)
        });

        showDialog.value = false;
    } catch (err) {
        console.error('Err', err);
        showDialog.value = false;
        displayErrorMessagesAsToast(err, toast);
        throw err; // Re-throw the error if needed or simply handle it as required
    }
}


</script>
<style lang="scss">
</style>
