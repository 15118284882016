<template>
    <form @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="Email">
            <FormItem v-auto-animate>
                <FormLabel>Email</FormLabel>
                <FormControl>
                    <InputWithIcon type="email" :icon="MailIcon" v-bind="componentField"/>
                </FormControl>
                <FormMessage/>
            </FormItem>
        </FormField>
        <FormField v-slot="{ componentField }" name="Password">
            <FormItem v-auto-animate>
                <FormLabel>Password</FormLabel>
                <FormControl>
                    <InputWithIcon type="password" :icon="LockIcon" v-bind="componentField"/>
                </FormControl>
                <FormMessage/>
            </FormItem>
        </FormField>
        <FormField v-slot="{ value, handleChange }" type="checkbox" name="Remember">
            <FormItem v-auto-animate class="flex items-start space-x-2 mt-6">
                <FormControl>
                    <Checkbox :checked="value" @update:checked="handleChange"/>
                </FormControl>
                <div class="!mt-0 flex w-full justify-between">
                    <div class="mt-1">
                        <FormLabel class="pt-0">Remember me</FormLabel>
                        <FormMessage/>
                    </div>
                    <div class="text-sm leading-6">
                        <Link href="/forgot-password">
                            Forgot password?
                        </Link>
                    </div>
                </div>
            </FormItem>
        </FormField>
        <Button type="submit" class="w-full mt-8">
            Sign in
        </Button>
    </form>
</template>
<script setup>
import { h } from 'vue'
import { useForm } from 'vee-validate'
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { InputWithIcon } from '@/Components/ui/input'
import { Checkbox } from '@/Components/ui/checkbox'

import Button from "@/tiptap/components/ui/button/Button.vue";
import { LockIcon, MailIcon } from "lucide-vue-next";
import * as yup from "yup";
import { Link } from "@inertiajs/vue3";

const schema = yup.object().shape({
    Email: yup.string().email().required('Please enter your email address.'),
    Password: yup.string().min(6).required('Please enter your password.'),
    Remember: yup.boolean(),
});

const { handleSubmit } = useForm({
    validationSchema: schema,
})

const onSubmit = handleSubmit((values) => {
    toast({
        title: 'You submitted the following values:',
        description: h('pre', { class: 'mt-2 w-[340px] rounded-md bg-slate-950 p-4' }, h('code', { class: 'text-white' }, JSON.stringify(values, null, 2))),
    })
})
</script>
