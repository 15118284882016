<template>
    <div class="py-[2px] px-2 text-[10px] leading-[16px] font-semibold border uppercase rounded" :class="statusClasses">
        <slot>
            {{ humanReadableStatus }}
        </slot>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { startCase } from "lodash-es";

const props = defineProps({
    status: {
        type: String,
        required: true,
    },
})

const statusClasses = computed(() => {
    const classMap = {
        'To-do': 'text-yellow-600 border-yellow-300 bg-yellow-50',
        'In-progress': 'text-primary-600 border-primary-300 bg-primary-50',
        'Done': 'text-green-600 border-green-300 bg-green-50',
        'Blocked': 'text-red-600 border-red-300 bg-red-50',
        default: 'text-gray-600 border-gray-300 bg-gray-50'
    }
    return classMap[props.status] || classMap.default
})

const humanReadableStatus = computed(() => {
    return startCase(props.status)
})
</script>
<style>
</style>
