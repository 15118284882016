<template>
  <DropdownMenuRoot>
    <DropdownMenuTrigger v-slot="{ attrs }">
      <ToolbarButton :active="!!currentValue?.value">
        {{ currentSizeLabel }}
        <Icon name="ChevronDown" class="w-2 h-2"/>
      </ToolbarButton>
    </DropdownMenuTrigger>
    <DropdownMenuContent v-slot="{ attrs }">
      <Surface class="flex flex-col gap-1 px-2 py-4">
        <DropdownButton
            v-for="size in FONT_SIZES"
            :key="`${size.label}_${size.value}`"
            :isActive="modelValue === size.value"
            @click="selectSize(size.value)"
        >
          <span :style="{ fontSize: size.value }">{{ size.label }}</span>
        </DropdownButton>
      </Surface>
    </DropdownMenuContent>
  </DropdownMenuRoot>
</template>

<script setup>
import { computed } from 'vue';
import { DropdownMenuContent, DropdownMenuRoot, DropdownMenuTrigger } from "radix-vue";
import Surface from "@/tiptap/components/ui/Surface.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import DropdownButton from "@/tiptap/components/ui/dropdown/DropdownButton.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";

const props = defineProps({
  modelValue: String,
});

const FONT_SIZES = [
  { label: 'Smaller', value: '12px' },
  { label: 'Small', value: '14px' },
  { label: 'Medium', value: '' },
  { label: 'Large', value: '18px' },
  { label: 'Extra Large', value: '24px' },
];

const currentValue = computed(() =>
    FONT_SIZES.find((size) => size.value === props.modelValue)
);
const currentSizeLabel = computed(() =>
    currentValue.value?.label.split(' ')[0] || 'Medium'
);

const emit = defineEmits(['update:modelValue']);
const selectSize = (size) => {
  emit('update:modelValue', size)
};
</script>
