<template>
    <div class="flex self-start flex-row-reverse gap-2 group w-full">
        <div class="flex flex-col w-full">
            <p class="text-xs text-gray-500 mt-0.5 text-start mb-1">{{ creator }}</p>
            <div class="flex items-start self-start rounded-xl rounded-tl text-gray-600 break-words">
                <div v-if="comment.comment !== ''" class="text-left flex flex-col items-start justify-end" v-html="comment.comment"></div>
                <div v-else class="animate-spin"><Loader /></div>
            </div>
            <p class="text-xs text-gray-500 mt-0.5 opacity-0 group-hover:opacity-100 text-left">{{ createdAt }}</p>
        </div>
        <BaseAvatar
            v-if="props.comment.creator.name !== ''"
            :src="props.comment.creator?.avatar"
            :name="creator"
            class="w-8 h-8 rounded-full mt-1 p-0.5"
        />

        <img v-else :src="props.comment.creator?.avatar" class="w-8 mt-1 p-0.5"  alt=""/>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import BaseAvatar from '../../../Components/forms/BaseAvatar.vue';
import { Loader } from "lucide-vue-next";
const props = defineProps({
    comment: {
        type: Object,
        required: true,
    },
    onComplete: {
        type: Function,
        default: null
    }
});

const displayedMessage = ref('');

function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert 24-hour time to 12-hour format
    const twelveHourFormatHours = hours % 12 || 12;

    return `${year}/${month}/${day} ${twelveHourFormatHours}:${minutes}:${seconds} ${ampm}`;
}

const createdAt = formatDateTime(new Date(props.comment.created_at));
const creator = props.comment.creator?.name || props.comment.email;
</script>

<style scoped>
.chat-message {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}
.self-end {
    align-items: flex-end;
}
.self-start {
    align-items: flex-start;
}
</style>
