<template>
    <form @submit.prevent="onInsertMember">
        <div class="flex items-end">
            <FormField v-slot="{ componentField }" name="email" class="w-full">
                <FormItem v-auto-animate class="w-full" >
                    <FormLabel>Emails</FormLabel>
                    <FormControl>
                        <div class="flex">
                            <Input type="text" v-bind="componentField" placeholder="Add emails separated by comma" class="rounded-r-none border-r-0"/>
                            <Button
                                class="rounded-l-none !border-l-0"
                                variant="secondary"
                                type="submit"
                            >
                                Add
                            </Button>
                        </div>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
        </div>
    </form>
</template>
<script setup lang="ts">
import Button from '../../../tiptap/components/ui/button/Button.vue';
import useValidatedForm from '../../../hooks/useValidatedFormNew';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Form} from '@/Components/ui/form'
import { Input } from "@/Components/ui/input";

const emailForm = useValidatedForm({
    email: '',
}, {})

const emit = defineEmits(['onInsertMember'])

function onInsertMember() {
    emit('onInsertMember', emailForm.data().email || "")

    emailForm.vee.resetForm()
}

</script>
