<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{
            'readonly': !editor?.isEditable,
            '!max-w-full': node.attrs.fullWidth,
            '!mx-24': node.attrs.fullWidth && editor.isEditable,
            'border rounded-md p-4': !loading && !error
        }"
    >
        <div class="flex justify-center items-center flex-col gap-4 w-full" v-if="!currentMedia">
            <label :for="uploadId" class="flex flex-col gap-2 items-center justify-center" v-if="editable">
                <Icon name="Paperclip" :size="24" class="text-gray-400  w-5 h-5"/>
                <span
                    class="cursor-pointer flex group items-center justify-center border-transparent gap-2 text-sm font-semibold rounded-md disabled:opacity-50 whitespace-nowrap text-neutral-900 dark:text-white hover:bg-neutral-100 active:bg-neutral-200 dark:hover:bg-neutral-900 dark:active:bg-neutral-800 false py-2 px-3 border border"
                >
                    {{uploading ? 'Uploading... ' : 'Upload a file'}}
                </span>
            </label>

            <input
                :id="uploadId"
                name="file-upload"
                type="file"
                class="sr-only"
                @change="onImageUpload"
                accept='"image/*,.pdf,application/msword,application/zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain"'
            >
        </div>
        <FileCard :loading="loading" :media="currentMedia" :can-preview="false"/>
        <CardError v-if="!loading && error"/>
    </node-view-wrapper>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import CardError from '../../../components/ui/CardError.vue';
import { NodeViewWrapper } from '@tiptap/vue-3';
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';

import Icon from '@/tiptap/components/ui/Icon.vue';
import { useTipTapStore } from '../../../stores/useTipTapStore.js';
import { displayErrorMessagesAsToast } from '../../../../utils/errorUtils.js';
import { useForm } from '@inertiajs/vue3';
import FileInfo from "../../../../Components/FileInfo.vue";
import FileCard from "../../../components/ui/file/FileCard.vue";

const showDialog = ref(false);
const error = ref(false);
const uploadId = `file-upload-${new Date().getTime()}`;
const roomUlid = useTipTapStore().currentRoomUlid;
const uploading = ref(false);
const currentMedia = ref(null);
const form = useForm({
    file: '',
})

onMounted(() => getFile(props.node.attrs.id))

function onImageUpload(event) {
    uploading.value = true;
    loading.value = true;
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('_method', 'put');
    formData.append('file', file);
    formData.append('room_ulid', roomUlid);

    axios({
        method: 'post',
        url: `/files/${props.node.attrs.id}`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    }).then(response => {
        getFile(props.node.attrs.id);
    }).catch(error => {
        displayErrorMessagesAsToast(error);
    }).finally(() => {
        loading.value = false;
        uploading.value = false;
    });
}

const props = defineProps({
    editor: Object,
    node: Object,
    form: Object,
    onSubmit: Function,
    updateAttributes: {
        type: Function,
        required: true,
    },
})
const { editable } = useRoomPage();

const loading = ref(false);

async function getFile(id) {
    try {
        const {data} = await axios.get('/files/' + id)
        currentMedia.value = data?.media?.[0];
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

</script>
<style lang="scss">
</style>
