<template>
    <div class="flex flex-wrap gap-2 text-center justify-center text-gray-950" v-if="value.plans.length > 0">
        {{ planPercentage(value) }}%
        <div class="h-2 bg-gray-200 rounded-md w-full relative">
            <div class="bg-blue-500 h-2 rounded-md" :style="{'width': `${planPercentage(value)}%`}"></div>
        </div>
        <ProgressBar :value="planPercentage(value)" style="height: 6px"></ProgressBar>
    </div>
</template>
<script setup>
import { flatten } from 'lodash-es';
import 'vue3-circle-progress/dist/circle-progress.css';
import CircleProgress from 'vue3-circle-progress';
import ProgressBar from 'primevue/progressbar';

const props = defineProps({
    value: {
        type: String
    }
})

function planPercentage(room) {
    let planItems = getPlanItems(room)
    const completed = planItems.filter((item) => item.completed_at !== null)
    const result = completed.length / planItems.length * 100;

    if (isNaN(result)) {
        return 0;
    }

    return Math.round(result)
}

function planTooltip(room) {
    let planItems = getPlanItems(room)

    const completed = planItems.filter((item) => item.completed_at !== null)

    if (completed.length === 0 && planItems.length === 0) {
        return 'no items completed'
    }

    return `${completed.length} of ${planItems.length} items completed`
}

function getPlanItems(room) {
    let planItems = [];
    room.plans.map((plan) => {
        return planItems.push(plan.items);
    });

    return flatten(planItems)
}

</script>
