<script setup lang="ts">
import { ref } from 'vue'
import Icon from "../../../../vendor/laravel/nova/resources/js/components/Icons/Icon.vue";
import { ClockIcon, CoinsIcon, EyeIcon, RefreshCwIcon } from "lucide-vue-next";
import RoomsIcon from '../../Layouts/icons/RoomsIcon.svg'
const cardItems = ref([
  {
    icon: EyeIcon,
    title: 'Total Room Views',
    total: '20,000',
    bgColor: 'bg-yellow-200',
    color: 'text-yellow-500'
  },
  {
    icon: CoinsIcon,
    title: 'Value Of All Rooms',
    total: '15 milion',
    bgColor: 'bg-green-200',
    color: 'text-green-500'
  },
  {
    icon: ClockIcon,
    title: 'Avg Time Spent',
    total: '15 minutes',
    bgColor: 'bg-amber-200',
    color: 'text-amber-500'
  },
  {
    icon: RoomsIcon,
    title: 'Total Users',
    total: '3.456',
    bgColor: 'bg-teal-200',
    color: 'text-teal-500'
  }
])
</script>

<template>
  <!-- Card Item Start -->
  <div
    v-for="(item, index) in cardItems"
    :key="index"
    class="rounded-sm border border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark"
  >
      <div class="flex justify-between items-top">
          <div class="flex p-2 rounded-full left w-12 h-12 items-center justify-center" :class="item.bgColor">
            <component :is="item.icon" class="flex h-6 w-6 items-center justify-center " :class="item.color" />
          </div>
          <div class="flex flex-col items-end justify-between">
              <span class="text-sm font-medium text-gray-450">{{ item.title }}</span>
              <h4 class="text-title-md font-bold text-black dark:text-white">{{ item.total }}</h4>
          </div>
      </div>
      <div class="border-t border-gray-400 pt-3 mt-3.5 text-sm">
          <button>
              <RefreshCwIcon class="h-4 w-4 inline-block mr-1.5 text-gray-400" />
              Update now
          </button>
      </div>
  </div>
  <!-- Card Item End -->
</template>
