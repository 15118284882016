<script lang="ts" setup>
import { type HTMLAttributes, computed } from 'vue'
import type { StepperDescriptionProps } from 'radix-vue'
import { StepperDescription, useForwardProps } from 'radix-vue'

import { cn } from '@/tiptap/lib/utils'

const props = defineProps<StepperDescriptionProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardProps(delegatedProps)
</script>

<template>
  <StepperDescription v-slot="slotProps" v-bind="forwarded" :class="cn('text-xs text-gray-400', props.class)">
    <slot v-bind="slotProps" />
  </StepperDescription>
</template>
