<template>
  <Surface class="flex items-center gap-2 p-2">
    <a :href="url" target="_blank" rel="noopener noreferrer" class="text-sm underline">
      {{ url }}
    </a>
    <ToolbarDivider/>
    <Tooltip title="Edit link">
      <ToolbarButton @click="emit('edit')">
        <Icon name="Pen"/>
      </ToolbarButton>
    </Tooltip>
    <Tooltip title="Remove link">
      <ToolbarButton @click="emit('clear')">
        <Icon name="Trash2"/>
      </ToolbarButton>
    </Tooltip>
  </Surface>
</template>
<script setup>
import ToolbarDivider from "@/tiptap/components/ui/toolbar/ToolbarDivider.vue";
import Tooltip from "@/tiptap/components/ui/tooltip/Tooltip.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import Surface from "@/tiptap/components/ui/Surface.vue";
import TooltipProvider from "@/tiptap/components/ui/tooltip/TooltipProvider.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['edit', 'clear'])
</script>
