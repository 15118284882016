import {Node} from '@tiptap/pm/model'
import {Editor} from '@tiptap/core'
import {ref} from "vue";

export const currentNode = ref<Node | null>(null)
export const currentNodePos = ref<number>(-1)
export const useData = () => {
    function handleNodeChange(data: { node: Node | null; editor: Editor; pos: number }) {
        if (data.node) {
            currentNode.value = data.node
        }
        currentNodePos.value = data.pos
    }

    return {
        currentNode,
        currentNodePos,
        handleNodeChange,
    }
}
