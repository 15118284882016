<template>
  <div
      v-bind="$attrs"
      :class="surfaceClass"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  withShadow: {
    type: Boolean,
    default: true,
  },
  withBorder: {
    type: Boolean,
    default: true,
  },
})

const surfaceClass = computed(() => {
  return [
    'bg-white rounded-lg dark:bg-black',
    props.withShadow ? 'shadow-sm' : '',
    props.withBorder ? 'border border-neutral-200 dark:border-neutral-800' : '',
  ].filter(Boolean).join(' ')
})
</script>
