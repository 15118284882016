import { useEditor } from '@tiptap/vue-3'

import { ExtensionKit } from '@/tiptap/extensions/extension-kit'
import { useTipTapStore } from "@/tiptap/stores/useTipTapStore";
import { useRoomPage } from '@/pages/RoomDetail/hooks/useRoomPage.js';
import axios from "axios";
import { debounce } from 'lodash-es';
import { usePage } from "@inertiajs/vue3";


import { nextTick, ref } from "vue";
export const useBlockEditor = (content = null, onUpdate, shouldScroll = false) => {
    const {setDirty, setSaving} = useTipTapStore();
    const page = usePage();

    const {
        editable,
    } = useRoomPage();

    const editor = useEditor(
        {
            content: '',
            autofocus: true,
            extensions: [
                ...ExtensionKit(),
            ],
            editable: editable.value,
            editorProps: {
                attributes: {
                    autocomplete: 'off',
                    autocorrect: 'off',
                    autocapitalize: 'off',
                    class: 'min-h-full overflow-x-hidden'
                },
            },
            onCreate({editor}) {
                setContent(content);
                setDirty(false);
                setTimeout(() => {
                    shouldScroll && window.scrollTo(0, 0);
                }, 50);
            },

            onUpdate({editor, transaction}) {
                if (!editable.value) {
                    return;
                }
                setDirty(true);
                saveContent({editor, transaction});
                // lastTransaction.value = transaction;
            },
        },
    )

    const characterCount = editor.value?.storage.characterCount || {
        characters: () => 0,
        words: () => 0
    }

    function deletePlan(planId) {
        try {
            const response = axios.delete(`/plans/${planId}`);
        } catch (e) {
            console.log('error', e);
        }
    }

    function deleteHeader(headerId) {
        try {
            const response = axios.delete(`/headers/${headerId}`);
        } catch (e) {
            console.log('error', e);
        }
    }


    function deleteFileCard(id) {
        try {
            const response = axios.delete(`/files/${id}`);
        } catch (e) {
            console.log('error', e);
        }
    }

    function deletePriceCard(id) {
        try {
            const response = axios.delete(`/prices/${id}`);
        } catch (e) {
            console.log('error', e);
        }
    }

    function deleteQuestion(id) {
        try {
            const response = axios.delete(`/questions/${id}`);
        } catch (e) {
            console.log('error', e);
        }
    }

    function deleteContactCard(id) {
        try {
            const response = axios.delete(`/contacts/${id}`);
        } catch (e) {
            console.log('error', e);
        }
    }

    const saveContent = debounce(async ({transaction, editor}) => {
        if (!editable) {
            return;
        }

        setSaving(true);

        const json = editor.getJSON();

        if (isInitialDocument(json)) {
            //handleInitialDocumentState(json);
            setSaving(false);
            return;
        }

        if (transaction) {
            const {removedNodeIds} = processDocumentTransactions(transaction);

            for (let id of removedNodeIds) {
                await handleNodeDeletion(id, transaction);
            }
        }

        if (onUpdate) {
            onUpdate(json);
        }
    }, 2000);

    async function handleNodeDeletion(id, transaction) {
        // Find the node and its type in the initial document
        let node;
        let found = false;

        transaction.before.descendants((n, pos) => {
            if (n.attrs.id === id) {
                node = n;
                found = true;
                return false; // Stop searching once the node is found
            }
        });

        if (!found || !node) {
            console.error('Node not found for deletion:', id);
            return;
        }

        // Determine action based on the node type
        switch (node.type.name) {
            case 'project-plan':
                await deletePlan(id);
                break;
            case 'price-card':
                await deletePriceCard(id);
                break;
            case 'question':
                await deleteQuestion(id);
                break;
            case 'contact-card':
                //await deleteContactCard(id);
                break;
            case 'header':
                await deleteHeader(id);
                break;
            case 'file-card':
                await deleteFileCard(id);
                break;
            default:
                console.warn('Unhandled node type for deletion:', node.type.name);
                break;
        }
    }


    function processDocumentTransactions(transaction) {
        const existingNodeIds = new Set();
        const initialNodeIds = new Set();
        const removedNodeIds = [];

        // Gather IDs from the final state of the document
        transaction.doc.descendants((node, pos) => {
            if (node.attrs.id) {
                existingNodeIds.add(node.attrs.id);
            }
        });

        // Gather IDs from the initial state of the document
        transaction.before.descendants((node, pos) => {
            if (node.attrs.id) {
                initialNodeIds.add(node.attrs.id);
            }
        });

        // Determine which nodes were removed
        initialNodeIds.forEach(id => {
            if (!existingNodeIds.has(id)) {
                removedNodeIds.push(id);
            }
        });

        return {removedNodeIds};
    }


    function isInitialDocument(json) {
        return JSON.stringify(json) === '{"type":"doc","content":[{"type":"paragraph","attrs":{"class":null,"textAlign":"left"}}]}';
    }

    function handleInitialDocumentState(json) {
        toast({
            variant: 'destructive',
            title: 'Cannot save empty document',
            description: 'Please add content to the document before saving.',
            life: 3000
        });

        //console.log('page.props.currentPage.ulid', page.props.currentPage.ulid);
        axios.post('/to-slack', {
            content: json,
            currentPage: page.props.currentPage.ulid,
        });
    }

    const setContent = (content) => {
        if (!editor.value) {
            return;
        }

        try {
            const data = JSON.parse(content);
            if (data !== null) {
                decodeContent(data);
            }
        } catch (e) {
            decodeContent(content)
        }
    }

    const isValidContent = (content, schema) => {
        try {
            const doc = schema.nodeFromJSON(content);
            return doc && doc.type === schema.topNodeType;
        } catch (error) {
            console.error('Invalid content:', error);
            return false;
        }
    };

    const decodeContent = (content) => {
        if (!isValidContent(content, editor.value.schema)) {
            axios.post('/to-slack', {
                content: content,
                currentPage: page.props.currentPage.ulid
            });
        }

        editor.value.commands.setContent(content);
    }

    return {
        editor,
        characterCount,
        setContent
    }
}
