<template>
    <div class="flex min-h-screen auth-layout">
        <Toast />
        <Toaster />
        <header class="relative hidden w-0 flex-1 lg:block bg-blue-50 border-r border-gray-200">
            <div class="h-full w-full flex justify-center lg:justify-start lg:ml-[15%] items-center">
                <div class="w-full max-w-2xl flex flex-col z-10 px-6">
                    <div class="flex items-center space-x-2 -mt-32">
                        <img class="h-14 z-10" src="/images/logo.png" alt="logo">
                    </div>
                    <h2 class="mt-8 text-gray-700 text-3xl">
                        Revolutionizing Sales Management with Interactive Collaboration Rooms
                    </h2>
                    <ul class="text-gray-700 font-light mt-12">
                        <li
                            v-for="title in headLines"
                            :key="title"
                            class="flex space-x-2"
                        >
                            <CheckIcon class="min-w-[16px] h-4 mt-1"/>
                            <span>{{ title }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
        <div class="flex flex-1 flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 z-50" :class="props.class">
            <div class="mx-auto w-full max-w-md lg:w-96 flex justify-center">
                <div class="w-full max-w-[540px] mt-20">
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {CheckIcon} from '@heroicons/vue/24/outline'

import { watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { Toaster } from "@/Components/ui/toast";
const page = usePage();

const props = defineProps({
    class: {
        type: String,
        default: '',
    },
});

function showToast(message) {
    toast({ description: message });
}

// Watch for changes in the flash messages
watch(() => page.props.flash, (flash) => {
    if (flash && flash.success) {
        showToast(flash.success);
    }
}, { immediate: true, deep: true });

const headLines = [
    'Break silos with customers, sales, and customer success in a single app.',
    'Share proposals, interactive plans, documents, media and more in one place.',
    'Simplifying the buyers\' purchasing process, streamlining onboarding, and empowering sellers to deliver value',
]

</script>

<style>
.auth-layout a {
    @apply text-blue-600 no-underline hover:underline;
}
</style>
