export function formatPrice(value, options = {}) {
    if (value === null || value === undefined) {
        return ''
    }

    const formatOptions = {
        style: 'currency',
        currency: 'USD',
        ...options,
    }
    const formatter = new Intl.NumberFormat('en-US', formatOptions)

    return formatter.format(value)
}
