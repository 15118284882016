import { usePage } from '@inertiajs/vue3';
import { useRoomPage } from '../pages/RoomDetail/hooks/useRoomPage.js';

export function useAuth() {
    const {
        editable,
    } = useRoomPage();

    const page = usePage();
    const user = page.props.auth.user;
    function isAdmin() {
        return page.props.auth.role === 'admin';
    }

    function isViewer() {
        return page.props.auth.role === 'viewer';
    }

    function isCollaborator() {
        return page.props.auth.role === 'collaborator';
    }

    function isUser() {
        return page.props.auth.role === 'user';
    }

    function isPublicRole() {
        return page.props.auth.role === 'public';
    }

    function canModifyRoom(room) {
        return isAdmin() || room.created_by === user.id || isUser();
    }

    function canModifyAccount(account) {
        return isAdmin() || account.created_by === user.id;
    }

    function canModifyPlanItem() {
        return editable.value || isCollaborator() || isUser();
    }

    function canAddQuestionComments() {
        return editable.value || isCollaborator() || isUser();
    }

    function canDeletePlanItem() {
        return editable.value && !isCollaborator();
    }

    function canChat() {
        return !isPublicRole();
    }

    return {
        user,
        isAdmin,
        canModifyRoom,
        canModifyAccount,
        isViewer,
        isCollaborator,
        canModifyPlanItem,
        canChat,
        canAddQuestionComments,
    };
}
