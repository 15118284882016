<template>
    <BaseFormCard
        :loading="form.processing"
        :form="form"
        :save-text="account.id ? 'Update': 'Create'"
        @submit="onSubmit"
        @cancel="onCancel"
    >
        <FormCardSection title="Account details" container-class="-mt-4">
            <FormField v-slot="{ componentField }" name="name">
                <FormItem v-auto-animate class="col-span-6">
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                        <Input v-bind="componentField" autofocus/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="website">
                <FormItem v-auto-animate class="col-span-6">
                    <FormLabel>Website</FormLabel>
                    <FormControl>
                        <Input v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>

            <div class="col-span-6">
                <label for="avatar">Avatar</label>
                <div class="mt-2">
                    <AvatarUpload
                        :can-crop="false"
                        v-model="form.avatar"
                        :name="form.name"
                        @update:modelValue="(e) => {
                            form.vee.setFieldValue('avatar', e)
                        }"
                    />
                </div>
            </div>

        </FormCardSection>
    </BaseFormCard>
</template>
<script setup>
import {usePage} from '@inertiajs/vue3';
import * as yup from 'yup';
import AvatarUpload from '@/Components/forms/AvatarUpload.vue';
import BaseFormCard from "@/Components/forms/BaseFormCard.vue";
import FormCardSection from "@/Components/forms/FormCardSection.vue";
import useValidatedForm from '../../hooks/useValidatedFormNew';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Form} from '@/Components/ui/form'
import { Input } from "@/Components/ui/input";

const page = usePage();
const account = page.props?.account || {};
const props = defineProps({
    onCancel: {
        type: Function,
        default: null
    },
    name: {
        type: String,
        default: null
    }
})
let schema = yup.object().shape({
    name: yup.string().required('Please enter the name'),
    website: yup.string().required('Please enter the website name'),
});

const initialValues = {
    name: account.name || props.name,
    avatar: account.avatar || '',
    website: account.website || '',
};

const form = useValidatedForm(initialValues, schema);

const emit = defineEmits(['submit'])

function onSubmit() {
    form.post('/add-app-sumo-code', {
        onFinish: () => form.reset('password'),
        onSuccess:(data) => {
            localStorage.setItem('isSidebarVisible', 'true');
            window.location.href = data.props.flash.redirectUrl;
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
}


</script>
