<template>
    <div class="flex justify-end">
        <div class="flex gap-2 text-sm">
            <Button
                variant="ghost"
                @click="showChat = true"
            >
                <ChatBubbleLeftEllipsisIcon class="w-4 h-4"></ChatBubbleLeftEllipsisIcon>
                <span>Chat</span>
            </Button>
            <Button
                variant="ghost"
                @click="showShareModal = true"
            >
                <ShareIcon class="w-4 h-4"></ShareIcon>
                <span>Share</span>
            </Button>
        </div>

        <Dialog v-model:visible="showChat" modal :header="`Chat for ${currentPageName} page (${chatModel})`" style="height: calc(100% - 100px)" content-class="h-full">
            <ChatDialog />
        </Dialog>

        <Dialog v-model:visible="showShareModal" modal :header="`Share ${room.name}`" style="z-index:100;">
            <RoomShareDialog @onClose="showShareModal = false"/>
        </Dialog>
    </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { ChatBubbleLeftEllipsisIcon, ShareIcon } from '@heroicons/vue/24/outline/index.js';
import Button from '../../../tiptap/components/ui/button/Button.vue';
import { useTipTapStore } from '@/tiptap/stores/useTipTapStore';
import ChatDialog from './ChatDialog.vue';
import { useOpenAi } from '../../../hooks/useOpenAi.js';
import RoomShareDialog from './RoomShareDialog.vue';

const page = usePage();

const currentPageName = page.props.currentPage.name;
const showShareModal = ref(false);
const showChat = ref(false);
const room = ref(page.props.room);
const tipTapStore = useTipTapStore();

const emit = defineEmits(['save']);
const saving = ref(false);
tipTapStore.$subscribe(function (state) {
    saving.value = tipTapStore.saving;
})

const {isGemeni, isChatGPT} = useOpenAi();

const chatModel = computed(() => {
    if (isGemeni) {
        return 'Gemeni';
    }

    if (isChatGPT) {
        return 'ChatGPT';
    }

    return 'None';
});

</script>

<style>
.p-chips-multiple-container {
    border-radius: 0 !important;
}
</style>
