import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import HeaderSpinnedNodeTemplate from "./HeaderSpinnedNodeTemplate.vue";
import HeaderNodeTemplate from "./HeaderNodeTemplate.vue";

export const HeaderSpinnerNode = Node.create({
    name: 'header-spinner',

    group: 'block',

    addAttributes() {
        return {
            id: {
                default: null,
            },

            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            inline: {
                default: false,
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'spinner'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['spinner', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(HeaderSpinnedNodeTemplate);
    },

    addCommands() {
        return {
            setSpinner: ({id}) => ({ commands, tr, dispatch, chain }) => {
                const { selection } = tr

                const node = this.type.create({id})

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return node
            },
        }
    },
});

export default HeaderSpinnerNode
