import { useForm } from "@inertiajs/vue3";
import { FormContext, useForm as useVeeForm, validate, YupSchema } from 'vee-validate'
import { InertiaForm } from "@inertiajs/vue3/types/useForm";


type ValidatedFormType<T extends Record<string, any>> = {
    vee: FormContext<any, any>;
    validate: () => Promise<any>;
} & InertiaForm<T>;

export default function useValidatedForm<T extends Record<string, any>>(formObject: Record<any, any>, validationObject: YupSchema|{}, ignoreKeys = []): ValidatedFormType<T> {
    let form = useForm(formObject);

    const veeForm = useVeeForm({
        validationSchema: validationObject,
        initialValues: formObject,
    })

    const { values, validate } = veeForm

    return new Proxy(form, {
        get(target, prop) {
            for (let key in values) {
                if (ignoreKeys.includes(key)) {
                    continue;
                }
                target[key] = values[key];
            }

            if (prop === 'vee') {
                return veeForm
            }

            if (prop === "validate") {
                return async (...args) => {
                    return await validate();
                };
            }

            // @ts-ignore
            return target[prop];
        },
    }) as ValidatedFormType<T>;
}
