export async function fileToDataURL(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

export function bytesToMegaBytes(sizeInBytes) {
    return (sizeInBytes / Math.pow(1024, 2))
}

export function createFileURL(baseUrl, file, thumbnail = false) {
    if (!file.url) {
        file.url = `${baseUrl}/media/preview/${file?.uuid}`
    }

    let fileUrl = file.url
    if (thumbnail) {

        fileUrl = `${baseUrl}/media/thumbnail/${file?.uuid}`
    }

    return fileUrl
}

export async function createBlobFromUrl(url) {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    return await response.blob();
}

export function blobToBase64(blob, callback) {
    const reader = new FileReader();
    reader.onloadend = function() {
        // Call the callback function with the result
        callback(reader.result);
    };
    reader.readAsDataURL(blob);
}

export function base64ToFile(base64, filename, contentType) {
    // Decode the base64 string
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    // Create a Blob object
    const blob = new Blob([byteArray], {type: contentType});

    // Create and return a File object
    const file = new File([blob], filename, {
        type: contentType,
        lastModified: Date.now(), // Use the current time as the last modified time
    });

    return file;
}

export function downloadFileLocally(url, fileName) {
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link);
}

