<template>
    <div style="height: 50px;">
        <MultiSelect
            v-if="editor?.isEditable"
            :options="allAvailableMembers"
            v-model="members"
            label=""
            placeholder="Select Members"
            id="members-select"
            @blur="deboundeUpdateMembers"
            variant="filled"
            panel-class="members-panel"
            class="w-full md:max-w-full border-0 !mt-0"

            style="margin-top: 2px !important;"
        >
            <template #option="slotProps">
                <div class="flex items-center gap-2 member-option">
                    <BaseAvatar
                        v-tooltip="slotProps.option.name"
                        :src="getAvatar(slotProps.option.id)"
                        :name="slotProps.option.name"
                        class="w-6 h-6 rounded-full"
                    />
                    <div>{{ slotProps.option.name }}</div>
                    <div v-if="slotProps.option.external" class="m-auto text-xs text-gray-500">(External)</div>
                </div>
            </template>
            <template #value="slotProps">
                <div class="flex gap-2 items-center flex-wrap min-h-[20px]"
                     v-if="slotProps?.value?.length > 0">
                    <div class="flex items-center gap-2" v-for="value in slotProps.value">
                        <BaseAvatar
                            v-tooltip="value.name"
                            :src="getAvatar(value.id)"
                            :name="value.name"
                            class="w-6 h-6 rounded-full"
                        />
                    </div>
                </div>
                <Button
                    v-else
                    size="small"
                    variant="ghost"
                    :style="{
                        'paddingRight': '0 !important',
                        'paddingLeft': '0 !important',
                        'paddingTop': '0 !important',
                        'paddingBottom': '0 !important',
                    }"
                >
                    <UsersIcon class="w-4 h-4"></UsersIcon>
                    <span>Add participants</span>
                </Button>
            </template>
        </MultiSelect>

        <div v-else>
            <div class="flex items-center gap-2" style="height: 50px;">
                <BaseAvatar
                    v-for="value in question.members"
                    v-tooltip="value.name"
                    :src="value.avatar"
                    :name="value.name"
                    class="w-6 h-6 rounded-full"
                />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import Button from "@/Components/ui/button/Button.vue";
import MultiSelect from "../../../Components/forms/Multiselect.vue";
import BaseAvatar from "../../../Components/forms/BaseAvatar.vue";
import { UsersIcon } from "lucide-vue-next";
import { usePage } from "@inertiajs/vue3";
import { ref, watch } from "vue";
import { debounce } from 'lodash-es';
import axios from "axios";
import { displayErrorMessagesAsToast } from "../../../utils/errorUtils";

const props = defineProps({
    editor: Object,
    question: Object,
    updateMembers: Function
});

const page = usePage();
const room = page.props.room;
const roomMembers = ref(room.members.map(m => ({id: m.id, name: m.name, external: false})));
const internalMembers = ref([]);
const externalMembers = ref([]);
const allAvailableMembers = ref([...roomMembers.value]);
const members = ref([]);
const deboundeUpdateMembers = debounce(() => props.updateMembers(members.value), 1500);

const invite = ref(null);


watch(() => props.question, (question) => {
    sync(question);
}, {immediate: true});

function sync(question) {
    internalMembers.value = question.members.map(m => ({id: m.id, name: m.name, external: false})) || [];
    externalMembers.value = question.access.map(m => ({id: m.id, name: m.email, external: true})) || [];
    members.value = [...internalMembers.value, ...externalMembers.value];

    allAvailableMembers.value = [...roomMembers.value, ...room.access.map(m => ({id: m.id, name: m.email, external: true}))];
}

function getAvatar(id) {
    const avatar = room.members.find(m => m.id === id)?.avatar;

    return avatar || null;
}

</script>
