<template>
    <div
        class="h-full max-h-full flex justify-center items-center">
        <div v-if="loading"
             class="h-full w-full rounded bg-gray-100 animate-pulse">
        </div>
        <template v-else>
            <img
                v-if="isImage"
                v-bind="extraAttributes"
                :src="media.preview_url"
                :alt="media?.name"
                :class="detailClass"
                class="max-h-full"
            >
            <iframe
                v-else-if="isIframePreview"
                v-bind="extraAttributes"
                :src="previewUrl"
                :title="media?.name"
                :class="detailClass"
                loading="lazy"
                class="h-full w-full"
            />
            <iframe
                v-else-if="isDocPreview"
                v-bind="extraAttributes"
                :src="officePreviewUrl"
                :class="detailClass"
                class="w-full h-full"
                allowfullscreen
            />
            <div v-else class="w-full flex flex-col items-center justify-center font-medium mb-20">
                <IconBox class="mb-1" @click="">
                    <FolderArrowDownIcon class="w-5 h-5"/>
                </IconBox>
                <span>Unfortunately this file type is not supported for preview.</span>
                <span>Click
                    <button
                        class="text-primary hover:underline"
                        @click=""
                    >
                        here
                    </button> to download the file</span>
            </div>
        </template>
    </div>
</template>
<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {FolderArrowDownIcon} from '@heroicons/vue/24/outline'
import {fileTypes, getFileType, previewTypes, docTypes} from "@/utils/documentUtils";
import IconBox from "./IconBox.vue";
import axios from "axios";

const props = defineProps({
    mediaUuid: {
        type: Object,
        default: () => ({})
    },
    extraAttributes: {
        type: Object,
        default: () => ({})
    },
    data: {
        type: Object,
    },
    detailClass: {
        type: String,
    },
    showToolbar: {
        type: Boolean,
        default: true,
    }
})

const loading = ref(false)
const media = ref(null)

async function getMedia() {
    if (props.data) {
        media.value = props.data
        return
    }
    try {
        loading.value = true
        const {data} = await axios.get(`/media/${props.mediaUuid}`)
        media.value = data
    } finally {
        loading.value = false
    }
}

onMounted(getMedia)


const fileType = computed(() => {
    return getFileType(media.value?.file_name)
})
const isImage = computed(() => {
    return fileType.value === fileTypes.Image
})

const isIframePreview = computed(() => {
    return previewTypes.includes(fileType.value as string)
})

const previewUrl = computed(() => {
    const url = media.value?.public_preview_url || ''
    if (!props.showToolbar) {
        return `${url}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`
    }
    return url
})

const isDocPreview = computed(() => {
    return docTypes.includes(fileType.value as string)
})

const officePreviewUrl = computed(() => {
    if (!media.value.public_preview_url) {
        return
    }
    const url = encodeURIComponent(media.value.public_preview_url)

    return `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
})

async function onClose() {

}
</script>
