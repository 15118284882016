<template>
    <form @submit.prevent="submit" class="mt-4 min-h-[calc(100vh-110px)] flex flex-col">
        <FormCardSection title="Room details" class="flex-1" containerClass="!flex flex-col -mt-4 h-full">
            <template #header>
                <RoomStepper v-model="activeStep" :steps="steps" />
            </template>
            <div v-show="activeStep === 'account' || activeStep === 'review'">
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="account">
                        <FormItem v-auto-animate class="w-full">
                            <FormLabel>Account</FormLabel>
                            <FormControl>
                                <AccountSelect
                                    @showCreateDialog="(value) => {
                                        accountNameInput = value
                                        showCreateAccountDialog = true
                                    }"
                                    :accounts="accounts"
                                    :value="form.values.account"
                                    @select="(value) => {
                                        form.setFieldValue('account', value.id)
                                    }"
                                />
                            </FormControl>
                        </FormItem>
                    </FormField>
                </div>
                <div class="col-span-6">
                    <MultiSelect
                        :options="users"
                        :error="form.errors.value.users"
                        v-model="usersModel"
                        label="Members"
                        optionLabel="name"
                        placeholder="Select Members"
                        id="members"
                        class="w-full md:max-w-full"
                    >
                        <template #option="slotProps">
                            <div class="flex align-items-center gap-2">
                                <BaseAvatar
                                    :src="slotProps.option.avatar"
                                    :name="slotProps.option.name"
                                    class="w-5 h-5 rounded-full"
                                />
                                <div>{{ slotProps.option.name }}</div>
                            </div>
                        </template>
                    </MultiSelect>
                </div>
            </div>
            <div v-show="activeStep === 'template' || activeStep === 'review'">
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="name">
                        <FormItem v-auto-animate>
                            <FormLabel>Room name</FormLabel>
                            <FormControl>
                                <Input type="text" v-model="form.name" v-bind="componentField" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                    <div class="col-span-6">
                        <FormField v-slot="{ componentField }" name="status">
                            <FormItem v-auto-animate class="w-full">
                                <FormLabel>Room status</FormLabel>
                                <FormControl>
                                    <Select v-bind="componentField" name="status">
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Choose room status"/>
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem v-for="roomStatus in roomStatusOptions" :value="roomStatus.value">
                                                    <div class="flex items-center gap-2 py-1 ">
                                                        {{ roomStatus.label }}
                                                    </div>
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="col-span-6" v-if="isCreateRoom">
                        <FormField v-slot="{ componentField }" name="template_type">
                            <FormItem v-auto-animate class="w-full">
                                <FormLabel>Room type</FormLabel>
                                <FormControl>
                                    <Select v-bind="componentField" name="template_type">
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Choose room status"/>
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem v-for="roomStatus in roomTypeOptions" :value="roomStatus.value">
                                                    <div class="flex items-center gap-2 py-1 ">
                                                        {{ roomStatus.label }}
                                                    </div>
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="col-span-6" v-if="form.values.template_type === 'room'">
                        <FormField v-slot="{ componentField }" name="room_id">
                            <FormItem v-auto-animate class="w-full">
                                <FormLabel>Room type</FormLabel>
                                <FormControl>
                                    <Select v-bind="componentField" name="room_id">
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Choose room"/>
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem v-for="roomStatus in rooms" :value="roomStatus.id">
                                                    <div class="flex items-center gap-2 py-1 ">
                                                        {{ roomStatus.name }}
                                                    </div>
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </div>
                    <div v-if="form.values.template_type === 'room' && selectedRoom" class="mt-4">
                        <iframe
                            :src="`/room/${selectedRoom.ulid}/${selectedRoom.pages[0].ulid}`"
                            class="w-full h-screen"
                        />
                    </div>
                </div>
            </div>
            <div v-show="activeStep === 'opportunity' || activeStep === 'review'">
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="opportunity_name">
                        <FormItem v-auto-animate>
                            <FormLabel>Opportunity name</FormLabel>
                            <FormControl>
                                <Input type="text" v-model="form.opportunity_name" v-bind="componentField" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>
                </div>
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="opportunity_value">
                        <FormItem v-auto-animate>
                            <FormLabel>Opportunity value</FormLabel>
                            <FormControl>
                                <div class="relative">
                                    <Input type="text" v-model="form.opportunity_value" v-bind="componentField" class="pl-5"  />
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">$</div>
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    </FormField>

                </div>
                <FormField v-slot="{ componentField }" name="activity_type">
                    <FormItem v-auto-animate class="w-full">
                        <FormLabel>Current Activity</FormLabel>
                        <FormControl>
                            <Select v-bind="componentField" name="activity_type">
                                <FormControl>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Choose current activity"/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem v-for="model in roomActivityTypeOptions" :value="model.value">
                                            <div class="flex items-center gap-2 py-1 ">
                                                {{ model.label }}
                                            </div>
                                        </SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                </FormField>
                <div class="col-span-6 mt-1">
                    <label>Products in scope</label>
                    <textarea v-model="products_in_scope" class="border rounded-md p-2 w-full text-sm"></textarea>
                </div>
                <div class="col-span-6 mt-1">
                    <label>Competitor</label>
                    <textarea v-model="competitor" class="border rounded-md p-2 w-full text-sm"></textarea>
                </div>
            </div>
            <div v-show="activeStep === 'date' || activeStep === 'review'">
                <div class="col-span-6 mt-2">
                    <label class="-mb-2">Start date</label>
                    <vue-tailwind-datepicker
                        v-model="form.start_date"
                        :formatter="dateFormatter"
                        as-single
                        input-classes="border rounded px-3 py-1.5 flex items-center"
                    >
                        <template #inputIcon="{ value }">
                            <div class="mt-2">
                                <i class="pi pi-times" v-if="value" />
                            </div>
                        </template>
                    </vue-tailwind-datepicker>
                </div>
                <div class="col-span-6">
                    <label class="-mb-2">End date</label>
                    <vue-tailwind-datepicker
                        v-model="form.end_date"
                        :formatter="dateFormatter"
                        as-single
                        input-classes="border rounded px-3 py-1.5 flex items-center"
                    >
                        <template #inputIcon="{ value }">
                            <div class="mt-2">
                                <i class="pi pi-times" v-if="value" />
                            </div>
                        </template>
                    </vue-tailwind-datepicker>
                </div>
            </div>
            <Dialog
                v-model:visible="showCreateAccountDialog"
                modal
                header="Create account"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
                @hide="onCancelCreateAccount"
            >
                <AccountForm @submit="createAccount" @cancel="onCancelCreateAccount" :name="accountNameInput"/>
            </Dialog>
        </FormCardSection>

        <div class="w-full flex justify-end space-x-2 py-3 px-3 rounded-b-xl border border-t-0 border-gray-200" v-if="!$slots.footer">
            <Button v-if="activeStep !== 'account'" class="w-[120px]" variant="tertiary"
                    size="small"
                    @click="back"
            >
                Back
            </Button>
            <Button
                class="w-[120px]"
                size="small"
                :loading="false"
                @click="activeStep === 'review' ? submit() : next()"
            >
                {{ activeStep === 'review' ? 'Save changes' : 'Next' }}
            </Button>
        </div>
    </form>
</template>

<script setup>
import { nextTick, ref, watch } from 'vue';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { router, usePage } from '@inertiajs/vue3';
import MultiSelect from '@/Components/forms/Multiselect.vue';
import FormCardSection from '@/Components/forms/FormCardSection.vue';
import RoomStepper from '@/Components/rooms/RoomStepper.vue';
import AccountForm from '@/Components/accounts/AccountForm.vue';
import { debounce } from 'lodash-es';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form';
import Input from '@/Components/ui/input/Input.vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import BaseAvatar from '@/Components/forms/BaseAvatar.vue';
import Button from '@/tiptap/components/ui/button/Button.vue';
import { useRoomStatusOptions } from '@/hooks/useRoomStatusOptions';
import { useRoomActivityType } from '@/hooks/useRoomActivityType';
import { displayErrorMessagesAsToast } from '@/utils/errorUtils';
import { useToast } from 'primevue/usetoast';
import AccountSelect from '@/pages/Rooms/components/account-select.vue';
const toast = useToast();
const page = usePage();
const users = ref(page.props.users.map(user => ({ id: user.id, name: user.name, avatar: user.avatar || null })));
const accounts = ref(page.props.accounts.map(account => ({ ...account, label: account.name })));
const rooms = ref(page.props.rooms);
const showCreateAccountDialog = ref(false);
const selectedRoom = ref(null);
const accountNameInput = ref(null);

const schema = yup.object().shape({
    name: yup.string().required('Room name is required'),
    opportunity_name: yup.string().notRequired(),
    opportunity_value: yup.string()
        .matches(/^\d{1,3}(,\d{3})*(\.\d{2})?$/, 'Invalid currency format. Ex: 100000.00')
        .notRequired(),
    products_in_scope: yup.string().notRequired(),
    activity_type: yup.string().notRequired(),
    account: yup.number().typeError('A number is required').required('Account is required'),
    template_type: yup.string().required(),
    users: yup.array().of(yup.object().shape({
        id: yup.number().required(),
    })).min(1, 'At least one user is required'),
});

const { roomStatusOptions } = useRoomStatusOptions();
const { roomActivityTypeOptions } = useRoomActivityType();
const roomTypeOptions = ref([
    { label: 'Blank', value: 'blank' },
    { label: 'Room', value: 'room' },
]);

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['submit']);

const isCreateRoom = props.data.length === 0;
const accountDropdownRef = ref(null);


const form = useForm({
    validationSchema: schema,
    initialValues: {
        name: '',
        opportunity_name: null,
        opportunity_value: null,
        competitor: null,
        products_in_scope: null,
        room_id: null,
        status: null,
        template_type: 'blank',
        ...props.data,
        start_date: props.data.start_date || [],
        end_date: props.data.end_date || [],
        account: props.data?.account?.id || null,
        users: props.data?.members?.map(user => ({ id: user.id, name: user.name, avatar: user.avatar })) || [],
    },
});

const usersModel = form.useFieldModel('users');
const products_in_scope = form.useFieldModel('products_in_scope');
const competitor = form.useFieldModel('competitor');

const { handleSubmit, validate, setFieldError, errors } = form;

const activeStep = ref('account');
const steps = ref([
    { step: 1, id: 'account', title: 'Account', description: 'Choose account and members' },
    { step: 2, id: 'template', title: 'Room', description: 'Set room details' },
    { step: 3, id: 'opportunity', title: 'Opportunity', description: 'Choose opportunity (optional)' },
    { step: 4, id: 'date', title: 'Date', description: 'Choose start & end date' },
    { step: 5, id: 'review', title: 'Confirmation', description: 'Step details here' },
]);

const dateFormatter = ref({
    date: 'MMM DD, YYYY',
    month: 'MMM',
});

const showDropdown = debounce((event) => {
    if (event.target.classList.contains('p-dropdown-trigger')) {
        return;
    }

    const dropdownElement = accountDropdownRef.value?.$el.querySelector('.p-dropdown-trigger');

    if (dropdownElement) {
        dropdownElement.click(); // Simulate a click directly on the dropdown trigger
    }
}, 0);

function back() {
    const index = steps.value.findIndex(s => s.id === activeStep.value);
    activeStep.value = steps.value[index - 1].id;
}

async function next() {
    const index = steps.value.findIndex(s => s.id === activeStep.value);
    const validationSchema = getValidation();

    try {
        await validationSchema.validate(form.values, { abortEarly: false });
        activeStep.value = steps.value[index + 1].id;
    } catch (err) {
        err.inner.forEach(error => {
            setFieldError(error.path, error.message);
        });
    }
}

function getValidation() {
    const mapping = {
        account: yup.object().shape({
            account: yup.number().required('Account is required'),
            users: yup.array().of(yup.object().shape({
                id: yup.number().required(),
            })).min(1, 'At least one user is required'),
        }),
        template: yup.object().shape({
            name: yup.string().required('Room name is required'),
        }),
        opportunity: yup.object().shape({
            opportunity_name: yup.string().notRequired(),
            opportunity_value: yup.string().matches(/^\d{1,3}(,\d{3})*(\.\d{2})?$/, 'Invalid currency format. Ex: 100000.00').notRequired(),
        }),
        date: yup.object().shape({}),
        review: yup.object().shape({
            name: yup.string().required('Room name is required'),
        }),
    };

    return mapping[activeStep.value];
}

const submit = handleSubmit(values => {
    emit('submit', values);
});

function createAccount(accountForm) {
    const newAccountName = accountForm.name;
    accountForm.post('/accounts', {
        forceFormData: true,
        onSuccess: (data) => {
            showCreateAccountDialog.value = false;
            accountNameInput.value = false;
            accountForm.reset();
            router.reload();
            // nextTick(() => {
                accounts.value = page.props.accounts;
                form.setFieldValue('account', accounts.value.find(a => a.name === newAccountName)?.id)
                console.log('form.vau', form.values)
            // });
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast);
        },
    });
}

function onCancelCreateAccount() {
    showCreateAccountDialog.value = false;
    form.values.account = null;
    accountNameInput.value = null;
    accounts.value = page.props.accounts;
}

function onAccountInput(e) {
    if (e.target.value !== '') {
        const newVal = accounts.value.filter(a => a.name.toLowerCase().includes(e.target.value.toLowerCase()));

        if (newVal.length === 0) {
            newVal.push({ id: 'create', name: 'Create new', create: true });
        }

        accounts.value = newVal;
    } else {
        accounts.value = page.props.accounts;
    }
}

watch(() => form.values.room_id, (newVal) => {
    selectedRoom.value = rooms.value.find(r => r.id === newVal);
});
</script>

<style scoped>
/* Add your custom styles here */
</style>
