import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import ResizableNodeTemplate from './components/ResizableNodeTemplate.vue';

export const embedSuggestion = Node.create({
    name: 'iframe',

    group: 'block',

    atom: true,

    draggable: true,

    addAttributes() {
        return {
            src: {
                default: null,
            },
            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            tag: {
                default: 'iframe',
            },
            html: {
                default: null,
            },
            small: {
                default: false,
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'resizable-node',
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['resizable-node', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(ResizableNodeTemplate);
    },

    addCommands() {
        return {
            setIframe: (options) => ({ commands, tr, dispatch, chain }) => {
                const { selection } = tr
                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true
            },
        }
    },
});

export default embedSuggestion;
