<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{'readonly': !editor?.isEditable }"
    >

        <RoomCard v-if="currentQuestion?.id" container-class="!px-0 !pl-4 !py-0">
            <div class="flex gap-2 items-center group">
                <div>
                    <question-icon
                        :resolved-at="currentQuestion.resolved_at"
                        :status="currentQuestion.status"
                    />
                </div>
                <div class="flex flex-1">
                    <InputEdit
                        :editable="true"
                        :isEdit="isEditTitleMode"
                        placeholder="Untitled"
                        :modelValue="currentQuestion?.title"
                        @update:modelValue="value => currentQuestion.title = value"
                        @submit="onUpdateTitle"
                        class="w-full border-0 !px-0 font-semibold focus:ring-0 text-lg"
                        :style="{fontSize: '16px'}"
                        :textStyle="{ fontSize: '16px', lineHeight: '48px', fontWeight: 'bold'}"
                    />
                </div>

                <div class="ml-auto opacity-0 group-hover:opacity-100" >
                    <Button
                        variant="secondary"
                        class="rounded-l-none !border-0 gap-1.5 h-[48px]"
                        @click="openDialog"
                    >
                        <ExternalLinkIcon class="w-4 h-4"></ExternalLinkIcon>
                        <span>Open</span>
                    </Button>
                </div>
            </div>
            <Dialog
                v-if="showDialog"
                v-model:visible="showDialog"
                modal
                :header="`Edit question`"
                :style="{ width: '64rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
                :dismissable-mask="true"
            >
                <div class="edit-question-dialog flex flex-col"
                >
                    <div>
                        <textarea
                            ref="textareaRef"
                            @input="onInput"
                            v-if="editor?.isEditable"
                            v-model="currentQuestion.title"
                            label=""
                            placeholder="Question"
                            class="border-0 w-full disabled:text-gray-950 focus:border-0 focus-visible:outline-none"
                            @blur="onSaveTitle"
                            :style="{
                                'fontSize': '36px'
                            }"
                        />

                        <p v-else style="font-size: 36px;">{{ currentQuestion.title }}</p>

                        <div class="flex gap-4 items-center flex-grow">
                            <QuestionMemberSelect
                                :question="currentQuestion"
                                :editor="editor"
                                :update-members="updateMembers"
                            />

                            <div style="height: 50px;" class="flex items-center">
                                <vue-tailwind-datepicker
                                    :disabled="!editor?.isEditable"
                                    v-model="dueDate"
                                    :formatter="dateFormatter"
                                    @update:modelValue="updateDueDate"
                                    placeholder=""
                                    as-single
                                    v-slot="{ value, placeholder, clear }"
                                >
                                    <div class="flex items-center">
                                        <div class="text-semibold flex gap-2 items-center"
                                             style="font-weight: 600;font-size:14px;">
                                            <CalendarIcon class="w-4"/>
                                            <span v-if="value">{{ value }}</span>
                                            <span v-else>
                                            Set due date
                                        </span>
                                        </div>
                                        <Button
                                            v-if="editor?.isEditable"
                                            size="small"
                                            variant="ghost"
                                            class="mr-auto p-0"
                                            @click="clear"
                                            :style="{
                                            // 'padding': '0 !important'
                                        }"
                                        >
                                            <XIcon class="w-4 h-4"></XIcon>
                                        </Button>
                                    </div>
                                </vue-tailwind-datepicker>
                            </div>

                            <div class="ml-auto" v-if="editor?.isEditable">
                                <Button
                                    v-if="!currentQuestion.resolved_at"
                                    size="small"
                                    variant="secondary"
                                    class="mr-auto p-0"
                                    @click="resolveQuestion(true)"
                                    :style="{
                                            // 'padding': '0 !important'
                                        }"
                                >
                                    <BadgeCheckIcon class="w-4 h-4"></BadgeCheckIcon>
                                    Resolve
                                </Button>
                                <Button
                                    v-else
                                    size="small"
                                    variant="secondary"
                                    class="mr-auto p-0"
                                    @click="resolveQuestion(false)"
                                    :style="{
                                            // 'padding': '0 !important'
                                        }"
                                >
                                    <LockOpenIcon class="w-4 h-4"></LockOpenIcon>
                                    Re-open
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div class="border mt-auto grow flex items-end flex-col rounded-md" v-if="currentQuestion">
                        <QuestionComments :question="currentQuestion" :editable="editor.isEditable" />
                    </div>
                </div>
            </Dialog>
        </RoomCard>
    </node-view-wrapper>
</template>

<script setup>
import { NodeViewWrapper } from '@tiptap/vue-3';
import { BadgeCheckIcon, CalendarIcon, ExternalLinkIcon, LockOpenIcon, XIcon, } from 'lucide-vue-next';
import 'vue3-circle-progress/dist/circle-progress.css';
import { nextTick, onMounted, ref, watch } from 'vue';
import RoomCard from '../../../pages/RoomDetail/components/RoomCard.vue';
import Button from '@/tiptap/components/ui/button/Button.vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import { formatDateToISO, formatISOToDate } from '../../../utils/formatDate.js';
import { displayErrorMessagesAsToast } from '../../../utils/errorUtils.js';
import QuestionComments from './QuestionComments.vue';
import QuestionMemberSelect from './QuestionMemberSelect.vue';
import InputEdit from '../../../Components/InputEdit.vue';
import { questionStatuses } from './questionStatuses.js';
import { useAuth } from '../../../hooks/useAuth.js';
import QuestionIcon from './questionIcon.vue';

const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
});
const {user} = useAuth();

const currentQuestion = ref({
    title: ''
});
const textareaRef = ref(null);
const showDialog = ref(false);
const loading = ref(false);
const isEditTitleMode = ref(false);
const dueDate = ref(formatISOToDate(currentQuestion.value?.due_date) || []);

watch(() => currentQuestion.value, (newVal) => {
    dueDate.value = formatISOToDate(newVal?.due_date) || [];
});

watch(() => [showDialog.value], async () => {
    await nextTick()
    onInput()
}, {immediate: true});

const dateFormatter = ref({
    date: 'MMM DD, YYYY',
    month: 'MMM',
})


onMounted(() => {
    getQuestion(props.node.attrs.id)
});

async function getQuestion(id) {
    try {
        const {data} = await axios.get('/questions/' + id)
        currentQuestion.value = data
        isEditTitleMode.value = data.title === ""
console.log(isEditTitleMode.value);
        console.log('isEditTitleMode.value', isEditTitleMode.value);
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}

async function onSaveTitle() {
    if (! currentQuestion.value.title) {
        return;
    }

    updateQuestion({
        title: currentQuestion.value.title
    })
}

async function updateMembers(members) {
    const internalMembers = members.filter(m => !m.external).map(m => m.id);
    const externalMembers = members.filter(m => m.external).map(m => m.id);
    updateQuestion({
        members: internalMembers,
        external: externalMembers
    })
}

async function resolveQuestion(value) {
    updateQuestion({
        resolved_at: value ? new Date().toISOString() : null
    });

    const message = value ? `${user.name} resolved this question` : `${user.name || user.email} re-opened this question`;

    addAutomaticallyComment(message, {automatically: true, solved: value})
}

function addAutomaticallyComment(value, extra) {
    axios.post(`/question-comments/${currentQuestion.value.id}`, {input: value, meta: extra})
        .then((response) => {

        })
        .catch((error) => {
            console.error(error);
        });
}

async function updateDueDate() {
    const dd = Array.isArray(dueDate.value) ? dueDate.value[0] : dueDate.value;
    const due_date = formatDateToISO(dd);

    updateQuestion({
        due_date
    })
}

function openDialog() {
    getQuestion(props.node.attrs.id)
    showDialog.value = true;
}

function onUpdateTitle() {
    if (!currentQuestion.value.title || currentQuestion.value.title === "") {
        updateQuestion({
            title: 'Untitled',
        });

        return;
    }

    updateQuestion({
        title: currentQuestion.value.title,
        status: questionStatuses.done
    })
}

async function updateQuestion(dataToUpdate) {
    if (!props.editor?.isEditable || !currentQuestion.value.id) {
        return;
    }

    try {
        const {data} = await axios.put('/questions/' + currentQuestion.value.id, {
            ...dataToUpdate,
        })
        currentQuestion.value = data;
    } catch (e) {
        displayErrorMessagesAsToast(e);
        console.error(e)
    } finally {
    }
}

function onInput(e) {
    const el = textareaRef.value;

    if (!el) {
        return;
    }

    el.style.height = "5px";
    el.style.height = (el.scrollHeight) + "px";
}

</script>
<style>
.edit-question-dialog {
    min-height: 500px;
}
</style>
