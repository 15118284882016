<template>
    <Dialog v-model:visible="showDialog" modal :header="title" @hide="emit('close')">
        <p class="text-gray-500">{{ description }}</p>
        <label v-if="showUpload" :for="uploadId" class="cursor-pointer text-sm text-gray-400 hover:text-gray-500 ml-auto w-full relative rounded-md mt-1 sm:mt-0 sm:col-span-3 ">
            <BaseFileUpload
                :id="uploadId"
                v-model="fileUploadModel"
                @update:modelValue="onImageUpload"
                multiple
                :placeholder="'Click To Upload'"
                :accept='"image/*,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain"'
            >
                <template #activator>
                    <div class="flex justify-center">
                        <Button tag="span" variant="primary" class="mt-2 min-w-64 flex items-center justify-center gap-2 text-gray-950">
                            <i class="pi pi-upload"></i>{{'Add a file'}}
                        </Button>
                    </div>

                    <div class="text-gray-400 mt-3 text-center text-xs">
                        <div>{{ "Upload your own content. Max file size is 5MB." }}</div>
                    </div>
                </template>
            </BaseFileUpload>
        </label>
        <div v-if="uploadLoading" class="flex justify-center">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
        <ul class="my-4">
            <template v-for="file in medias" :key="file.uuid">
                <PlanItemMediaRow
                    :media="file"
                    :show-remove="showRemove"
                    @delete="(id) => {
                        medias = medias.filter(m => m.id !== id);
                        emit('update')
                    }"
                />
            </template>
        </ul>
        <div class="flex gap-2 w-full justify-center">
            <Button class="max-w-[120px] mt-4"
                    @click="showDialog = false"
                    type="submit"
                    variant="secondary"
            >
                Close dialog
            </Button>
        </div>
    </Dialog>
</template>
<script setup lang="ts">
import { Label } from "radix-vue";
import BaseFileUpload from "../../../../Components/forms/BaseFileUpload.vue";
import PlanItemMediaRow from "./PlanItemMediaRow.vue";
import Button from "../../../components/ui/button/Button.vue";
import { onMounted, ref } from "vue";
import axios from "axios";


const showDialog = ref(true);
const uploadId = `file-upload-${new Date().getTime()}`;
const uploadLoading = ref(false);
const fileUploadModel = ref(false);

const props = defineProps({
    todo: {
        type: Array,
        default: () => [],
    },
    title: {
        type: String,
        default: 'Upload files',
    },
    description: {
        type: String,
        default: 'One or more files',
    },
    showUpload: {
        type: Boolean,
        default: true,
    },
    showRemove: {
        type: Boolean,
        default: true,
    },
});

const emit = defineEmits(['close', 'update']);
const medias = ref(props.todo.media);

onMounted(() => {
    axios.get(`/plan-item/${props.todo.id}/media`)
        .then(response => {
            medias.value = response.data;
        })
        .catch(error => {
            console.error('Error fetching media:', error);
        });
})

async function onImageUpload(files) {
    uploadLoading.value = true;
    const uploadPromises = Array.from(files).map(file => {
        medias.value.push({
            name: file.name,
            id: null,
        })

        uploadFile(file);
    });
    await Promise.all(uploadPromises);

    uploadLoading.value = false;
}

async function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(`/plan-item/${props.todo.id}/media`, formData);

        medias.value = medias.value.map(m => {
            if (m.name === file.name) {
                return {
                    ...m,
                    id: response.data.id,
                }
            }
            return m;
        })

        emit('update');
    } catch (error) {
        toast({
            variant: 'destructive',
            title: 'Error',
            description: error.message,
            life: 3000,
        })
        medias.value = medias.value.filter(m => m.name !== file.name);
    }
}
</script>
