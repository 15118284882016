<template>
    <AuthLayout>
        <h1 class="text-center">
            Register
        </h1>
        <div class="flex justify-center space-x-2 mt-2">
            <p>Already have an account?</p>
            <a :href="`${page.props.baseUrl}/login`">Sign In</a>
        </div>
        <form class="w-full mt-16" @submit.prevent="submit">
            <FormField v-slot="{ componentField }" name="email">
                <FormItem v-auto-animate>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                        <InputWithIcon type="email" :icon="MailIcon" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <div class="mt-10">
                <Button class="w-full" size="small" type="submit"  :loading="form.processing">
                    Register
                </Button>
            </div>
        </form>

        <Dialog v-model:visible="success" modal header="Check your email">
            <div class="text-sm leading-6">
                Thank you for providing your email address. We've sent a verification link to your inbox. Please check your email and click on the verification link to complete your registration.<br /><br />

                If you have any questions or need further assistance, please contact our support team at <a href="mailto:support@roombriks.com" class="text-blue-500">support@roombriks.com</a>

                <div class="flex justify-center mt-4">
                    <Button class="w-auto w-32" size="small" type="submit" @click="success = false">
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    </AuthLayout>
</template>
<script setup>
import * as yup from 'yup';
import { router, usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import AuthLayout from '../../Layouts/AuthLayout.vue';
import { ref } from 'vue';
import { MailIcon } from 'lucide-vue-next';
import Button from "@/tiptap/components/ui/button/Button.vue";

import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { InputWithIcon } from '@/Components/ui/input'
import { useForm } from 'vee-validate';
import { useToast } from 'primevue/usetoast';
const page = usePage();
const toast = useToast();

const params = new URLSearchParams(window.location.search);

const schema = yup.object().shape({
    email: yup.string().email().required('Please enter your email address.'),
});

const form = useForm({
    validationSchema: schema,
    initialValues: {
        email: '',
        is_app_sumo: params.get('is_app_sumo') || false,
    },
});

const success = ref(false);
const submit = () => {
    router.post('/register', {email: form.values.email, is_app_sumo: form.values.is_app_sumo}, {
        onSuccess: () => {
            form.resetForm();
            success.value = true;
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
        onFinish: () => {
            console.log('Finished')
        }
    });
};
</script>
