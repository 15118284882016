<template>
    <Dialog v-model:visible="op" modal header="Upload to Library">
        <Tabs :default-value="activeTab" class="w-full justify-center mt-4 flex flex-col gap-4"

        >
            <TabsList>
                <TabsTrigger v-for="option in filteredTabs" :value="option" >{{ option.toUpperCase() }}</TabsTrigger>
            </TabsList>

            <TabsContent value="upload">
                <div class="flex flex-col">
                    <label :for="uploadId" class="cursor-pointer text-sm text-gray-400 hover:text-gray-500 ml-auto w-full relative rounded-md mt-1 sm:mt-0 sm:col-span-3">
                        <BaseFileUpload
                            v-model="fileUploadModel"
                            v-model:preview="fileUploadPreview"
                            @update:modelValue="onImageUpload"
                            :placeholder="'Click To Upload'"
                            :id="uploadId"
                            :accept='"image/*,.pdf,application/msword,application/zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain"'
                        >
                            <template #activator>
                                <div class="p-1 pt-0 text-center flex flex-col items-center">
                                    <a @click="" data-pc-severity="custom-primary" class="min-w-24 flex items-center justify-center gap-2">
                                        <i v-show="uploading" class="pi pi-spin pi-spinner" />
                                        {{'Choose'}}
                                    </a>
                                    <div class="text-gray-400 mt-3 text-center text-xs">
                                        <div>{{ "Upload your own content. Max file size is 10MB." }}</div>
                                    </div>
                                </div>
                            </template>
                        </BaseFileUpload>
                        <img
                            v-if="fileUploadPreview"
                            :src="fileUploadPreview"
                            class="m-auto mt-3 h-24 w-24 rounded-md object-cover cursor-pointer flex-1"
                            alt=""
                        />
                    </label>
                </div>
            </TabsContent>
            <TabsContent value="url">
                <div class="flex flex-col">
                    <Input class="w-full my-4" v-model="url" />
                    <p class="text-sm text-gray-400">Enter an image link from cloud storage or the web.</p>
                    <div class="w-full mt-4 flex justify-center">
                        <Button class="w-[120px]" @click="uploadFromUrl" :disabled="url === ''">Upload</Button>
                    </div>
                </div>
            </TabsContent>
            <TabsContent value="library" v-if="!disabledLibrary">
                <div class="max-h-[400px] overflow-y-auto">
                    <DataTable
                        :value="medias"
                        :row-class="() => 'group cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out'"
                        :globalFilterFields="['name']"
                        v-model:filters="filters"
                        @row-click="onSelectMedia"

                        filterDisplay="menu"
                    >
                        <template #empty>
                            <EmptyState
                                title="No library"
                            />
                        </template>
                        <Column field="file_name" header="File" sortable>
                            <template #body="{data}">
                                <FileInfo :data="data" :loading="false"/>
                            </template>
                        </Column>
                        <Column field="name" header="Name" sortable>
                            <template #body="{data}">
                                {{ data.name }}
                            </template>

                            <template #filter="{ filterModel, filterCallback }">
                                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter rounded-md border-gray-300 py-1 h-10"
                                           placeholder="Search by name"/>
                            </template>
                        </Column>
                        <Column field="creator" header="Creator" sortable>
                            <template #body="{data}">
                                {{ data.creator.name }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabsContent>
        </Tabs>
    </Dialog>
</template>
<script setup>
// In your main.js or main.ts file

import { nextTick, onMounted, ref } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import BaseFileUpload from './BaseFileUpload.vue';
import { createBlobFromUrl } from '../../utils/fileUtils.js';
import { Input } from '@/Components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/Components/ui/tabs'


import { usePage } from '@inertiajs/vue3';
import FileInfo from '../FileInfo.vue';
import Button from '../../tiptap/components/ui/button/Button.vue';
import EmptyState from '../EmptyState.vue';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { FilterMatchMode, FilterOperator } from 'primevue/api';


const op = ref();
const url = ref('');
defineExpose({
    open
});

const page = usePage();
const medias = ref(page.props.medias);
const props = defineProps({
    uploadFunction: Function,
    onSelectLibrary: Function,
    shouldUploadImage: Boolean,
    disabledLibrary: {
        type: Boolean,
        default: false
    },
    activeTab: {
        type: String
    }
});

const tabs = ['upload', 'url', 'library']

const filteredTabs = tabs.filter((t) => {
    return !(t === 'library' && props.disabledLibrary);
})

const activeTab = ref(tabs[0])

const uploadId = `file-upload-${new Date().getTime()}`;
const fileUploadModel = ref(null);
const fileUploadPreview = ref('');
const uploading = ref(false);

const filters = ref({
    name: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
});

async function open() {
    op.value = true;

    await nextTick()
}

onMounted(() => {
    axios.get('/media')
        .then(response => {
            medias.value = response.data.medias;
        })
        .catch(error => {
            console.log(error);
        });
})

function onSelectMedia(media) {
    if (props.onSelectLibrary) {
        props.onSelectLibrary(media.data);
    }
    op.value = false;
}

async function onImageUpload(image) {

    uploading.value = true;

    try {
        await triggerUpload(image);
    } catch (e) {

    } finally {
        uploading.value = false;
        fileUploadPreview.value = '';
        fileUploadModel.value = null;
    }
}

async function triggerUpload(image) {
    if (props.uploadFunction) {
        await props.uploadFunction(image)
    }
}

async function uploadFromUrl() {
    if (!url.value) {
        return;
    }

    try {
        triggerUpload(
            await createBlobFromUrl(url.value)
        );
    } catch (e) {
        toast({
            variant: 'destructive',
            title: 'Error',
            description: 'Invalid URL',
        })
    }
}

</script>
<style lang="scss">
.image-modal-tabs [data-pc-section="header"][data-p-highlight="true"] {
    border-bottom: 2px solid blue;
}
</style>
