<template>
    <div class="relative max-w-[50px]">
        <svg
            v-if="!isImageFile"
            :class="[iconClass, {
        'w-6 h-8': size === 'md',
        'w-9 h-11': size === 'lg',
      }]"
            class="file-icon"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.089 33.2">
            <path data-name="Path 3346"
                  d="M8.556 35h17.777a3.556 3.556 0 0 0 3.556-3.556V14.4a1.778 1.778 0 0 0-.521-1.257l-9.625-9.622A1.778 1.778 0 0 0 18.486 3h-9.93A3.556 3.556 0 0 0 5 6.556v24.888A3.556 3.556 0 0 0 8.556 35z"
                  transform="translate(-4.4 -2.4)"
                  style="fill:transparent; stroke:currentColor; stroke-linecap:round; stroke-linejoin:round; stroke-width:1.2px"
            />
        </svg>
        <svg
            v-if="!isImageFile"
            :class="backgroundClass"
            class="file-icon-2"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.089 33.2">
            <path data-name="Path 3346"
                  d="M8.556 35h17.777a3.556 3.556 0 0 0 3.556-3.556V14.4a1.778 1.778 0 0 0-.521-1.257l-9.625-9.622A1.778 1.778 0 0 0 18.486 3h-9.93A3.556 3.556 0 0 0 5 6.556v24.888A3.556 3.556 0 0 0 8.556 35z"
                  transform="translate(-4.4 -2.4)"
                  style="fill:currentColor; stroke-linecap:round; stroke-linejoin:round; stroke-width:0"
            />
        </svg>
        <img
            v-else
            :src="data?.preview_url"
            :alt="data?.name"
            :class="[iconClass, {
                'w-6 h-8': size === 'md',
                'w-11 h-11': size === 'lg',
              }]"
            class="rounded object-cover"
        >

        <div
            v-if="extension && !loading"
            :class="typeColors.main"
            class="icon-extension"
        >
            <span>{{ extension }}</span>
        </div>

        <slot/>
    </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import { getFileTypeColor, isImage } from "@/utils/documentUtils";

const props = defineProps({
    iconClass: String,
    backgroundClass: String,
    size: {
        type: String,
        default: 'md'
    },
    extension: String,
    loading: Boolean,
    data: {
        type: Object as PropType<Data<Document>>
    }
})

const typeColors = computed(() => {
    const extension = props.extension || ''
    return getFileTypeColor(extension)
})

const isImageFile = computed(() => {
    return isImage(props.data)
})
</script>

<style scoped lang="scss">
.file-icon {
    @apply relative flex justify-center items-center rounded;
    z-index: 2;
}

.file-icon-2 {
    left: -13%;
    top: -13%;
    z-index: 1;
    @apply absolute w-full h-full rounded fill-current;
}

.icon-extension {
    bottom: -5px;
    right: -15px;
    font-size: 8px;
    max-height: 12px;
    padding: 1px 6px 1px 6px;
    z-index: 3;
    @apply absolute flex items-center justify-center text-white font-semibold rounded-sm;
}
</style>
