<template>
    <div class="p-0" v-on:dblclick="setInput">
        <template v-if="!isEdit">
            <div v-if="$slots.header">
                <slot name="header"></slot>
            </div>
            <div v-else>
                <RoomCardTitle :style="textStyle">{{ modelValue }}</RoomCardTitle>
            </div>
        </template>
        <template v-else>
            <FormField v-slot="{ componentField }" name="title">
                <FormItem v-auto-animate class="col-span-6">
                    <FormControl>
                        <Input
                            v-bind="componentField"
                            autofocus
                            ref="inputRef"
                            :id="inputId"
                            @blur="onBlur"
                            @keyup.enter="onBlur"
                            :modelValue="modelValue"
                            @input="$emit('update:modelValue', $event.target.value)"
                            class="w-full !caret-black"
                            autocomplete="off"
                        />
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
        </template>
    </div>
</template>
<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { uniqueId } from 'lodash-es';
import RoomCardTitle from "../pages/RoomDetail/components/RoomCardTitle.vue";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input';

const props = defineProps({
    editable: {
        type: Boolean,
        default: false
    },
    textStyle: {
        type: Object,
        default: () => ({})
    },
    modelValue: {
        type: [String, Number], // Ensure the type matches your data
        default: ''
    },
    isEdit: {
        type: Boolean,
        default: false
    },
    autoFocus: {
        type: Boolean,
        default: false
    }
})

const inputRef = ref();
const emit = defineEmits(['update:modelValue', 'submit']);
const inputId = uniqueId('input-');
const isEdit = ref(props.isEdit);

onMounted(() => {
    if (props.autoFocus) {
        focus();
    }
});

watch(() => props.isEdit, (value) => {
    isEdit.value = value;
    if (value) {
        focus();
    }
});

function setInput() {
    if (!props.editable) {
        return;
    }

    isEdit.value = true;
    focus();
}

function focus() {
    nextTick(() => {
        isEdit.value = true
        if (isEdit.value) {
            document.getElementById(inputId)?.focus();
        }
    });
}

function onBlur() {
    isEdit.value = false;
    emit('submit');
}
</script>
