<template>
    <draft-question v-if="!isDoneStatus" class="text-gray-600 w-6 h-6"/>
    <solved-question v-else-if="isDoneStatus && isResolved" class="text-green-500 text-xl w-6 h-6"/>
    <unsolved-question v-else class="text-gray-600 w-6 h-6"/>
</template>
<script setup lang="ts">
import DraftQuestion from "./draftQuestion.vue";
import { questionStatuses } from "./questionStatuses";
import SolvedQuestion from "./solvedQuestion.vue";
import UnsolvedQuestion from "./unsolvedQuestion.vue";
import { computed } from "vue";

const props = defineProps({
    resolvedAt: {
        type: String,
        default: null
    },
    status: {
        type: String,
        default: false
    }
})

const isDoneStatus = computed(() => {
    return props.status === questionStatuses.done;
});

const isResolved = computed(() => {
    return props.resolvedAt !== null;
});

</script>
