<template>
    <li class="my-1 text-gray-500 text-sm flex justify-between shadow-sm p-2">
        <span>{{ media.file_name || media.name }}</span>
        <span v-if="!media.id">
                    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                </span>
        <span v-if="media.id" class="gap-2 flex">
            <Button
                v-if="showRemove"
                variant="secondary"
                @click="deleteFile(media.id)"
            >
                <XIcon class="w-4 h-4"/>
            </Button>
            <Button
                variant="secondary"
                @click="downloadFile(media.id)">
                <DownloadIcon class="w-4 h-4"/>
            </Button>
        </span>
    </li>
</template>
<script setup lang="ts">
import Button from "../../../components/ui/button/Button.vue";
import {router} from "@inertiajs/vue3";
import {DownloadIcon, XIcon} from 'lucide-vue-next'

const props = defineProps({
    media: {
        type: Object,
        default: () => []
    },
    showRemove: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['delete']);

function deleteFile(id) {
    router.delete(`/media/${id}`, {
        onSuccess() {
            emit('delete', id);
            router.reload()
        }
    });
}

function downloadFile(id) {
    window.open(`/media/download/${id}`, '_blank');
}

</script>
