<template>
    <div class="flex justify-end group-hover:opacity-100">
        <Link v-if="canModifyRoom(value)" :href="'/accounts/' + value.ulid"
              class="text-blue-600 group-hover:opacity-100">

            <Button variant="ghost" size="small" aria-label="Edit">
                <Icon name="Pencil"></Icon>
            </Button>
        </Link>
        <DeleteRow v-if="canModifyRoom(value)" @onDelete="() => onDelete(value)"/>
    </div>
</template>
<script setup>
import Icon from '@/tiptap/components/ui/Icon.vue';
import { Link, useForm } from '@inertiajs/vue3';
import Button from '@/tiptap/components/ui/button/Button.vue';
import { defineProps } from 'vue';
import { useAuth } from '@/hooks/useAuth.js';
import DeleteRow from '@/Components/DeleteRow.vue';
import { useToast } from 'primevue/usetoast';
import { displayErrorMessagesAsToast } from '@/utils/errorUtils.js';

const {canModifyRoom} = useAuth();
const toast = useToast();
const props = defineProps({
    value: {
        type: Object
    },
    accounts: {
        type: Array,
    },
    onDelete: {
        type: Function
    },
})

const form = useForm({});

function onDelete(data) {
    form.delete('/accounts/' + data.ulid, {
        preserveScroll: true,
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
        onSuccess: () => {
            const newAccounts = props.accounts.value.filter(account => account.id !== props.value.id);

            props.onDelete(newAccounts)
        }
    });
}

</script>
