<template>
    <Button
        @click="confirm1"
        variant="ghost" size="small" aria-label="Delete" >
        <Icon name="Trash2"></Icon>
    </Button>
</template>
<script setup>
import { useConfirmationModal } from '../hooks/useConfirmationModal.js';
import Button from "../tiptap/components/ui/button/Button.vue";
import Icon from "../tiptap/components/ui/Icon.vue";
const emit = defineEmits(['onDelete']);
const confirm = useConfirmationModal();
const confirm1 = (e) => {
    e.stopPropagation();

    confirm({
        accept: () => {
            emit('onDelete');
        },
    });
};
</script>
