<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{'readonly': !editor?.isEditable }"
    >
        <Toast/>
        <ProjectPlanSpinner v-if="loading"/>
        <RoomCard>
            <template #header>
                <div class="flex w-full justify-between items-center gap-2">
                    <RoomCardTitle>
                        <InputEdit
                            :editable="editable"
                            :modelValue="planTitleForm.title"
                            @update:modelValue="value => planTitleForm.title = value"
                            @submit="onSubmitPlanTitle"
                            class="flex-1"
                        />
                    </RoomCardTitle>
                    <div class="gap-2 flex items-center text-xs text-gray-500">
                        <div class="card w-14 text-xs">
                            <ProgressBar :value="percentage" style="height: 6px">{{ '' }}</ProgressBar>
                        </div>
                        <div>{{ completedItems }} / {{ totalItems }}</div>
                    </div>
                </div>
            </template>
            <div class="flex flex-col py-4" v-if="!loading && !error">

                <div class="py-1.5 rounded-lg group" v-for="todo in todos" :key="todo.id">
                    <div class="flex items-center justify-between ">
                        <div class="flex items-center overflow-x-auto">
                            <Checkbox
                                :disabled="!canModifyPlanItem()"
                                inputId="newTask"
                                name="completed"
                                :checked="todo.completed"
                                @update:checked="(e) => { onUpdateCompletedAt(e, todo)}"
                                @update:modelValue="(e) => { onUpdateCompletedAt(e, todo)}"
                            >
                                <template #icon="{checked}">
                                    <i v-if="checked" class="pi pi-check text-primary-500"></i>
                                </template>
                            </Checkbox>

                            <div class="ml-2 text-base flex-wrap text-wrap flex-1 overflow-x-hidden truncate">
                                {{ todo.title }}
                            </div>
                        </div>
                        <div class="flex gap-2 space-x-2 items-center flex-1 justify-end relative">
                            <div class="opacity-0 bg-white absolute right-0 group-hover:opacity-100">

                                <div class="flex space-x-2">
                                    <Button
                                        v-if="todo.action === 'external_link'"
                                        @click="() => openExternalLink(todo)"
                                        variant="ghost"
                                        button-size="icon"
                                    >
                                        <SquareArrowOutUpRightIcon class="w-4 h-4"/>
                                    </Button>

                                    <Button
                                        v-if="todo.action === 'request_files'"
                                        @click="uploadDialog = todo"
                                        variant="ghost"
                                        button-size="icon"
                                    >
                                        <UploadCloudIcon class="w-4 h-4"/>
                                    </Button>

                                    <Button
                                        v-if="todo.action === 'file_download'"
                                        @click="downloadDialog = todo"
                                        variant="ghost"
                                        button-size="icon"
                                    >
                                        <ArrowDownToLine class="w-4 h-4"/>
                                    </Button>
                                    <template v-if="canModifyPlanItem() && !isCollaborator()">
                                        <Button
                                            variant="ghost"
                                            button-size="icon"
                                            @click="todoDetail = todo"
                                        >
                                            <Icon name="Pencil"/>
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            button-size="icon"
                                            @click="removeTodo(todo)"
                                        >
                                            <Icon name="Trash2"/>
                                        </Button>
                                    </template>
                                </div>
                            </div>

                            <div class="flex flex-row h-6 min-w-6">
                                <BaseAvatar
                                    v-for="member in todo.members"
                                    :key="member.id"
                                    :src="member.avatar"
                                    :name="member.name"
                                    :title="member.name"
                                    :svg-font-size="40"
                                    class="w-6 h-6 rounded-full border border-white -ml-2"
                                />
                            </div>

                            <div v-if="todo.due_date">
                                <span class="text-xs text-gray-500 text-nowrap">{{ formatDate(todo.due_date) }}</span>
                            </div>

                            <TaskStatus :status="todo.status"/>
                        </div>
                    </div>
                </div>
                <div class="flex items-center mt-6" v-if="editable">
                    <form @submit.prevent="addTodo" class="flex items-end w-full">
                        <FormField name="name">
                            <FormItem v-auto-animate class="w-full">
                                <FormControl>
                                    <div class="flex">
                                        <Input
                                            v-model="newTodoTitle"
                                            placeholder="Add new task"
                                            class="rounded-r-none border-r-0"
                                        />
                                        <Button
                                            class="rounded-l-none !border-l-0"
                                            variant="secondary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </form>
                </div>

            </div>
            <CardError v-if="!loading && error"/>

            <template v-if="todoDetail">
                <PlanItemDetail :todo-detail="todoDetail" @close="() => {
                todoDetail = null;
                getProjectPlan(props.node.attrs.id)
            }"/>
            </template>

            <template v-if="uploadDialog">
                <PlanItemUploader
                    :todo="uploadDialog"
                    @close="uploadDialog = null"
                />
            </template>

            <template v-if="downloadDialog">
                <PlanItemUploader
                    :title="'Download files'"
                    :description="''"
                    :todo="downloadDialog"
                    @close="downloadDialog = null"
                    :show-upload="false"
                    :show-remove="false"
                />
            </template>

        </RoomCard>
    </node-view-wrapper>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { NodeViewWrapper } from '@tiptap/vue-3';
import { useForm } from '@inertiajs/vue3';
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import { displayErrorMessagesAsToast } from '../../../../utils/errorUtils.js';
import ProjectPlanSpinner from './ProjectPlanSpinner.vue';
import InputEdit from '../../../../Components/InputEdit.vue';
import PlanItemDetail from './PlanItemDetail.vue';

import ProgressBar from 'primevue/progressbar';
import Button from '../../../components/ui/button/Button.vue';
import PlanItemUploader from './PlanItemUploader.vue';
import BaseAvatar from '../../../../Components/forms/BaseAvatar.vue';
import formatDate from '../../../../utils/formatDate.js';
import CardError from '../../../components/ui/CardError.vue';
import RoomCard from '../../../../pages/RoomDetail/components/RoomCard.vue';
import Icon from '../../../components/ui/Icon.vue';
import RoomCardTitle from '../../../../pages/RoomDetail/components/RoomCardTitle.vue';
import { FormControl, FormField, FormItem, FormMessage, } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import { Checkbox } from '@/Components/ui/checkbox'
import { ArrowDownToLine, SquareArrowOutUpRightIcon, UploadCloudIcon } from 'lucide-vue-next'
import { useToast } from 'primevue/usetoast';
import { useAuth } from '../../../../hooks/useAuth.js';
import TaskStatus from './TaskStatus.vue';

const toast = useToast();

const {room} = useRoomPage();
const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
})

const todoDetail = ref(null);
const uploadDialog = ref(null);
const downloadDialog = ref(null);
const { canModifyPlanItem, isCollaborator } = useAuth();
const {
    editable,
} = useRoomPage();

const loading = ref(true);
const newTodoTitle = ref('');
const form = useForm({
    name: undefined,
    account_avatar: undefined,
    tenant_avatar: undefined,
    cover: undefined,
});

const error = ref(false);

const items = computed(() => {
    return currentPlan.value?.items || []
})
const completedItems = computed(() => {
    return items.value.filter((item) => !!item.completed_at).length
});

const totalItems = computed(() => {
    return items.value.length
});

const percentage = computed(() => {
    return Math.round((completedItems.value / totalItems.value) * 100)
});

const currentPlan = ref(null);
const todos = computed(() => {
    return items.value.map((item) => {
        return {
            ...item,
            completed: !!item.completed_at,
            members: item?.members
                .map((todoMember) => {
                    return room.members.find((member) => member.id === todoMember.id);
                }).filter(v => !!v)
        }
    })
});

const planTitleForm = useForm({
    title: currentPlan.value?.title,
});


onMounted(() => {
    getProjectPlan(props.node.attrs.id)
});

async function getProjectPlan(id) {
    try {
        const { data } = await axios.get('/plans/' + id)
        currentPlan.value = data
        planTitleForm.title = data.title
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}

function updateTodos(data) {
    currentPlan.value = data.plan;
}

function addTodo() {
    const trimmedTitle = newTodoTitle.value.trim();
    if (!trimmedTitle) {
        return; // Exit if the title is empty
    }

    axios.post(`/plan-item`, {
        title: trimmedTitle,
        plan_id: currentPlan.value.id,
    }).then(response => {
        updateTodos(response.data); // Assuming updateTodos function processes and updates the state with the new todo data
    }).catch(error => {
        displayErrorMessagesAsToast(error); // Handling errors by showing them in a toast message
    }).finally(() => {
        newTodoTitle.value = ''; // Clear the input field in any case after the request
    });
}

function removeTodo(todo) {
    axios.delete(`/plan-item/${todo.id}`)
        .then(response => {
            updateTodos(response.data);
        })
        .catch(error => {
            displayErrorMessagesAsToast(error);
        })
        .finally(() => {
        });
}


function onUpdateCompletedAt(value, todo) {
    todo.completed_at = !!value;

    axios.put(`/plan-item/${todo.id}`, {
        title: todo.title,
        completed: value,
        members: todo.members.map(member => member.id),
    }).then(response => {
        updateTodos(response.data);
    }).catch(error => {
        displayErrorMessagesAsToast(error);
    }).finally(() => {
    });
}

function onSubmitPlanTitle() {
    if (!editable) {
        return;
    }

    axios.put(`/plans/${currentPlan.value.id}`, {
        title: planTitleForm.title,
    }).then(response => {
    }).catch(error => {
        planTitleForm.title = currentPlan.value?.title
        displayErrorMessagesAsToast(err, toast)
    }).finally(() => {
    });
}

function openExternalLink(todo) {
    window.open(todo.action_url, '_blank');
}

</script>
<style lang="scss">
.task-modal {
    .ProseMirror {
        padding: 0;
    }

    .p-dialog {
        @apply h-screen;
    }
}

.p-sidebar-mask {
    z-index: 999 !important;
}

.peer:hover ~ .peer-hover\:bg-primary-600 {
    background: white !important;
}
</style>
