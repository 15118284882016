import { displayErrorMessagesAsToast } from '../../../utils/errorUtils.js';
import { router, useForm, usePage } from '@inertiajs/vue3';
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import useValidatedForm from '@/hooks/useValidatedFormNew';
import * as yup from 'yup';

export function useRoomPage(roomParam) {
    const page =  usePage();
    const toast = useToast();
    const room = roomParam || page.props.room;
    const pages = ref(room?.pages);
    console.log('PRPS', page.props);
    const editable = ref(page.props.editable);
    const external = ref(page.props.external);
    const addPage = ref(false);
    const editPage = ref(false);
    const schema = yup.object().shape({
        name: yup.string().required('Please enter page name.'),
    });

    const addPageForm = useValidatedForm({
        name: '',
    }, schema)

    const editPageForm = useValidatedForm({
        name: '',
        ulid: '',
    }, schema)

    function onDelete(page) {
        const index = pages.value.findIndex(p => p.id === page.id);
        pages.value.splice(index, 1);
    }

    function onEditPage(currentPage) {
        editPage.value = true

        editPageForm.vee.setValues(currentPage);
    }

    function editPageSubmit() {
        editPageForm
            .transform((data) => {
                return {
                    name: data.name,
                }
            })
            .put(`/page/${editPageForm.ulid}`, {
            onSuccess: (data) => {
                pages.value = data.props.room.pages;
                editPage.value = false
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
    }

    function createNewPage() {
        addPageForm.post(`/room-detail/${page.props.room.ulid}/add-page`, {
            onSuccess: (data) => {
                addPageForm.reset();
                pages.value = data.props.room.pages;
                router.visit(`/room-detail/${page.props.room.ulid}/${data.props.room.pages.at(-1).ulid}`);
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err)
            },
        });
    }

    return {
        page,
        pages,
        addPage,
        editPage,
        addPageForm,
        editPageForm,
        onDelete,
        onEditPage,
        editPageSubmit,
        createNewPage,
        editable,
        external,
        room,
    }
}
