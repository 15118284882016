<template>
    <div>
        <RoomView />
    </div>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import RoomView from './components/RoomView.vue';
import { usePage } from '@inertiajs/vue3';

const page = usePage();
</script>
