<template>
    <div class="w-full">
        <div class="flex gap-2 items-center py-4 items-center">
            <InputWithIcon
                :icon="SearchIcon"
                class="max-w-sm"
                placeholder="Search"
                :model-value="table.getColumn('email')?.getFilterValue() as string"
                @update:model-value="table.getColumn('email')?.setFilterValue($event)"
            />
            <DropdownMenu>
                <DropdownMenuTrigger as-child>
                    <Button variant="outline">
                        <FilterIcon class="h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuCheckboxItem
                        v-for="column in table.getAllColumns().filter((column) => column.getCanHide())"
                        :key="column.id"
                        class="capitalize"
                        :checked="column.getIsVisible()"
                        @update:checked="(value) => {
                          column.toggleVisibility(!!value)
                        }"
                    >
                        {{ column.id }}
                    </DropdownMenuCheckboxItem>
                </DropdownMenuContent>
            </DropdownMenu>
            <div class="flex w-full justify-end ">
                <div>
                    <Link href="/invitations/create" as="button">
                        <CreateButton>
                            Invite member
                        </CreateButton>
                    </Link>
                </div>
            </div>
        </div>
        <div class="rounded-md border">
            <Table>
                <TableHeader class="bg-gray-25">
                    <TableRow v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
                        <TableHead v-for="header in headerGroup.headers" :key="header.id">
                            <FlexRender v-if="!header.isPlaceholder" :render="header.column.columnDef.header" :props="header.getContext()" />
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <template v-if="table.getRowModel().rows?.length">
                        <template v-for="row in table.getRowModel().rows" :key="row.id">
                            <TableRow :data-state="row.getIsSelected() && 'selected'">
                                <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id">
                                    <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
                                </TableCell>
                            </TableRow>
                            <TableRow v-if="row.getIsExpanded()">
                                <TableCell :colspan="row.getAllCells().length">
                                    {{ JSON.stringify(row.original) }}
                                </TableCell>
                            </TableRow>
                        </template>
                    </template>

                    <TableRow v-else>
                        <TableCell
                            :colspan="columns.length"
                            class="h-24 text-center"
                        >
                            No results.
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>

        <div class="flex items-center justify-end space-x-2 py-4">
            <!--            <div class="flex-1 text-sm text-muted-foreground">-->
            <!--                {{ table.getFilteredSelectedRowModel().rows.length }} of-->
            <!--                {{ table.getFilteredRowModel().rows.length }} row(s) selected.-->
            <!--            </div>-->
            <div class="space-x-2">
                <Button
                    variant="outline"
                    size="sm"
                    :disabled="!table.getCanPreviousPage()"
                    @click="table.previousPage()"
                >
                    Previous
                </Button>
                <Button
                    variant="outline"
                    size="sm"
                    :disabled="!table.getCanNextPage()"
                    @click="table.nextPage()"
                >
                    Next
                </Button>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { ColumnDef, ColumnFiltersState, ExpandedState, SortingState, VisibilityState, } from '@tanstack/vue-table'
import {
    FlexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useVueTable,
} from '@tanstack/vue-table'
import { ArrowUpDown, FilterIcon, SearchIcon } from 'lucide-vue-next'

import { h, ref, watch } from 'vue'
import { Button } from '@/Components/ui/button'
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@/Components/ui/dropdown-menu'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from '@/Components/ui/table'
import { Link, usePage } from "@inertiajs/vue3";
import { InputWithIcon } from "@/Components/ui/input";
import { valueUpdater } from "@/utils/valueUpdater";
import CreateButton from "@/Components/buttons/CreateButton.vue";
import ActionsCell from "@/pages/Invitation/components/invitations-table-actions-cell.vue";
import Badge from "primevue/badge";
import { format } from "@formkit/tempo";

const page = usePage();
const invitations = ref(page.props.invitations || []);

const emit = defineEmits(['showUpload']);
const data = invitations.value


const columns: ColumnDef<any>[] = [
    {
        accessorKey: 'email',
        header: ({ column }) => {
            return h(Button, {
                variant: 'ghost',
                class: 'p-0 hover:bg-gray-50',
                onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
            }, () => ['EMAIL', h(ArrowUpDown, { class: 'ml-2 h-4 w-4' })])
        },
    },
    {
        accessorKey: 'ACCEPTED',
        cell: ({ row }) => {
            return row.original.validated_at ? 'Yes' : 'No'
        }
    },
    {
        accessorKey: 'EXPIRE',
        cell: ({ row }) => {
            return format(row.original.expire_at, {date: 'long', time: 'short'})
        }
    },
    {
        accessorKey: 'actions',
        header: '',
        cell: ({ row, cell }) => {
            return h(ActionsCell, {
                value: row.original,
                invitations: invitations,
                onUpdate: (newData) => {
                    invitations.value = newData;
                }
            })
        },
    },
]

const sorting = ref<SortingState>([])
const columnFilters = ref<ColumnFiltersState>([])
const columnVisibility = ref<VisibilityState>({})
const rowSelection = ref({})
const expanded = ref<ExpandedState>({})

const table = useVueTable({
    get data() {
        return invitations.value
    },
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: updaterOrValue => valueUpdater(updaterOrValue, sorting),
    onColumnFiltersChange: updaterOrValue => valueUpdater(updaterOrValue, columnFilters),
    onColumnVisibilityChange: updaterOrValue => valueUpdater(updaterOrValue, columnVisibility),
    onRowSelectionChange: updaterOrValue => valueUpdater(updaterOrValue, rowSelection),
    onExpandedChange: updaterOrValue => valueUpdater(updaterOrValue, expanded),
    state: {
        get sorting() { return sorting.value },
        get columnFilters() { return columnFilters.value },
        get columnVisibility() { return columnVisibility.value },
        get rowSelection() { return rowSelection.value },
        get expanded() { return expanded.value },
        get data() {
            return data.value
        }
    },
})

</script>
