<template>
    <img
        :src="avatarSvg"
        :alt="name"
        loading="lazy"
        class="object-top object-cover"
        @error="onLoadError"
    >
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { createAvatar } from "@dicebear/core";
import { shapes, initials } from '@dicebear/collection';

const props = defineProps({
    src: String,
    name: String,
    svgFontSize: Number
})
const hasError = ref(false)
const avatarSvg = computed(() => {
    if (props.src && !hasError.value) {
        return props.src
    }
    const seed = props.name || 'default'

    const avatar = createAvatar(initials, {
        seed,
        fontSize: props.svgFontSize,
    });

    return avatar.toDataUriSync()
})

function onLoadError() {
    hasError.value = true
}

watch(() => props.src, () => {
    hasError.value = false
})
</script>
