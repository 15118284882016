<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="2325" viewBox="25 24.999 850 790.592" v-bind="$attrs">
        <g fill="#007ee5">
            <path
                d="M275.037 24.999L25 188.255l172.886 138.448L450 171.023zM25 465.16l250.037 163.256L450 482.374l-252.114-155.67zM450 482.374l174.963 146.042L875 465.16 702.114 326.703z"/>
            <path
                d="M875 188.255L624.963 24.999 450 171.024l252.114 155.68zM450.513 513.797l-175.476 145.61-75.09-49.028v54.959L450.513 815.59 701.08 665.338v-54.96l-75.09 49.029z"/>
        </g>
    </svg>
</template>
