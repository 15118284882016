<template>
    <div class="room-nav">
        <RoomEditorTopNav v-if="!page.props.external && canModifyRoom(room)" @save="emit('save')"/>
    </div>
</template>

<script setup>

import { usePage } from '@inertiajs/vue3';
import { useAuth } from '../../../hooks/useAuth.js';
import { ref } from 'vue';
import RoomEditorTopNav from './RoomEditorTopNav.vue';

const { canModifyRoom } = useAuth();
const props = defineProps({
    room: {
        type: Object,
        default: null
    }
})

const emit = defineEmits(['save'])


const page = usePage();
const room = ref(props.room || page.props.room);

</script>
<style scoped>
.room-nav {
    z-index: 100;
    @apply w-full sticky top-0 left-0;
}
</style>
