<template>
    <VueApexCharts
        type="line"
        height="350"
        :options="apexOptions"
        :series="apexSeries"
        ref="chart"
    />
</template>

<script setup>
import { computed, ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

const props = defineProps({
    room: {
        type: Object,
        default: () => ({})
    }
});

const chart = ref(null);

const chartData = computed(() => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const groupedByMonth = props.room?.views.reduce((acc, view) => {
        const date = new Date(view.created_at);
        const monthKey = `${monthNames[date.getMonth()]} ${date.getFullYear()}`;

        if (!acc[monthKey]) {
            acc[monthKey] = {
                name: monthKey,
                value: 0
            };
        }

        acc[monthKey].value += 1;
        return acc;
    }, {});

    const data = Object.values(groupedByMonth).map(data => {
        return [data.name, data.value];
    }).sort((a, b) => new Date(a[0].split(' ')[1], monthNames.indexOf(a[0].split(' ')[0])) - new Date(b[0].split(' ')[1], monthNames.indexOf(b[0].split(' ')[0])));

    return data;
});

const apexSeries = computed(() => [{
    name: 'Views',
    data: chartData.value.map(item => item[1]) // Extract the values for the series data
}]);

const apexOptions = computed(() => ({
    chart: {
        height: 350,
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth'
    },
    subtitle: {
        text: '',
        align: 'left',
        offsetY: 0,
        style: {
            fontSize: '18px',
        }
    },
    title: {
        text: '',
        align: 'left',
        offsetY: 30,
        style: {
            fontSize: '24px'
        }
    },
    fill: {
        type: 'solid',
        colors: ['transparent', 'transparent', 'transparent']
    },
    colors: ['#22c55e'],
    grid: {
        show: false,
        row: {
            colors: ['transparent'],
            opacity: 0.5
        },
    },
    xaxis: {
        categories: chartData.value.map(item => item[0]), // Extract the categories from chartData
    },
}));

</script>

