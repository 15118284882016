<template>
    <Sidebar
        v-model:visible="show"
        @hide="emit('hide')"
        header="Filters"
        position="right"
        class="w-full md:w-44rem overflow-x-hidden"
        style="width: 500px;"
    >
        <BaseFormCard @submit="submit" :loading="form.processing" :form="form">
            <div class="task-modal flex flex-col gap-4 text-sm px-2">

                <MultiSelect
                    :options="accounts"
                    v-model="accountModel"
                    label="Accounts"
                    optionLabel="name"
                    placeholder="Select Accounts"
                    id="members"
                    class="w-full md:max-w-full"
                >
                    <template #option="slotProps">
                        <div class="flex align-items-center gap-2">
                            <BaseAvatar
                                :src="slotProps.option.avatar"
                                :name="slotProps.option.name"
                                class="w-5 h-5 rounded-full"
                            />
                            <div>{{ slotProps.option.name }}</div>
                        </div>
                    </template>
                </MultiSelect>
                <MultiSelect
                    :options="users"
                    v-model="usersModel"
                    label="Members"
                    optionLabel="name"
                    placeholder="Select Members"
                    id="members"
                    class="w-full md:max-w-full"
                >
                    <template #option="slotProps">
                        <div class="flex align-items-center gap-2">
                            <BaseAvatar
                                :src="slotProps.option.avatar"
                                :name="slotProps.option.name"
                                class="w-5 h-5 rounded-full"
                            />
                            <div>{{ slotProps.option.name }}</div>
                        </div>
                    </template>
                </MultiSelect>

                <MultiSelect
                    :options="shareOptions"
                    v-model="sharing_type"
                    label="Access"
                    optionLabel="name"
                    placeholder="Select Sharing Type"
                    id="members"
                    class="w-full md:max-w-full"
                >
                    <template #option="slotProps">
                        <div>{{ slotProps.option.name }}</div>
                    </template>
                </MultiSelect>
                <MultiSelect
                    :options="roomStatusOptions"
                    v-model="statusModel"
                    label="Room Status"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select Room Status"
                    id="members"
                    class="w-full md:max-w-full"
                >

                </MultiSelect>

                <MultiSelect
                    :options="roomActivityTypeOptions"
                    v-model="activity_type"
                    label="Current Activity"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select Activity Type"
                    id="members"
                    class="w-full md:max-w-full"
                >

                </MultiSelect>
            </div>

            <template #footer>
                <div class="flex gap-2 mt-auto px-2">
                    <Button class="w-full mt-4" size="small" variant="secondary"
                            @click="onClearFilters"
                    >
                        Clear
                    </Button>
                    <Button
                        class="w-full mt-4" :loading="loading" size="small" type="submit"
                    >
                        Apply
                    </Button>
                </div>
            </template>
        </BaseFormCard>

    </Sidebar>
</template>
<script setup lang="ts">
import Button from '@/tiptap/components/ui/button/Button.vue';
import Sidebar from "primevue/sidebar";
import { ref, watch } from "vue";
import { useRoomPage } from '@/pages/RoomDetail/hooks/useRoomPage.js';
import BaseAvatar from "@/Components/forms/BaseAvatar.vue";
import MultiSelect from "@/Components/forms/Multiselect.vue";
import BaseFormCard from "@/Components/forms/BaseFormCard.vue";
import useValidatedForm from "@/hooks/useValidatedFormNew";
import { useRoomStatusOptions } from '@/hooks/useRoomStatusOptions';
import { useRoomActivityType } from '@/hooks/useRoomActivityType';
import { useRoomSharingType } from '@/hooks/useRoomSharingType';
import { usePage } from "@inertiajs/vue3";

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['hide', 'submit'])

const page = usePage();
const accounts = ref(page.props.accounts.map(account => ({ ...account, label: account.name })));
const {room} = useRoomPage();
const show = ref(props.show)
const loading = ref(false);
const todoDetail = ref({});
const { roomStatusOptions } = useRoomStatusOptions();
const { roomActivityTypeOptions } = useRoomActivityType();
const { shareOptions } = useRoomSharingType();
const users = ref(page.props.users.map(user => ({ id: user.id, name: user.name, avatar: user.avatar || null })));
watch(() => props.show, (v) => show.value = v)

const initialValues = {
    accounts: [],
    status: [],
    activity_type: [],
    users: [],
    sharing_type: []
};

const form = useValidatedForm(initialValues, {})

const accountModel = form.vee.useFieldModel('accounts');
const usersModel = form.vee.useFieldModel('users');
const statusModel = form.vee.useFieldModel('status');
const activity_type = form.vee.useFieldModel('activity_type');
const sharing_type = form.vee.useFieldModel('sharing_type');

function onClearFilters() {
    emit('submit', initialValues);
    form.vee.setValues(initialValues);
    emit('hide');
}

async function submit() {
    const data = form.data();

    emit('submit', {
        accounts: data.accounts?.map((d) => d.id) || [],
        users: data.users?.map((d) => d.id) || [],
        status: data.status,
        activity_type: data.activity_type,
        sharing_type: data.sharing_type?.map((d) => d.value) || []
    });
    emit('hide');
}

</script>
