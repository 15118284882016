<template>
    <div class="py-[2px] px-2 text-[10px] leading-[16px] font-semibold border uppercase rounded" :class="statusClasses">
        <slot>
            {{ humanReadableStatus }}
        </slot>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { startCase } from "lodash-es";
import { softwareAdaptionItemStatuses } from '@/tiptap/extensions/SoftwareAdoption/statuses.js';

const props = defineProps({
    status: {
        type: String,
        required: true,
    },
})

const statusClasses = computed(() => {
    const classMap = {
        'Evaluation': 'text-blue-600 border-blue-300 bg-blue-50',
        'Purchased': 'text-green-600 border-green-300 bg-green-50',
        'Deployment in Progress': 'text-orange-600 border-orange-300 bg-orange-50',
        'Active': 'text-green-600 border-green-300 bg-green-50',
        'Partial Deployment': 'text-orange-600 border-orange-300 bg-orange-50',
        'Review': 'text-purple-600 border-purple-300 bg-purple-50',
        'Not Owned': 'text-red-600 border-red-300 bg-red-50',
        'Retired': 'text-gray-600 border-gray-300 bg-gray-50',
        default: 'text-gray-600 border-gray-300 bg-gray-50'
    };
    return classMap[props.status] || classMap.default
})

const humanReadableStatus = computed(() => {
    return startCase(props.status)
})
</script>
<style>
</style>
