import { useConfirm } from 'primevue/useconfirm';

export function useConfirmationModal() {
  const baseConfirm = useConfirm();

  return (options) => {
      baseConfirm.require({
          message: 'Are you sure you want to delete?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle mr-2 text-yellow-500 text-2xl',
          rejectClass: 'p-button-secondary p-button-outlined reject-button',
          rejectLabel: 'Cancel',
          acceptLabel: 'Delete',
          acceptClass: 'bg-red-500 hover:bg-red-600 border-red-500 hover:border-red-600 confirm-button',
          ...options
      });
  };
}
