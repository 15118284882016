<template>
    <div class="bg-blue-50">
        <Toast/>
        <Toaster />
        <Sidebar
            v-model="sidebarOpen"
            v-model:sidebar-visible="isSidebarVisible"
        />
        <div class="bg-blue-50 min-h-screen transition-all duration-300 ease-in-out"
             :class="{
                'lg:pl-60': isSidebarVisible,
                'lg:pl-14': !isSidebarVisible,
             }">
            <div class="h-4 bg-blue-50"></div>
            <div class="bg-white min-h-[calc(100vh-16px)] rounded-tl-3xl overflow-hidden">

                <div
                    class="sticky top-0 z-40 flex h-12 mb-4 shrink-0 items-end gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6"
                    :class="{
                    'lg:px-8': isSidebarVisible,
                    'lg:pl-20': !isSidebarVisible
                }"
                >
                    <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
                        <span class="sr-only">Open sidebar</span>
                        <Bars3Icon class="h-6 w-6" aria-hidden="true"/>
                    </button>

                    <!-- Separator -->
                    <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"/>

                    <div class="flex flex-1 self-stretch lg:gap-x-6"
                    >
                        <div class="flex-1 flex items-end">
                            <h1 class="text-gray-950 font-semibold text-lg pl-2">
                                <slot name="title"></slot>
                            </h1>
                        </div>
                    </div>
                </div>

                <main>
                    <div class="px-4 sm:px-6"
                         :class="{
                        'lg:px-8': isSidebarVisible,
                        'lg:pl-20': !isSidebarVisible
                    }"
                    >
                        <slot/>
                    </div>
                </main>

            </div>
        </div>
        <WelcomeAppSumoModal />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import { usePage } from '@inertiajs/vue3';

import { useToast } from 'primevue/usetoast';
import Sidebar from '../Components/sidebar/Sidebar.vue';
import { usePusher } from '../hooks/usePusher.js';
import { Toaster } from "@/Components/ui/toast";
import WelcomeAppSumoModal from '@/Components/WelcomeAppSumoModal.vue';

usePusher();
const toast = useToast();
const page = usePage();

function showToast(message) {
    toast.add({severity: 'success', summary: '', detail: message, life: 3000});
}

// Watch for changes in the flash messages
watch(() => page.props.flash, (flash) => {
    if (flash && flash.success) {
       showToast(flash.success);
    }
}, {immediate: true, deep: true});


const isSidebarVisible = ref(localStorage.getItem('isSidebarVisible') !== 'false');
const sidebarOpen = ref(false)
</script>
