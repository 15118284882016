import { Group } from './types'
import {
    onInsertBulletList,
    onInsertTable,
    onInsertTaskList,
    prepareEditor
} from "@/tiptap/extensions/SlashCommand/commands.ts";
import { useTipTapStore } from "@/tiptap/stores/useTipTapStore";
import { usePage } from "@inertiajs/vue3";
import axios from "axios";

export const GROUPS: Group[] = [
  {
    name: 'format',
    title: 'Format',
    commands: [
      {
        name: 'heading1',
        label: 'Heading 1',
        iconName: 'Heading1',
        description: 'High priority section title',
        aliases: ['h1'],
        action: editor => {
          editor.chain().focus().setHeading({ level: 1 }).run()
        },
      },
      {
        name: 'heading2',
        label: 'Heading 2',
        iconName: 'Heading2',
        description: 'Medium priority section title',
        aliases: ['h2'],
        action: editor => {
          editor.chain().focus().setHeading({ level: 2 }).run()
        },
      },
      {
        name: 'heading3',
        label: 'Heading 3',
        iconName: 'Heading3',
        description: 'Low priority section title',
        aliases: ['h3'],
        action: editor => {
          editor.chain().focus().setHeading({ level: 3 }).run()
        },
      },
      {
        name: 'bulletList',
        label: 'Bullet List',
        iconName: 'List',
        description: 'Unordered list of items',
        aliases: ['ul'],
        action: onInsertBulletList,
      },
      {
        name: 'numberedList',
        label: 'Numbered List',
        iconName: 'ListOrdered',
        description: 'Ordered list of items',
        aliases: ['ol'],
        action: editor => {
          editor.chain().focus().toggleOrderedList().run()
        },
      },
      {
        name: 'taskList',
        label: 'Task List',
        iconName: 'ListTodo',
        description: 'Task list with todo items',
        aliases: ['todo'],
        action: onInsertTaskList,
      },
      {
        name: 'blockquote',
        label: 'Blockquote',
        iconName: 'Quote',
        description: 'Element for quoting',
        action: editor => {
          editor.chain().focus().setBlockquote().run()
        },
      },
      {
        name: 'emoji',
        label: 'Emoji',
        iconName: 'Smile',
        description: 'Insert Emoji',
        action: editor => {
            prepareEditor(editor).insertContent(':').run()
        },
      },
      {
        name: 'codeBlock',
        label: 'Code Block',
        iconName: 'SquareCode',
        description: 'Code block with syntax highlighting',
        shouldBeHidden: editor => editor.isActive('columns'),
        action: editor => {
          editor.chain().focus().setCodeBlock().run()
        }
      },
    ],
  },
  {
    name: 'insert',
    title: 'Insert',
    commands: [
      {
        name: 'table',
        label: 'Table',
        iconName: 'Table',
        description: 'Insert a table',
        shouldBeHidden: editor => editor.isActive('columns'),
        action: onInsertTable,
      },
      {
        name: 'columns',
        label: 'Columns',
        iconName: 'Columns',
        description: 'Add two column content',
        aliases: ['cols'],
        shouldBeHidden: editor => editor.isActive('columns'),
        action: editor => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 2)
            .run()
        },
      },
    {
        name: 'embed',
        label: 'Embed',
        iconName: 'ExternalLink',
        description: 'Place an embed in your note',
        shouldBeHidden: editor => editor.isActive('columns'),
        action: (editor) => {
            useTipTapStore().toggleShowEmbedDialog(true);
        },
    },
    {
        name: 'question',
        label: 'Question',
        iconName: 'MessageCircleQuestion',
        description: 'Add question',
        shouldBeHidden: editor => editor.isActive('columns'),
        action: (editor) => {
            const roomUlid = useTipTapStore().currentRoomUlid;
            const currentPage = usePage().props.currentPage;

            axios.post(`/questions`, {
                room_ulid: roomUlid,
                page_ulid: currentPage.ulid,
            }).then((response) => {
                const { question } = response.data;

                editor.chain().focus().setQuestion({
                    id: question.id,
                    firstRender: '1232',
                }).run();
            }).catch((error) => {
                console.log(error);
            })
        },
    },
    {
        name: 'header',
        label: 'Header',
        iconName: 'AppWindowMac',
        description: 'Place header in your note',
        shouldBeHidden: editor => editor.isActive('columns'),
        action: (editor) => {
            const roomUlid = useTipTapStore().currentRoomUlid;
            const currentPage = usePage().props.currentPage;

            const node = editor.chain().focus().setSpinner({
                id: 'header-spinner'
            }).run();

            axios.post(`/headers`, {
                room_ulid: roomUlid,
                page_ulid: currentPage.ulid,
            }).then((response) => {
                const { header } = response.data;

                editor.chain().focus().setHeader({
                    id: header.id,
                }).run();
            }).catch((error) => {
                console.log(error);
            })
        },
    },
        {
            name: 'price-card',
            label: 'Price card',
            iconName: 'BadgeCent',
            description: 'Place header in your note',
            action: (editor) => {
                const roomUlid = useTipTapStore().currentRoomUlid;
                const currentPage = usePage().props.currentPage;

                axios.post(`/prices`, {
                    room_ulid: roomUlid,
                    page_ulid: currentPage.ulid
                }).then((response) => {
                    const { price } = response.data;

                    editor.chain().focus().setPriceCard({
                        id: price.id,
                    }).focus().run();
                }).catch((error) => {
                    console.log(error);
                })
            },
        },
        {
            name: 'contact-card',
            label: 'Contact card',
            iconName: 'Contact',
            description: 'Place a contact card',
            action: (editor) => {
                const roomUlid = useTipTapStore().currentRoomUlid;

                axios.post(`/contacts`, {
                    room_ulid: roomUlid,
                }).then((response) => {
                    const { contact } = response.data;

                    editor.chain().focus().setContactCard({
                        id: contact.id,
                    }).run();
                }).catch((error) => {
                    console.log(error);
                })
            },
        },
        {
            name: 'project-plan',
            label: 'Project Plan',
            iconName: 'SquareKanban',
            description: 'Create a project plan',
            shouldBeHidden: editor => editor.isActive('columns'),
            action: (editor) => {
                const roomUlid = useTipTapStore().currentRoomUlid;
                const currentPage = usePage().props.currentPage;

                // const node = editor.chain().focus().setProjectPlanSpinner({
                //     id: 'project-plan-spinner'
                // }).run();

                axios.post(`/plans`, {
                    room_ulid: roomUlid,
                    page_ulid: currentPage.ulid,
                }).then((response) => {
                    const { plan } = response.data;

                    editor.chain().focus().setProjectPlan({
                        id: plan.id,
                    }).run();
                }).catch((error) => {
                    console.log(error);
                })
            },
        },
        {
            name: 'software-adoption',
            label: 'Software Adoption',
            iconName: 'Laptop',
            description: 'Create a software adoption',
            shouldBeHidden: editor => editor.isActive('columns'),
            action: (editor) => {
                const roomUlid = useTipTapStore().currentRoomUlid;
                const currentPage = usePage().props.currentPage;

                // const node = editor.chain().focus().setProjectPlanSpinner({
                //     id: 'project-plan-spinner'
                // }).run();

                axios.post(`/software-adoption`, {
                    room_ulid: roomUlid,
                    page_ulid: currentPage.ulid,
                }).then((response) => {
                    const { plan } = response.data;

                    editor.chain().focus().setSoftwareAdoption({
                        id: plan.id,
                    }).run();
                }).catch((error) => {
                    console.log(error);
                })
            },
        },
      {
        name: 'horizontalRule',
        label: 'Horizontal Rule',
        iconName: 'Minus',
        description: 'Insert a horizontal divider',
        aliases: ['hr'],
        action: editor => {
          editor.chain().focus().setHorizontalRule().run()
        },
      },
    ],
  },
    {
        name: 'upload',
        title: 'Upload',
        commands: [
            {
                name: 'image',
                label: 'Image',
                iconName: 'Image',
                description: 'Insert an image',
                aliases: ['img'],
                action: editor => {
                    const tipTapStore = useTipTapStore()
                    tipTapStore.setUploadDialogTab('upload')
                    tipTapStore.toggleShowUploadDialog(true);
                },
            },
            {
                name: 'library',
                label: 'Library',
                iconName: 'LibraryBig',
                description: 'Insert a file from the library',
                aliases: ['img'],
                action: editor => {
                    const tipTapStore = useTipTapStore()
                    tipTapStore.setUploadDialogTab('library')
                    tipTapStore.toggleShowUploadDialog(true);
                },
            },
            {
                name: 'file',
                label: 'File',
                iconName: 'Upload',
                description: 'Upload any file',
                shouldBeHidden: editor => editor.isActive('columns'),
                action: (editor) => {
                    const roomUlid = useTipTapStore().currentRoomUlid;
                    const currentPage = usePage().props.currentPage;

                    axios.post(`/files`, {
                        room_ulid: roomUlid,
                        page_ulid: currentPage.ulid,
                    }).then((response) => {
                        const { file } = response.data;

                        editor.chain().focus().setFile({
                            id: file.id,
                        }).run();
                    }).catch((error) => {
                        console.log(error);
                    })
                },
            },
        ]
    }
]

export default GROUPS
