<template>
    <div class="flex justify-end group-hover:opacity-100">
        <DeleteRow @onDelete="() => onDeleteInvitation(value)"/>
        <Button
            v-if="!value.validated_at"
            @click="() => resend(value)"
            size="small"
            variant="ghost"
            icon="pi pi-send"
            text aria-label="Resend"
        >
            <Icon name="Send"></Icon>
        </Button>
    </div>
</template>
<script setup>
import { useForm } from '@inertiajs/vue3';
import { defineProps } from 'vue';
import Button from '../../../tiptap/components/ui/button/Button.vue';
import DeleteRow from '@/Components/DeleteRow.vue';
import { useToast } from 'primevue/usetoast';
import Icon from '../../../tiptap/components/ui/Icon.vue';

const toast = useToast();
const props = defineProps({
    value: {
        type: Object
    },
    invitations: {
        type: Array,
    },
    onUpdate: {
        type: Function
    },
})

const form = useForm({});

function onDeleteInvitation(data) {
    form.delete('/invitations/' + data.id, {
        preserveScroll: true,
        onSuccess: () => {
            const newData = props.invitations.value.filter(account => account.id !== data.id);

            props.onUpdate(newData);
        }
    });
}

function resend(data) {
    form.post('/invitations/' + data.id + '/resend', {
        preserveScroll: true,
        onSuccess: (data) => {
            const newData = data.props.invitations;

            props.onUpdate(newData);
        }
    });
}
</script>
