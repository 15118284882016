<template>
  <BubbleMenu
      :tippyOptions="{ popperOptions: { placement: 'top-start' }, zIndex: 99 }"
      :editor="editor"
      pluginKey="textMenu"
      :shouldShow="states.shouldShow"
      :updateDelay="100"
  >
    <ToolbarWrapper>
      <ToolbarDivider/>
      <ContentTypePicker :options="blockOptions"/>
      <FontFamilyPicker :model-value="states.currentFont || ''" @update:model-value="commands.onSetFont"/>
      <FontSizePicker :model-value="states.currentSize || ''" @update:model-value="commands.onSetFontSize"/>
      <ToolbarDivider/>
      <ToolbarButton tooltip="Bold" :tooltipShortcut="['Mod', 'B']" @click="commands.onBold" :active="states.isBold">
        <Icon name="Bold"/>
      </ToolbarButton>
      <ToolbarButton tooltip="Italic" :tooltipShortcut="['Mod', 'I']" @click="commands.onItalic"
                     :active="states.isItalic">
        <Icon name="Italic"/>
      </ToolbarButton>
      <ToolbarButton tooltip="Underline" :tooltipShortcut="['Mod', 'U']" @click="commands.onUnderline"
                     :active="states.isUnderline">
        <Icon name="Underline"/>
      </ToolbarButton>
      <ToolbarButton tooltip="Strikehrough" :tooltipShortcut="['Mod', 'X']" @click="commands.onStrike"
                     :active="states.isStrike">
        <Icon name="Strikethrough"/>
      </ToolbarButton>
      <ToolbarButton tooltip="Code" :tooltipShortcut="['Mod', 'E']" @click="commands.onCode" :active="states.isCode">
        <Icon name="Code"/>
      </ToolbarButton>
      <ToolbarButton tooltip="Code block" @click="commands.onCodeBlock">
        <Icon name="Code2"/>
      </ToolbarButton>
      <EditLinkPopover @set="commands.onLink"/>
      <PopoverRoot>
        <PopoverTrigger asChild>
          <ToolbarButton :active="!!states.currentHighlight" tooltip="Highlight text">
            <Icon name="Highlighter"/>
          </ToolbarButton>
        </PopoverTrigger>
        <PopoverContent side="top" :sideOffset="8" asChild>
          <Surface class="p-1">
            <ColorPicker
                :color="states.currentHighlight"
                @change="commands.onChangeHighlight"
                @clear="commands.onClearHighlight"
            />
          </Surface>
        </PopoverContent>
      </PopoverRoot>
      <PopoverRoot>
        <PopoverTrigger asChild>
          <ToolbarButton :active="!!states.currentColor" tooltip="Text color">
            <Icon name="Palette"/>
          </ToolbarButton>
        </PopoverTrigger>
        <PopoverContent side="top" :sideOffset="8" asChild>
          <Surface class="p-1">
            <ColorPicker
                :color="states.currentColor"
                @change="commands.onChangeColor"
                @clear="commands.onClearColor"
            />
          </Surface>
        </PopoverContent>
      </PopoverRoot>
      <PopoverRoot>
        <PopoverTrigger asChild>
          <ToolbarButton tooltip="More options">
            <Icon name="MoreVertical"/>
          </ToolbarButton>
        </PopoverTrigger>
        <PopoverContent side="top" asChild>
          <ToolbarWrapper>
            <ToolbarButton
                tooltip="Subscript"
                :tooltipShortcut="['Mod','.']"
                @click="commands.onSubscript"
                :active="states.isSubscript"
            >
              <Icon name="Subscript"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Superscript"
                :tooltipShortcut="['Mod',',']"
                @click="commands.onSuperscript"
                :active="states.isSuperscript"
            >
              <Icon name="Superscript"/>
            </ToolbarButton>
            <ToolbarDivider/>
            <ToolbarButton
                tooltip="Align left"
                :tooltipShortcut="['Shift','Mod', 'L']"
                @click="commands.onAlignLeft"
                :active="states.isAlignLeft"
            >
              <Icon name="AlignLeft"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Align center"
                :tooltipShortcut="['Shift','Mod', 'E']"
                @click="commands.onAlignCenter"
                :active="states.isAlignCenter"
            >
              <Icon name="AlignCenter"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Align right"
                :tooltipShortcut="['Shift','Mod', 'R']"
                @click="commands.onAlignRight"
                :active="states.isAlignRight"
            >
              <Icon name="AlignRight"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Justify"
                :tooltipShortcut="['Shift','Mod', 'J']"
                @click="commands.onAlignJustify"
                :active="states.isAlignJustify"
            >
              <Icon name="AlignJustify"/>
            </ToolbarButton>
          </ToolbarWrapper>
        </PopoverContent>
      </PopoverRoot>
    </ToolbarWrapper>
  </BubbleMenu>
</template>
<script setup>
import { PopoverContent, PopoverRoot, PopoverTrigger } from 'radix-vue'
import ToolbarWrapper from "@/tiptap/components/ui/toolbar/ToolbarWrapper.vue";
import ToolbarDivider from "@/tiptap/components/ui/toolbar/ToolbarDivider.vue";
import ContentTypePicker from "@/tiptap/components/menus/TextMenu/components/ContentTypePicker.vue";
import FontFamilyPicker from "@/tiptap/components/menus/TextMenu/components/FontFamilyPicker.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";
import FontSizePicker from "@/tiptap/components/menus/TextMenu/components/FontSizePicker.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import Surface from "@/tiptap/components/ui/Surface.vue";
import { useTextmenuCommands } from "@/tiptap/components/menus/TextMenu/composables/useTextmenuCommands";
import { useTextmenuStates } from "@/tiptap/components/menus/TextMenu/composables/useTextmenuStates";
import { useTextmenuContentTypes } from "@/tiptap/components/menus/TextMenu/composables/useTextmenuContentTypes";
import { BubbleMenu } from "@tiptap/vue-3";
import ColorPicker from "@/tiptap/components/menus/panels/Colorpicker/ColorPicker.vue";
import EditLinkPopover from "@/tiptap/components/menus/TextMenu/components/EditLinkPopover.vue";

const props = defineProps({
  editor: Object,
})
const commands = useTextmenuCommands(props.editor)
const states = useTextmenuStates(props.editor)
const blockOptions = useTextmenuContentTypes(props.editor)
</script>
