import defaultMime, { Mime } from 'mime';

export function getHumanFileSize(sizeInBytes) {
    if (sizeInBytes === 0) {
        return `0B`
    }
    let i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    const convertedSize = (sizeInBytes / Math.pow(1024, i)).toFixed(2)
    const sizeMetric = ['B', 'kB', 'MB', 'GB', 'TB'][i]
    return `${convertedSize} ${sizeMetric}`
}

export const fileTypeMap = {
    'Word document': ['doc', 'docx'],
    'Image': ['jpg', 'png', 'gif', 'svg', 'jpeg'],
    'Presentation': ['ppt', 'pptx'],
    'Excel': ['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xlt', 'xla', 'xlr', 'xlw'],
    'text/csv': ['csv'],
    'Open Document': ['ods'],
    'Pdf Document': ['pdf'],
    'Text file': ['txt']
}

export const fileTypes = {
    Word: 'word document',
    Image: 'image',
    Presentation: 'presentation',
    Excel: 'excel',
    Csv: 'text/csv',
    PdfDocument: 'pdf document',
    Text: 'text file',
    Video: 'video',
    VideoQuickTime: 'video/quicktime',
    Json: 'application/json',
}

export const previewTypes = [
    fileTypes.Csv,
    fileTypes.PdfDocument,
    fileTypes.Text,
    fileTypes.Video,
    fileTypes.Image,
    fileTypes.Json,
]

export const docTypes = [
    fileTypes.Word,
    fileTypes.Presentation,
    fileTypes.Excel,
    fileTypes.PdfDocument,
]


export const typeColors = {
    'word document': {
        main: 'bg-blue-500',
        text: 'text-blue-500',
        background: 'text-blue-100',
    },
    'pdf document': {
        main: 'bg-red-500',
        text: 'text-red-500',
        background: 'text-red-100',
    },
    'image': {
        main: 'bg-orange-500',
        text: 'text-orange-500',
        background: 'text-orange-100',
    },
    'excel': {
        main: 'bg-green-500',
        text: 'text-green-500',
        background: 'text-green-100',
    },
    'text/csv': {
        main: 'bg-blue-500',
        text: 'text-blue-500',
        background: 'text-blue-100',
    },
    default: {
        main: 'bg-gray-500',
        text: 'text-gray-500',
        background: 'text-gray-100',
    },
}

export function getFileTypeColor(pathOrExtension, loading = false) {
    if (loading) {
        return typeColors.default
    }
    const type = getFileType(pathOrExtension) || ''
    return typeColors[type] || typeColors.default
}

let myMime = new Mime(fileTypeMap)

export function getFileExtension(fileName) {
    if (!fileName) {
        return ''
    }
    const parts = fileName.split('.')
    return parts[parts.length - 1] || ''
}

export function isImage(file) {
    if (!file) {
        return false
    }
    const fileType = getFileType(file?.file_name)
    return fileType === fileTypes.Image
}

export function isPreViewable(file) {
    if (!file) {
        return false
    }
    const fileType = getFileType(file?.file_name)
    return previewTypes.includes(fileType) || docTypes.includes(fileType)
}

export function getFileWithoutExtension(fileName) {
    const parts = fileName.split('.')
    return parts.slice(0, parts.length - 1).join('.')
}

export function getFileType(pathOrExtension) {
    return myMime.getType(pathOrExtension) || defaultMime.getType(pathOrExtension)
}

export function downloadFile(url, fileName) {
    const link = document.createElement('a')
    link.href = url
    link.target = '_blank'
    if (fileName) {
        link.download = fileName
    }
    link.click()
}

export function updateFileProgress(params) {
    if (!params.file?.name || !params.file) {
        return
    }
    const fileProgress = params.fileProgress.value[params.file.name]
    if (!fileProgress) {
        params.fileProgress.value = {
            [params.file.name]: {
                progress: 0,
                error: '',
            },
        }
    }
    params.fileProgress.value[params.file.name].progress = Math.round(params.progress)
}

export async function fileToDataURL(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}


export const acceptedFileTypes = `.pdf,.docx,.doc,.txt,.rtf,.jpg,.jpeg,.png,.gif,.bmp,.xls,.xlsx,.ppt,.pptx,.svg`
