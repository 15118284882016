import { useToast } from 'primevue/usetoast';

export function displayErrorMessagesAsToast(err = {}, toast, toastOptions = {}) {
    if (!toast) {
        console.error('No toast service provided to displayErrorMessagesAsToast')
        return
    }

    if (err.message === "Request aborted") {
        return;
    }

    const apiMessage = err?.response?.data?.message || err?.response?.data?.error

    if (apiMessage) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: apiMessage,
            life: 3000,
            ...toastOptions,
        })
        return;
    }

    let messages = []
    if (typeof err !== "object") {
        messages = []
    } else {messages = Object.values(err)
    }

    messages.forEach(message => {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: message,
            life: 3000,
            ...toastOptions,
        })
    })
}
