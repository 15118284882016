<template>
    <button
        v-if="tag === 'button'"
        :class="buttonClassName"
        :disabled="disabled"
        :type="type"
        v-bind="$attrs"
    >

        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 0.75rem"></i>
        <slot></slot>
    </button>
    <component
        v-if="tag !== 'button'"
        :is="tag"
        :class="buttonClassName"
        :disabled="disabled"
        :type="type"
        v-bind="$attrs"
    >

        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 0.75rem"></i>
        <slot></slot>
    </component>
</template>

<script setup>
import {computed, toRefs} from 'vue';

const props = defineProps({
    variant: {
        type: String,
        default: 'primary',
        validator: (value) => ['primary', 'secondary', 'tertiary', 'ghost', 'outline'].includes(value),
    },
    active: Boolean,
    activeClassname: String,
    buttonSize: {
        type: String,
        default: 'medium',
        validator: (value) => ['medium', 'small', 'icon', 'iconSmall'].includes(value),
    },
    type: {
        type: String,
        default: 'button'
    },
    disabled: Boolean,
    loading: {
        type: Boolean,
        default: false
    },
    tag: {
        type: String,
        default: 'button'
    }
});

const buttonClassName = computed(() => {
    const baseClass = 'flex group items-center justify-center border border-transparent gap-2 text-sm font-medium rounded disabled:opacity-50 whitespace-nowrap';
    const variantClasses = {
        primary: `text-white bg-primary-500 border-primary-500 dark:text-black dark:bg-white dark:border-white ${!props.disabled && !props.active && 'hover:bg-primary-600 active:bg-primary-600 dark:hover:bg-gray-200 dark:active:bg-gray-300'} ${props.active && `bg-gray-900 dark:bg-gray-300 ${props.activeClassname}`}`,
        outline: `text-primary-500 bg-white !border-primary-500 ${!props.disabled && !props.active && 'hover:bg-primary-100 active:bg-primary-100'} ${props.active && `bg-primary-100 dark:bg-gray-300 ${props.activeClassname}`}`,
        secondary: `!border-gray-200 text-gray-950 dark:text-white ${!props.disabled && !props.active && 'hover:bg-gray-50 hover:border-gray-300 active:bg-gray-100 dark:hover:bg-gray-900 dark:active:bg-gray-800'} ${props.active && 'bg-gray-200 dark:bg-gray-800'}`,
        tertiary: `bg-gray-50 text-gray-950 dark:bg-gray-900 dark:text-white dark:border-gray-900 ${!props.disabled && !props.active && 'hover:bg-gray-100 active:bg-gray-200 dark:hover:bg-gray-800 dark:active:bg-gray-700'} ${props.active && `bg-gray-200 dark:bg-gray-800 ${props.activeClassname}`}`,
        ghost: `bg-transparent border-transparent text-gray-500 dark:text-gray-400 ${!props.disabled && !props.active && 'hover:bg-black/5 hover:text-gray-950 active:bg-black/10 active:text-gray-800 dark:hover:bg-white/10 dark:hover:text-gray-300 dark:active:text-gray-200'} ${props.active && `bg-black/10 text-gray-800 dark:bg-white/20 dark:text-gray-200 ${props.activeClassname}`}`,
    };
    const sizeClasses = {
        medium: 'py-2 px-3',
        small: 'py-1 px-2',
        icon: 'w-8 h-8',
        iconSmall: 'w-6 h-6',
        iconLarge: 'w-10 h-10',
    };

    return [baseClass, variantClasses[props.variant], sizeClasses[props.buttonSize]].filter(Boolean).join(' ');
});
</script>
