<template>
    <AuthenticatedLayout>
        <template #title>Profile</template>
        <BaseFormCard @submit="submit" :loading="form.processing" save-text="Update" :form="form">
            <FormCardSection title="Profile Information" container-class="-mt-4">
                <div class="col-span-6">
                    <div class="flex flex-col gap-2">
                        <FormField v-slot="{ componentField }" name="first_name">
                            <FormItem v-auto-animate>
                                <FormLabel>First Name</FormLabel>
                                <FormControl>
                                    <Input type="text" v-bind="componentField" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="flex flex-col gap-2">
                        <FormField v-slot="{ componentField }" name="last_name">
                            <FormItem v-auto-animate>
                                <FormLabel>Last Name</FormLabel>
                                <FormControl>
                                    <Input type="text" v-bind="componentField" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>

                    </div>
                    <div class="flex flex-col gap-2">
                        <FormField v-slot="{ componentField }" name="title">
                            <FormItem v-auto-animate>
                                <FormLabel>Title</FormLabel>
                                <FormControl>
                                    <Input type="text" v-bind="componentField" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="flex flex-col gap-2">
                        <FormField v-slot="{ componentField }" name="phone">
                            <FormItem v-auto-animate>
                                <FormLabel>Phone</FormLabel>
                                <FormControl>
                                    <Input type="text" v-bind="componentField" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="flex flex-col gap-2">
                        <FormField v-slot="{ componentField }" name="calendar_link">
                            <FormItem v-auto-animate>
                                <FormLabel>Calendar Link</FormLabel>
                                <FormControl>
                                    <Input type="text" v-bind="componentField" />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        </FormField>
                    </div>
                    <div class="my-4">
                        <label for="user_avatar" class="block text-sm font-medium leading-6 text-gray-900">
                            Profile Picture
                        </label>
                        <div class="mt-2">
                            <AvatarUpload
                                name="user_avatar"
                                class="h-[100px]"
                                :can-crop="false"
                                v-model="form.user_avatar"
                                @update:modelValue="(e) => {
                                    form.vee.setFieldValue('user_avatar', e);
                                }"
                            />
                        </div>
                    </div>
                </div>
            </FormCardSection>
            <div class="mt-8"></div>
            <FormCardSection title="Change Password">
                <PasswordInput v-model="form.password" :error="form.errors.password" class="col-span-6"/>
                <PasswordInput v-model="form.password_confirmation" :error="form.errors.password_confirmation" label="Password confirmation" class="col-span-6"/>
            </FormCardSection>
        </BaseFormCard>
    </AuthenticatedLayout>
</template>
<script setup>
import * as yup from "yup";
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import FormCardSection from '@/Components/forms/FormCardSection.vue';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import { GlobeAltIcon, UserIcon } from '@heroicons/vue/24/outline/index.js';
import PasswordInput from '@/Components/forms/PasswordInput.vue';
import AvatarUpload from '@/Components/forms/AvatarUpload.vue';
import InputText from '@/Components/forms/InputText.vue';
import { useForm, usePage, router } from '@inertiajs/vue3';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/Components/ui/form';
import Input from '@/Components/ui/input/Input.vue';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import useValidatedForm from '../../hooks/useValidatedFormNew';
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const auth = usePage().props.auth.user;

const schema = yup.object().shape({
    first_name: yup.string().required('Please enter your first name.'),
    last_name: yup.string().required('Please enter your last name.'),
    password: yup.string()
        .nullable()
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Password must contain at least one symbol')
        .test('no-spaces', 'Password must not contain spaces', value => !/\s/.test(value)),
    password_confirmation: yup.string()
        .nullable()
        .oneOf([yup.ref('password')], 'Your passwords do not match.'),
    company_avatar: yup.mixed().notRequired(),
    title: yup.string().required('Please enter your title.').nullable(),
    phone: yup.string().required('Please enter your phone number.').nullable(),
    calendar_link: yup.string().required('Please enter your calendar link.').nullable(),
});
const form = useValidatedForm({
    first_name: auth.first_name,
    last_name: auth.last_name,
    phone: auth.phone,
    title: auth.title,
    calendar_link: auth.calendar_link,
    password: null,
    password_confirmation: null,
    user_avatar: auth.avatar,
}, schema);

function submit() {
    form
        .transform(data => {
            console.log('data', data);
            if (!(data.user_avatar instanceof File)) {
                delete data['user_avatar'];
            }

            return data;
        })
        .post('/profile', {
            forceFormData: true,
            onSuccess: () => {
                router.reload();
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        })
}

</script>
