<template>
    <AuthenticatedLayout>
        <template #title>Accounts</template>
        <AccountsTable />
        <DeleteRowDialog />
    </AuthenticatedLayout>
</template>
<script setup>
import { useForm, usePage } from '@inertiajs/vue3'
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import DeleteRowDialog from '@/Components/DeleteRowDialog.vue';
import { useToast } from 'primevue/usetoast';
import AccountsTable from '@/pages/Accounts/AccountsTable.vue';

const toast = useToast();

const page = usePage();
const form = useForm({});

</script>
