export async function convertUrlToEmbed(src, otherOptions = {}) {
    const apiUrl = import.meta.env.VITE_IFRAMELY_URL
    const apiKey = import.meta.env.VITE_IFRAMELY_KEY
    const result = await axios.get(`${apiUrl}/iframely`, {
        params: {
            url: src,
            api_key: apiKey,
            omit_script: 1,
            ...otherOptions,
        }
    })
    if (result.error)  {
        result.error = result.error.replaceAll('Iframely', 'us')
    }
    return result.data
}
