<template>
    <div class="flex justify-end group-hover:opacity-100">
        <DeleteRow @onDelete="() => onDelete(value)"/>
    </div>
</template>
<script setup>
import { useForm } from '@inertiajs/vue3';
import { defineProps } from 'vue';
import { useAuth } from '@/hooks/useAuth.js';
import DeleteRow from '@/Components/DeleteRow.vue';
import { useToast } from 'primevue/usetoast';
import { displayErrorMessagesAsToast } from '@/utils/errorUtils.js';

const toast = useToast();
const props = defineProps({
    value: {
        type: Object
    },
    medias: {
        type: Array,
    },
    onDelete: {
        type: Function
    },
})

const form = useForm({});

function onDelete(data) {
    console.log('data', data);
    form.delete('/media/' + data.id, {
        preserveScroll: true,
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
        onSuccess: () => {
            const newData = props.medias.value.filter(model => model.id !== props.value.id);

            props.onDelete(newData)
        }
    });
}

</script>
