<script setup lang="ts">
import { Check, ChevronsUpDown } from 'lucide-vue-next'

import { computed, ref, watch } from 'vue'
import { Button } from '@/Components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList
} from '@/Components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/Components/ui/popover'
import { cn } from "@/tiptap/lib/utils";
import { usePage } from "@inertiajs/vue3";

import BaseAvatar from "@/Components/forms/BaseAvatar.vue";
const page = usePage();
const emit = defineEmits(['showCreateDialog', 'select'])
const props = defineProps({
    value: {

    }
})

const accounts = computed(() => {
    return page.props.accounts.map(account => ({...account, label: account.name}))
});

const open = ref(false)
const value = ref(
    accounts.value.find(account => account.id === props.value)?.label
)

watch(() => props.value, (newValue) => {
    value.value = accounts.value.find(account => account.id === props.value)?.label;
});

const selectedAccount = computed(() => {
    return page.props.accounts.find(account => {
        return account.name.includes(value.value);
    });
})

function onKeyUpEnter(e) {
    const inputValue = e.target.value;

    const find = page.props.accounts.find(account => account.name.includes(inputValue));

    if (! find) {
        emit('showCreateDialog', inputValue)
    }
}
</script>

<template>
    <Popover v-model:open="open" class="w-full overflow-hidden">
        <PopoverTrigger as-child>
            <Button
                variant="outline"
                role="combobox"
                :aria-expanded="open"
                class="w-full justify-between"
            >
                <div class="flex gap-2">
                    <template v-if="selectedAccount">
                        <BaseAvatar
                            :src="selectedAccount.avatar"
                            :name="selectedAccount.name"
                            class="w-5 h-5 rounded-full"
                        />
                        {{ value }}
                    </template>
                    <template v-else>
                        Select account
                    </template>
                </div>
                <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50"/>
            </Button>
        </PopoverTrigger>
        <PopoverContent class="PopoverContent w-full p-0" style="z-index: 9999;">
            <Command v-model="value">
                <CommandInput
                    class="h-9"
                    v-on:keyup.enter="onKeyUpEnter"
                    placeholder="Search or create account"
                />
                <CommandEmpty class="py-2">
                    <div class="relative flex cursor-default select-none items-center rounded-sm px-4 py-0.5 text-sm outline-none data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:data-[highlighted]:bg-gray-800 dark:data-[highlighted]:text-gray-50"
                    >
                        Press enter to create new account
                    </div>
                </CommandEmpty>
                <CommandList>
                    <CommandGroup>
                        <CommandItem
                            v-for="account in accounts"
                            :value="account.name"
                            @select="(ev) => {
                                if (typeof ev.detail.value === 'string') {
                                  value = ev.detail.value
                                }
                                open = false

                                emit('select', accounts.find(a => a.name === value))
                              }"
                        >
                            <Check
                                :class="cn(
                                  'h-4 w-4',
                                  value === account.label ? 'opacity-100' : 'opacity-0',
                                )"
                            />
                            <div class="flex gap-2 ml-2">
                                <BaseAvatar
                                    :src="account.avatar"
                                    :name="account.name"
                                    class="w-5 h-5 rounded-full"
                                />
                                {{ account.name }}
                            </div>
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
