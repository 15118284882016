<template>
    <Stepper :model-value="stepIndex" class="flex w-full items-start gap-2">
        <StepperItem
            v-for="step in steps"
            :key="step.step"
            v-slot="{ state }"
            class="relative flex w-full flex-col items-center justify-center"
            :step="step.step"
        >
            <StepperSeparator
                v-if="step.step !== steps[steps.length - 1].step"
                class="absolute left-[calc(50%+20px)] right-[calc(-50%+10px)] top-4 block h-0.5 shrink-0 rounded-full bg-gray-100 group-data-[state=completed]:bg-primary-500"
            />

            <StepperTrigger as-child>
                <Button
                    :variant="state === 'completed' || state === 'active' ? 'primary' : 'outline'"
                    buttonSize="icon"
                    class="z-10 !rounded-full shrink-0"
                    :class="[state === 'active' && 'ring-2 ring-ring ring-offset-2 ring-offset-background']"
                >
                    <Check v-if="state === 'completed'" class="size-5"/>
                    <Circle v-if="state === 'active'"/>
                    <Dot v-if="state === 'inactive'"/>
                </Button>
            </StepperTrigger>

            <div class="mt-5 flex flex-col items-center text-center">
                <StepperTitle
                    :class="[state === 'active' && 'text-primary']"
                    class="text-sm font-semibold transition lg:text-base"
                >
                    {{ step.title }}
                </StepperTitle>
                <StepperDescription
                    :class="[state === 'active' && 'text-primary']"
                    class="sr-only text-xs text-muted-foreground transition md:not-sr-only lg:text-sm"
                >
                    {{ step.description }}
                </StepperDescription>
            </div>
        </StepperItem>
    </Stepper>
</template>
<script setup>
import { Check, Circle, Dot } from 'lucide-vue-next'

import {
    Stepper,
    StepperDescription,
    StepperItem,
    StepperSeparator,
    StepperTitle,
    StepperTrigger
} from '@/Components/ui/stepper'
import Button from "@/tiptap/components/ui/button/Button.vue";
import { computed } from "vue";

const props = defineProps({
    steps: {
        type: Array,
        default: () => [],
    }
})

const activeStep = defineModel({
    default: 'account',
})


const stepIndex = computed(() => {
    const index = props.steps.findIndex(step => step.id === activeStep.value)
    return index + 1
})

</script>
