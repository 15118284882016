export const softwareAdaptionItemStatuses = [{
    name: 'Evaluation',
    color: '#4E87EE',
    value: 'Evaluation',
}, {
    name: 'Purchased',
    color: '#4ECB71',
    value: 'Purchased',
}, {
    name: 'Deployment in Progress',
    color: '#FFA500',
    value: 'Deployment in Progress',
}, {
    name: 'Active',
    color: '#4ECB71',
    value: 'Active',
}, {
    name: 'Partial Deployment',
    color: '#FF8C00',
    value: 'Partial Deployment',
}, {
    name: 'Review',
    color: '#9370DB',
    value: 'Review',
}, {
    name: 'Not Owned',
    color: '#F24E1E',
    value: 'Not Owned',
}, {
    name: 'Retired',
    color: '#111827',
    value: 'Retired',
}]
