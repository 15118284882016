<template>
  <div class="flex flex-col gap-2">
    <ColorPicker
        :is-widget="true"
        :pure-color="color"
        format="hex"
        picker-type="chrome"
        @pure-color-change="handleColorChange"
    >
      <template #extra>
        <div class="flex flex-wrap items-center gap-1">
          <ColorButton
              v-for="currentColor in themeColors"
              :key="currentColor"
              :active="currentColor === color"
              :color="currentColor"
              @change="handleColorChange"
          />
          <ToolbarButton @click="onClear" tooltip="Reset color to default">
            <Icon name="Undo"/>
          </ToolbarButton>
        </div>
      </template>
    </ColorPicker>
  </div>
</template>

<script setup>
import { ColorPicker } from "vue3-colorpicker";
import { ref } from 'vue';
import ColorButton from './ColorButton.vue';
import Icon from "@/tiptap/components/ui/Icon.vue";
import { themeColors } from "@/tiptap/lib/constants";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";
import "vue3-colorpicker/style.css";

const props = defineProps({
  color: String,
});

const colorInputValue = ref(props.color || '');
const emit = defineEmits(['change', 'clear']);

function onClear() {
  colorInputValue.value = ''
  emit('clear');
}
function handleColorChange(value) {
  const isCorrectColor = /^#([0-9A-F]{3}){1,2}$/i.test(value);
  colorInputValue.value = isCorrectColor ? value : '';

  if (!isCorrectColor) {
    emit('change', '');
    return;
  }
  emit('change', colorInputValue.value);
}
</script>
