<template>
  <DropdownMenuRoot>
    <DropdownMenuTrigger v-slot="{ attrs }">
      <ToolbarButton :active="activeItem?.id !== 'paragraph' && !!activeItem?.type">
        <Icon :name="(activeItem?.type === 'option' && activeItem.icon) || 'Pilcrow'"/>
        <Icon name="ChevronDown" className="w-2 h-2"/>
      </ToolbarButton>
    </DropdownMenuTrigger>
    <DropdownMenuContent v-slot="{ attrs }">
      <Surface class="flex flex-col gap-1 px-2 py-4">
        <template v-for="option in options" :key="option.id">
          <DropdownButton v-if="isOption(option)" @click="option.onClick" :isActive="option.isActive()">
            <Icon :name="option.icon" className="w-4 h-4 mr-1"/>
            {{ option.label }}
          </DropdownButton>
          <div v-if="isCategory(option)" class="mt-2 first:mt-0" :key="option.id">
            <DropdownCategoryTitle>
              {{ option.label }}
            </DropdownCategoryTitle>
          </div>
        </template>
      </Surface>
    </DropdownMenuContent>
  </DropdownMenuRoot>
</template>

<script setup>
import { DropdownMenuContent, DropdownMenuRoot, DropdownMenuTrigger } from "radix-vue";
import Surface from "@/tiptap/components/ui/Surface.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import DropdownButton from "@/tiptap/components/ui/dropdown/DropdownButton.vue";
import { computed } from "vue";
import DropdownCategoryTitle from "@/tiptap/components/ui/dropdown/DropdownCategoryTitle.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";

const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
});

const activeItem = computed(() => {
  return props.options.find(option => option.type === 'option' && option.isActive())
})

const isOption = (option) => option.type === 'option'
const isCategory = (option) => option.type === 'category'
</script>
