import {Node, mergeAttributes} from '@tiptap/core'
import {VueNodeViewRenderer} from '@tiptap/vue-3';
import FilePreviewNodeTemplate from './components/FilePreviewNodeTemplate.vue';

export const filePreviewSuggestion = Node.create({
    name: 'file-preview',

    group: 'block',

    addAttributes() {
        return {
            id: {
                default: null,
            },
            src: {
                default: null,
            },
            file_name: {
                default: null,
            },
            name: {
                default: null,
            },
            size: {
                default: null,
            },

            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            inline: {
                default: false,
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'file-preview'
        }]
    },

    renderHTML({HTMLAttributes}) {
        return ['file-preview', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(FilePreviewNodeTemplate);
    },

    addCommands() {
        return {
            setFilePreview: (options) => ({commands, tr, dispatch, chain}) => {
                const {selection} = tr

                const node = this.type.create(options)

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true;
            },

        }
    },
});

export default filePreviewSuggestion;
