<template>
  <BubbleMenu
      :editor="editor"
      pluginKey="tableRowMenu"
      :updateDelay="0"
      :tippyOptions="tippyOptions"
      :shouldShow="shouldShow"
  >
    <ToolbarWrapper isVertical>
      <PopoverItem
          icon="ArrowUpToLine"
          :close="false"
          label="Add row before"
          @click="onAddRowBefore"
      />
      <PopoverItem
          icon="ArrowDownToLine"
          :close="false"
          label="Add row after"
          @click="onAddRowAfter"
      />
      <PopoverItem
          icon="Trash"
          :close="false"
          label="Delete row"
          @click="onDeleteRow"
      />
    </ToolbarWrapper>
  </BubbleMenu>
</template>

<script setup>
import { BubbleMenu } from "@tiptap/vue-3";
import PopoverItem from "@/tiptap/components/ui/popover/PopoverItem.vue";
import ToolbarWrapper from "@/tiptap/components/ui/toolbar/ToolbarWrapper.vue";
import isRowGripSelected from "./utils";


// Props passed to this component
const props = defineProps({
  editor: Object,
  appendTo: Object // Assuming this is a ref object
});

const tippyOptions = {
  appendTo: () => {
    return props.appendTo
  },
  placement: 'left',
  offset: [0, 15],
  popperOptions: {
    modifiers: [{ name: 'flip', enabled: false }],
  },
};

const shouldShow = ({ view, state, from }) => {
  if (!state || !from) {
    return false
  }

  return isRowGripSelected({ editor: props.editor, view, state, from })
};

const onAddRowBefore = () => {
  props.editor.chain().focus().addRowBefore().run()
};

const onAddRowAfter = () => {
  props.editor.chain().focus().addRowAfter().run()
};

const onDeleteRow = () => {
  props.editor.chain().focus().deleteRow().run()
};
</script>
