import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import QuestionNodeTemplate from './QuestionNodeTemplate.vue';

export const questionSuggestion = Node.create({
    name: 'question',

    group: 'block',

    addAttributes() {
        return {
            id: {
                default: null,
            },

            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            inline: {
                default: false,
            },
            firstRender: {
                //default: false,
            },
        }
    },

    parseHTML() {
        return [{
            tag: 'software-adoption'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['software-adoption', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(QuestionNodeTemplate);
    },

    addCommands() {
        return {
            setQuestion: ({id, firstRender}) => ({ commands, tr, dispatch, chain }) => {
                const { selection } = tr

                const node = this.type.create({id, firstRender})

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true;
            },
        }
    },
});

export default questionSuggestion;
