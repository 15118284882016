<template>
    <div class="bg-white pb-10">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-4xl text-center">
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Pick a Plan that Works for Your Business Model
                </p>
            </div>
            <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600"></p>
            <Tabs :defaultValue="frequency.value" class="w-full justify-center flex mt-16"
                  @update:modelValue="e => frequency = frequencies.find(f => f.value === e)"
            >
                <TabsList>
                    <TabsTrigger v-for="option in frequencies" :value="option.value" >{{ option.label }}</TabsTrigger>
                </TabsList>
            </Tabs>
            <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div v-for="tier in tiers" :key="tier.id"
                     :class="[
                         tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200', 'rounded-3xl p-8 ring-1 xl:p-10',
                         isActive(
                             ['free', 'enterprise'].includes(tier.id) ? 'free' : frequency.value.value === 'monthly' ? 'monthly_pro' : 'yearly_pro'
                         ) ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200', 'rounded-3xl p-8 xl:p-10'
                     ]"

                >
                    <h3 :id="tier.id"
                        :class="[tier.featured ? 'text-white' : 'text-gray-900', 'text-lg font-semibold leading-8']">
                        {{ tier.name }}</h3>
                    <p :class="[tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6']">
                        {{ tier.description }}</p>
                    <p class="mt-6 flex items-baseline gap-x-1">
                        <span
                            :class="[tier.featured ? 'text-white' : 'text-gray-900', 'text-4xl font-bold tracking-tight']">{{
                                typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]
                            }}</span>
                        <span
                            v-if="typeof tier.price !== 'string'"
                            :class="[tier.featured ? 'text-gray-300' : 'text-gray-600', 'text-sm font-semibold leading-6']"
                        >
                            <template v-if="tier.id !== 'free'">
                                {{ frequency.priceSuffix }}/month
                            </template>
                        </span>
                    </p>
                    <a @click="(e) => {e.preventDefault();onPlanSelect(tier)}" :aria-describedby="tier.id"
                       class="cursor-pointer"
                       :class="[tier.featured ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white' : 'bg-primary-600 text-white shadow-sm hover:bg-primary-500 focus-visible:outline-primary-600', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">{{
                            tier.cta()
                        }}</a>
                    <ul role="list"
                        :class="[tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-8 space-y-3 text-sm leading-6 xl:mt-10']">
                        <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
                            <CircleCheckBig
                                :class="[tier.featured ? 'text-white' : 'text-green-600', 'h-6 w-5 flex-none']"
                                aria-hidden="true"/>
                            {{ feature }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { usePage } from '@inertiajs/vue3';
import { CircleCheckBig } from 'lucide-vue-next'
import { Tabs, TabsList, TabsTrigger } from '@/Components/ui/tabs'

const page = usePage();

const plans = page.props.plans;
const subscription = page.props.subscription;

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: `/user` },
    { value: 'annually', label: 'Annually', priceSuffix: '/user' },
];

const frequency = ref(
    subscription?.plan?.identifier === 'yearly_pro'
        ? frequencies[1]
        : frequencies[0]
)

const tiers = ref([
    {
        name: 'Free',
        id: 'free',
        href: '#',
        price: { monthly: 'Free', annually: 'Free' },
        description: 'Try roombriks products for free.',
        features: ['5 Rooms 10 Assets', 'Digital Collaboration Rooms', 'Digital Sales Rooms for onboarding', 'Client Portals', 'Project Managment', 'Content Managment', 'Integrations with Zoom, MS Office, Google Drive/Gsuite, OneDrive, Box, Sharepoint and more', 'Customer Support'],
        featured: false,
        cta: () => isActive('free') ? 'Manage plan' : 'Buy plan',
    },
    {
        name: 'Standard Business',
        id: 'monthly_pro',
        href: '#',
        price: { monthly: '$19', annually: '$14', priceSuffix: '/user' },
        description: 'For sales deals, client portals, customer onboarding and more. For teams to consolidate their tech stack.',
        features: [
            'Unlimted Rooms and Assets',
            'Digital Collaboration Rooms',
            'Digital Sales Rooms for onboarding',
            'Client Portals',
            'Project Managment',
            'Content Managment',
            'Integrations with Zoom, MS Office, Google Drive/Gsuite, OneDrive, Box, Sharepoint and more',
            'Custom branding',
            'Customer Support',
            'Training and Implementation Assistence',
        ],
        featured: false,
        cta: () => {
            return isActive(frequency.value.value === 'monthly' ? 'monthly_pro' : 'yearly_pro') ? 'Manage plan' : 'Buy plan';
        },
    }
])

async function onPlanSelect(tier) {
    if (tier.id === 'free') {
        return;
    }

    const selectedTier = getPlanIdentifier();

    const plan = plans.find(plan => plan.identifier === selectedTier);

    if (plan?.id === subscription?.plan.id) {
        window.location.href = page.props.billingPortalUrl;
        return;
    }

    const { data } = await axios.post(`/checkout/${plan.id}`, {
        successUrl: window.location.href,
        cancelUrl: window.location.href,
    })

    window.location.href = data.data.url;
}

function getPlanIdentifier() {
    return frequency.value.value === 'monthly' ? 'monthly_pro' : 'yearly_pro';
}

function isActive(tier) {
    const t = tier?.id || tier;

    if (!subscription?.plan && t === 'free') {
        return true;
    }

    if (t === 'free' && subscription?.plan?.identifier === 'free') {
        return true;
    }

    const selectedTier = ['monthly_pro', 'yearly_pro'].includes(tier) ? getPlanIdentifier() : tier.id;

    return selectedTier === subscription?.plan?.identifier;
}

</script>
