<template>
    <div class="flex self-start flex-row-reverse gap-2 group">
        <div class="flex flex-col">
            <p class="text-xs text-gray-500 mt-0.5 text-start mb-1">{{ creator }}</p>
            <div class="flex items-center self-start rounded-xl rounded-tl text-gray-600 break-words">
                <p>{{ props.comment.comment }}</p>
            </div>
            <p class="text-xs text-gray-500 mt-0.5 opacity-0 group-hover:opacity-100 text-left">{{ createdAt }}</p>
        </div>
        <BaseAvatar
            :src="props.comment.creator?.avatar"
            :name="creator"
            class="w-8 h-8 rounded-full border mt-1"
        />
    </div>
</template>
<script setup>
import BaseAvatar from '../forms/BaseAvatar.vue';

const props = defineProps({
    comment: {
        type: Object,
        required: true,
    },
});

function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert 24-hour time to 12-hour format
    const twelveHourFormatHours = hours % 12 || 12;

    return `${year}/${month}/${day} ${twelveHourFormatHours}:${minutes}:${seconds} ${ampm}`;
}

const createdAt = formatDateTime(new Date(props.comment.created_at), 'yyyy/MM/dd hh:mm:ss a');
console.log('Prop Comment, props.comment', props.comment)
const creator = props.comment.creator?.name || props.comment.email;
</script>
