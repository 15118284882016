import {Image as BaseImage} from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import ResizableNodeTemplate from '../../../Components/ResizableNodeTemplate.vue';

export const Image = BaseImage.extend({
    name: 'resizableImage',

    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width
                    };
                }
            },
            height: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height
                    };
                }
            },
            mimeType: {
                default: 'auto',
            },
            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            uuid: {
                default: '',
            },
            file_name: {
                default: '',
            },
            src: {
                default: '',
            },
            media: {
                default: null,
            }
        };
    },

    addCommands() {
        return {
            ...this.parent?.(),
        };
    },

    addNodeView() {
        return VueNodeViewRenderer(ResizableNodeTemplate);
    }
});

export default BaseImage
