<script setup>
import { cn } from "@/tiptap/lib/utils";

const props = defineProps({
  class: { type: null, required: false },
});
</script>

<template>
  <th
    :class="
      cn(
        'h-12 px-4 text-left align-middle font-medium text-gray-400 [&:has([role=checkbox])]:pr-0 dark:text-gray-400',
        props.class,
      )
    "
  >
    <slot />
  </th>
</template>
