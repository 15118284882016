<template>
    <FloatingMenu
        :editor="editor"
        :pluginKey="pluginKey"
        :updateDelay="0"
        :tippyOptions="tippyOptions"
    >
        <ToolbarWrapper :should-show-content="showContent"
                        class="!border-transparent !shadow-none"
        >
            <ToolbarButton
                tooltip="Columns"
                button-size="iconSmall"
                @click="onInsertColumns(editor)"
            >
                <Icon name="Columns2" />
            </ToolbarButton>
            <ToolbarButton
                tooltip="Bullet List"
                button-size="iconSmall"
                @click="onInsertBulletList(editor)"
            >
                <Icon name="List"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Table"
                button-size="iconSmall"
                @click="onInsertTable(editor)"
            >
                <Icon name="Table"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Image"
                button-size="iconSmall"
                @click="onImageClick"
            >
                <Icon name="Image"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Task List"
                button-size="iconSmall"
                @click="onInsertTaskList(editor)"
            >
                <Icon name="ListTodo"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Embed"
                button-size="iconSmall"
                @click="onEmbedClick"
            >
                <Icon name="ExternalLink"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Project Plan"
                button-size="iconSmall"
                @click="onInsertProjectPlan(editor)"
            >
                <Icon name="SquareKanban"/>
            </ToolbarButton>
            <ToolbarButton
                tooltip="Add"
                button-size="iconSmall"
                @click="openSlashCommand(editor)"
            >
                <Icon name="ChevronRight"/>
            </ToolbarButton>
        </ToolbarWrapper>
    </FloatingMenu>
</template>
<script setup>
import {computed} from 'vue'
import {FloatingMenu} from "@tiptap/vue-3";
import ToolbarWrapper from "@/tiptap/components/ui/toolbar/ToolbarWrapper.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";
import {onInsertBulletList, onInsertTable, onInsertTaskList} from "@/tiptap/extensions/SlashCommand/commands";
import {useTipTapStore} from "../../../stores/useTipTapStore.js";
import { onInsertProjectPlan, openSlashCommand, onInsertColumns } from '../../../extensions/SlashCommand/commands';

const props = defineProps({
    editor: Object,
    appendTo: Object
})
const pluginKey = computed(() => {
    return `placeholderMenu-${crypto.randomUUID()}`
})

const tippyOptions = {
    duration: 100,
    offset: [0, 355],
}

const tipTapStore = useTipTapStore()

function onEmbedClick() {
    tipTapStore.toggleShowEmbedDialog(true);
}

function onImageClick() {
    tipTapStore.toggleShowUploadDialog(true)
}

const showContent = computed(() => {
    return !tipTapStore.showUploadDialog && !tipTapStore.showEmbedDialog
})

</script>
