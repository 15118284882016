<template>
    <div class="flex items-center space-x-2">
        <BaseAvatar
            :src="value.avatar"
            :name="value.name"
            class="w-6 h-6 rounded-full"
        />
        <div>{{ value.name }}</div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { GlobeLock } from 'lucide-vue-next';
import BaseAvatar from '@/Components/forms/BaseAvatar.vue';

const props = defineProps({
    value: {
        type: String
    }
})

const shareOptions = ref([
    {
        value: 'hidden',
        name: 'Hidden',
        description: 'Not visible to anyone',
        icon: 'pi pi-eye-slash',
        iconColor: 'bg-red-200'
    },
    {
        value: 'anyone',
        name: 'Anyone with the link',
        description: 'Email gate, no verification',
        icon: 'pi pi-link',
        iconColor: 'bg-purple-400'
    },
    {
        value: 'restricted',
        name: 'Restricted Email',
        description: 'Only people with access can view the room',
        iconColor: 'bg-cyan-400',
        svgIcon: GlobeLock,
    },
    {
        value: 'public',
        name: 'Public',
        description: 'No email required to view the room. Comments and message removed',
        icon: 'pi-globe',
        iconColor: 'bg-green-400'
    },
]);

function getShareOption(value) {
    return shareOptions.value.find(option => option.value === value);
}
</script>
