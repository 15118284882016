<template>
    <AuthLayout>
        <h1 class="text-center">
            Accept invitation
        </h1>
        <BaseFormCard @submit="submit" :form="form" class="w-full mt-16">
            <FormField v-slot="{ componentField }" name="email">
                <FormItem v-auto-animate>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                        <Input type="email" name="email" disabled v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="first_name">
                <FormItem v-auto-animate>
                    <FormLabel>First name</FormLabel>
                    <FormControl>
                        <Input name="email" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="last_name">
                <FormItem v-auto-animate>
                    <FormLabel>Last name</FormLabel>
                    <FormControl>
                        <Input name="email" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="password">
                <FormItem v-auto-animate>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                        <Input type="password" name="email" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="password_confirmation">
                <FormItem v-auto-animate>
                    <FormLabel>Password confirmation</FormLabel>
                    <FormControl>
                        <Input type="password" name="email" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <template #footer>
                <div class="mt-10">
                    <Button class="w-full" size="small" type="submit" :loading="form.processing">
                        Register
                    </Button>
                </div>
            </template>
        </BaseFormCard>
    </AuthLayout>
</template>
<script setup>
import { usePage } from '@inertiajs/vue3';
import AuthLayout from '../../Layouts/AuthLayout.vue';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import useValidatedForm from '../../hooks/useValidatedFormNew';
import * as yup from 'yup';
import { useToast } from 'primevue/usetoast';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import Button from '@/tiptap/components/ui/button/Button.vue';
import { Input } from '@/Components/ui/input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Form} from '@/Components/ui/form'
import { InputWithIcon } from '@/Components/ui/input'
const toast = useToast();
const page = usePage();
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const schema = yup.object().shape({
    first_name: yup.string().required('Please enter your first name.'),
    last_name: yup.string().required('Please enter your last name.'),
    password: yup.string()
        .required()
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Password must contain at least one symbol')
        .test('no-spaces', 'Password must not contain spaces', value => !/\s/.test(value)),
    password_confirmation: yup.string()
        .required('Please retype your password.')
        .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

const form = useValidatedForm({
    email: page.props.email,
    first_name: '',
    last_name: '',
    password: '',
    password_confirmation: '',
}, schema);

const submit = () => {
    form
        .transform((data) => {
            return {
                ...data,
                token
            }
        })
        .post('/invitations/accept', {
            onSuccess: (data) => {
                window.location.href = data.props.flash.redirectUrl;
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
};
</script>
