import { defineStore } from 'pinia'

export const useTipTapStore = defineStore('alerts', {
    state: () => ({
        showEmbedDialog: false,
        showUploadDialog: false,
        uploadDialogTab: 'upload',
        currentRoomUlid: null,
        content: null,
        saving: false,
        dirty: false,
    }),
    actions: {
        toggleShowEmbedDialog(value) {
            this.showEmbedDialog = value
        },
        toggleShowUploadDialog(value) {
            this.showUploadDialog = value
        },
        setUploadDialogTab(value) {
            this.uploadDialogTab = value
        },
        setCurrentRoom(roomUlid) {
            this.currentRoomUlid = roomUlid;
        },
        setSaving(value) {
            this.saving = value;
        },
        setDirty(value) {
            this.dirty = value;
        }
    }
})
