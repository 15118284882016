<template>
    <div class="flex flex-grow overflow-y-auto w-full" ref="scrollContainer" style="max-height: 65vh;">
        <div class="flex flex-col space-y-2 p-4 mt-auto w-full">
            <template v-for="comment in comments">
                <AutomaticallyMessage v-if="comment.meta?.automatically" :comment="comment"/>
                <MyMessage v-else-if="comment.email === user.email" :comment="comment"/>
                <SenderMessage v-else :comment="comment"/>
            </template>
        </div>
    </div>
    <div class="flex items-center p-4 pt-0 gap-2 w-full">
        <form @submit.prevent="sendMessage" class="flex items-center w-full gap-2">
            <FormField name="name">
                <FormItem v-auto-animate class="w-full">
                    <FormControl>
                        <div class="flex">
                            <Input
                                placeholder="Insert your message"
                                v-model="form.input"
                                class="rounded-r-none border-r-0"
                            />
                            <Button
                                class="rounded-l-none !border-l-0"
                                variant="secondary"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </div>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
        </form>
    </div>
</template>
<script setup lang="ts">
import MyMessage from "../../../Components/comments/MyMessage.vue";
import Button from '../../../tiptap/components/ui/button/Button.vue';
import { FormControl, FormField, FormItem, FormMessage, } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import SenderMessage from "../../../Components/comments/SenderMessage.vue";
import InputText from "../../../Components/forms/InputText.vue";
import { useQuestionComments } from '../../../hooks/useQuestionComments.js';
import { ref } from "vue";
import { useAuth } from "../../../hooks/useAuth";
import AutomaticallyMessage from "../../../Components/comments/AutomaticallyMessage.vue";

const props = defineProps({
    question: Object,
    editable: Boolean
});

const scrollContainer = ref(null);
const {user, canAddQuestionComments} = useAuth();
const { sendMessage, form, comments } = useQuestionComments(scrollContainer, props.question, props.editable);
</script>
