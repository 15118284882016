import { router, useForm, usePage } from '@inertiajs/vue3';
import { useAuth } from './useAuth.js';
import { nextTick, ref, watch, watchEffect } from 'vue';

export function useQuestionComments(scrollContainer, currentQuestion, editable) {
    const url = 'question-comments';
    const event = 'question.comment.created';
    const {user, canAddQuestionComments} = useAuth()

    const comments = ref(currentQuestion?.comments || []);
    const form = useForm({
        input: '',
    });

    watch(comments.value, () => {
        scrollToBottom()
    });

    watchEffect(() => {
        scrollToBottom()
    });

    window.pusher.subscribe(`question.${currentQuestion.id}`)
        .bind(event, ({comment}) => {
            console.log('Comment, comment', comment)
            if (comment.email !== user.email || comment.meta?.automatically) {
                
                comments.value.push(comment);
            }
        });

    function scrollToBottom() {
        nextTick(() => {
            if (scrollContainer.value) {
                // Scroll to the bottom of the container
                const {scrollHeight, clientHeight} = scrollContainer.value;
                scrollContainer.value.scrollTop = scrollHeight - clientHeight;
            }
        });
    }

    function sendMessage() {
        if (!form.input || !canAddQuestionComments()) {
            return;
        }

        const value = form.input;

        comments.value.push({
            comment: value,
            creator: {
                id: user.id,
                name: user.name,
                avatar: user.avatar,
                email: user.email,
            },
            email: user.email,
            created_at: new Date().toISOString(),
        });

        form.reset();

        console.log('`${url}/${currentQuestion.id}`', `${url}/${currentQuestion.id}`);

        axios.post(`/${url}/${currentQuestion.id}`, {input: value})
            .then((response) => {

            })
            .catch((error) => {
                console.error(error);
            });
    }

    return {
        sendMessage,
        form,
        comments,
    }
}
