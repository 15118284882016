<template>
    <Dialog
        v-if="showDialog"
        v-model:visible="showDialog"
        modal
        :header="`Welcome to Roombriks`"
        :style="{ width: '64rem' }"
        :dismissable-mask="true"
    >
        <div class="flex flex-col justify-center items-center my-4">
            <h2 class="text-3xl font-bold text-primary">Thank you, {{ user.name }}!</h2>
            <div class="mt-4 mb-4 flex flex-row gap-4 items-center">
                <div>
                    <img src="/images/chris.jpg" alt="Founder Image" class="rounded-full w-32" />
                </div>
                <div class="">
                    <p class="text-xl font-semibold">We hope you like Roombriks! We're super excited to have you.</p>
                    <p class="mt-4">If you need anything, don't hesitate to reach out: <a href="mailto:chris@roombriks.com" class="text-blue-500 underline">chris@roombriks.com</a></p>
                </div>
            </div>
            <!--ARCADE EMBED START--><div style="position: relative; padding-bottom: calc(56.25% + 41px); height: 0; width: 100%;"><iframe src="https://demo.arcade.software/D5ENTVmZVDMSkICM2NSd?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true" title="roombriks overview " frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="clipboard-write" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; color-scheme: light;" ></iframe></div><!--ARCADE EMBED END-->
            <h3 class="text-2xl font-bold mt-4">Remember to Review Us</h3>
            <p class="text-lg mt-2">
                We live & breathe your feedback and reviews! Please let us know what you think.
            </p>

            <a href="" class="mt-4 bg-primary underline text-blue-500">Review on AppSumo</a>

            <Button class="mt-8 px-8" @click="showDialog = false">Close</Button>
        </div>
    </Dialog>
</template>
<script setup lang="ts">
import Button from "@/tiptap/components/ui/button/Button.vue";
import { useAuth } from "@/hooks/useAuth";
import { ref } from "vue";

const { user } = useAuth();
const queryString = window.location.search;

const params = new URLSearchParams(queryString);

const paramValue = params.get('showAppSumoModal'); // replace 'yourParam' with the query parameter you're looking for

const showDialog = ref(paramValue === 'true');
</script>
