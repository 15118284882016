<template>
    <div id="room-view">
        <div class="relative mx-auto mb-10">
            <RoomEditorTopNav/>

            <div class="max-w-5xl mx-auto mt-4 gap-4 flex flex-col">
                <RoomDetailsCard :room="room"/>

                <RoomCard title="Visitors">

                    <VisitorsTable />
                </RoomCard>

                <div class="grid grid-cols-3 gap-4">
                    <RoomCard
                        title="Plan status"
                        description="Total Plan Status For All Plans"
                    >
                        <div class="flex justify-center my-4">
                            <div class="flex-col gap-2 justify-center items-center text-center">
                                <div class="flex justify-center my-2">
                                    <circle-progress
                                        :size="80"
                                        :border-width="8"
                                        :border-bg-width="8"
                                        :percent="completedPlanItems.length / planItems.length * 100"
                                    />
                                </div>
                                <p class="text-gray-700 text-sm">{{ completedPlanItems.length }} of {{ planItems.length }} completed</p>
                            </div>
                        </div>
                    </RoomCard>
                    <RoomCard
                        title="Software adoption"
                        description="Total Software Adoption"
                    >
                        <div class="flex justify-center my-4">
                            <div class="flex-col gap-2 justify-center items-center text-center">
                                <div class="flex justify-center my-2">
                                    <circle-progress
                                        :size="80"
                                        :fill-color="'green'"
                                        :border-width="8"
                                        :border-bg-width="8"
                                        :percent="totalConsumption / totalAmountOwned * 100"
                                    />
                                </div>
                                <p class="text-gray-700 text-sm">{{ totalConsumption }} of {{ totalAmountOwned }} Consumed</p>
                            </div>
                        </div>
                    </RoomCard>
                    <RoomCard
                        title="Views"
                        description="Total views of the Room"
                    >
                        <div class="text-6xl flex w-full h-full justify-center items-center">
                            {{ room.views.length }}
                        </div>
                    </RoomCard>
                </div>

                <RoomCard title="Total Visits Over Time">
                    <VisitorsChart :room="room"/>
                </RoomCard>

                <RoomCard title="Comments">
                    <div class="flex flex-grow overflow-y-auto border-0" ref="scrollContainer" style="max-height: 400px;">
                        <div class="flex flex-col space-y-2 p-4 mt-auto w-full">
                            <template v-for="comment in comments">
                                <MyMessage v-if="comment.email === user.email" :comment="comment"/>
                                <SenderMessage v-else :comment="comment"/>
                            </template>
                        </div>
                    </div>
                    <div class="flex items-center p-4 pt-0 gap-2 ">
                        <form @submit.prevent="sendMessage" class="flex items-end w-full">
                            <FormField name="name">
                                <FormItem v-auto-animate class="w-full">
                                    <FormControl>
                                        <div class="flex">
                                            <Input
                                                placeholder="Insert your message"
                                                v-model="form.input"
                                                class="rounded-r-none border-r-0"
                                            />
                                            <Button
                                                class="rounded-l-none !border-l-0"
                                                variant="secondary"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            </FormField>
                        </form>
                    </div>
                </RoomCard>
            </div>
        </div>
    </div>
</template>
<script setup>
import { FormControl, FormField, FormItem, FormMessage, } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import { usePage } from '@inertiajs/vue3';
import { usePusher } from '../../hooks/usePusher.js';
import { computed, ref } from 'vue';
import { useAuth } from '../../hooks/useAuth.js';
import RoomEditorTopNav from './components/RoomEditorTopNav.vue';
import { flatten } from 'lodash-es';
import MyMessage from '@/Components/comments/MyMessage.vue';
import SenderMessage from '@/Components/comments/SenderMessage.vue';
import { useRoomComments } from '../../hooks/useRoomComments.js';
import 'vue3-circle-progress/dist/circle-progress.css';
import CircleProgress from 'vue3-circle-progress';
import RoomDetailsCard from './components/RoomDetailsCard.vue';
import RoomCard from './components/RoomCard.vue';
import VisitorsChart from './components/VisitorsChart.vue';
import Button from '../../tiptap/components/ui/button/Button.vue';
import VisitorsTable from '@/pages/RoomDetail/VisitorsTable.vue';

usePusher();
const page = usePage();
const room = ref(page.props.room);
const scrollContainer = ref(null);
const {user} = useAuth();
const {sendMessage, form, comments} = useRoomComments(scrollContainer);

const planItems = computed(() => {
    const acc = [];
    room.value.plans.map((plan) => {
        return acc.push(plan.items);
    });

    return flatten(acc)
})

const completedPlanItems = computed(() => {
    return planItems.value.filter((item) => item.completed_at !== null)
})

const totalConsumption = computed(() => {
    return room.value.software_adoptions.reduce((acc, item) => acc + item.total_comsumption, 0);
})

const totalAmountOwned = computed(() => {
    return room.value.software_adoptions.reduce((acc, item) => acc + item.total_amount_owned, 0);
});

const views = computed(() => {
    const groupedObject = groupBy(room.value.views, 'email');
    return Object.entries(groupedObject).map(([email, views]) => ({email, views}));
})

function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
}
</script>
