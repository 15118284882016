<template>
    <node-view-wrapper
        as="div"
        class="resizable-node"
        :class="{
            'readonly': !editor?.isEditable,
            '!max-w-full': node.attrs.fullWidth,
            '!mx-24': node.attrs.fullWidth && editor.isEditable,
        }"
    >
        <Toast/>
        <RoomCard class="relative group">
            <template #header v-if="currentContact">
                <BaseAvatar
                    :src="currentContact.avatar"
                    :name="currentContact.name"
                    class="h-16 w-16 p-2 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                />
                <div>
                    <RoomCardTitle>{{ currentContact.name }}</RoomCardTitle>
                    <RoomCardDescription v-if="currentContact.description">{{ currentContact.description }}
                    </RoomCardDescription>
                </div>
            </template>
            <div class="group" v-if="!loading && !error">
                <div v-if="currentContact.name">
                    <RoomCardRow v-if="currentContact.email !== null" label="Email">
                        <template #label>
                            <div class="flex items-center space-x-2">
                                <Icon name="Mail" class="text-primary-500 text-2xl" :size="24"/>
                                <span>Email</span>
                            </div>
                        </template>

                        <a
                            :href="`mailto:${currentContact.email}`"
                            class="truncate"
                            :title="currentContact.email"
                        >
                            {{ currentContact.email }}
                        </a>
                    </RoomCardRow>
                    <RoomCardRow v-if="currentContact.phone !== null" label="Phone">
                        <template #label>
                            <div class="flex items-center space-x-2">
                                <Icon name="Phone" class="text-primary-500 text-2xl" :size="24"/>
                                <span>Phone</span>
                            </div>
                        </template>

                        <div class="truncate" v-tooltip="currentContact.phone">
                            <a :href="`tel:${currentContact.phone}`">
                                {{ currentContact.phone }}
                            </a>
                        </div>
                    </RoomCardRow>
                    <RoomCardRow v-if="currentContact.position !== null" label="Email">
                        <template #label>
                            <div class="flex items-center space-x-2">
                                <Icon name="CircleUserRound" class="text-primary-500 text-2xl" :size="24"/>
                                <span>Position</span>
                            </div>
                        </template>

                        {{ currentContact.position }}
                    </RoomCardRow>
                </div>
                <div v-else
                     class="min-h-20 flex items-center justify-center cursor-pointer"
                     @click="() => {
                        if (editable) {
                            showDialog = true
                        }
                     }"
                >
                    <i class="pi pi-plus-circle text-2xl"></i>
                </div>

                <div class="card-buttons absolute top-[5px] right-[5px] opacity-0 group-hover:opacity-100">
                    <div class="flex">
                        <Button
                            variant="ghost"
                            @click="deleteNode"
                        >
                            <Icon name="Trash2"/>
                        </Button>
                        <Button
                            variant="ghost"
                            @click="openDialog"
                        >
                            <Icon name="Pencil"/>
                        </Button>
                    </div>
                </div>
                <Dialog v-model:visible="showDialog" modal header="Edit contact card" :style="{ width: '48rem' }">
                    <BaseFormCard @submit="onSubmit" :loading="form.processing" :form="form">
                        <div class="flex gap-2 mt-2">
                            <AvatarUpload
                                :canCrop="false"
                                :name="form.avatar"
                                v-model="form.avatar"
                                @update:modelValue="(e) => {
                                    form.vee.setFieldValue('avatar', e);
                                }"
                                :show-button="false"
                                :show-help="false"
                                class="p-0 h-[150px] rounded-md !object-contain"
                                :description="`Upload PNG, JPG, 50x50 or larger recommended`"
                            />
                        </div>
                        <div class="flex align-items-center gap-3 mb-3">
                            <FormField v-slot="{ componentField }" name="name">
                                <FormItem v-auto-animate class="w-full">
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input v-bind="componentField" class="w-full"/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            </FormField>

                        </div>
                        <div class="flex flex-col gap-3 mb-3">
                            <FormField v-slot="{ componentField }" name="position">
                                <FormItem v-auto-animate>
                                    <FormLabel>Position</FormLabel>
                                    <FormControl>
                                        <Input v-bind="componentField"/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            </FormField>
                        </div>
                        <div class="flex flex-col gap-3 mb-3">
                            <FormField v-slot="{ componentField }" name="description">
                                <FormItem v-auto-animate class="col-span-6">
                                    <FormControl>
                                        <FormLabel>Description</FormLabel>
                                        <Textarea v-bind="componentField" class="w-full"/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            </FormField>
                        </div>
                        <div class="flex flex-col gap-3 mb-3">
                            <FormField v-slot="{ componentField }" name="email">
                                <FormItem v-auto-animate>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input v-bind="componentField" type="email"/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            </FormField>
                        </div>
                        <div class="flex flex-col gap-3 mb-3">
                            <FormField v-slot="{ componentField }" name="phone">
                                <FormItem v-auto-animate>
                                    <FormLabel>Phone</FormLabel>
                                    <FormControl>
                                        <Input v-bind="componentField" />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            </FormField>
                        </div>

                        <template #footer>
                            <div class="flex justify-center gap-2 mt-4">
                                <Button class="w-full mt-4" size="small" variant="secondary" @click="onClose">
                                    Cancel
                                </Button>
                                <Button
                                    class="w-full mt-4" size="small" type="submit"
                                    :loading="form.processing"
                                >
                                    Save
                                </Button>
                            </div>
                        </template>
                    </BaseFormCard>
                </Dialog>
            </div>
        </RoomCard>
        <CardError v-if="!loading && error"/>
    </node-view-wrapper>
</template>

<script setup>
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import {NodeViewWrapper} from '@tiptap/vue-3';
import axios from "axios";
import {useRoomPage} from '../../../../pages/RoomDetail/hooks/useRoomPage.js';
import {displayErrorMessagesAsToast} from '../../../../utils/errorUtils.js';
import Textarea from 'primevue/textarea';
import BaseFormCard from "@/Components/forms/BaseFormCard.vue";
import useValidatedForm from '../../../../hooks/useValidatedFormNew';
import * as yup from 'yup';
import CardError from '../../../components/ui/CardError.vue';
import Icon from "@/tiptap/components/ui/Icon.vue";
import AvatarUpload from '../../../../Components/forms/AvatarUpload.vue';
import BaseAvatar from '../../../../Components/forms/BaseAvatar.vue';
import RoomCard from "../../../../pages/RoomDetail/components/RoomCard.vue";
import RoomCardTitle from "../../../../pages/RoomDetail/components/RoomCardTitle.vue";
import RoomCardDescription from "../../../../pages/RoomDetail/components/RoomCardDescription.vue";
import RoomCardRow from "../../../../pages/RoomDetail/components/RoomCardRow.vue";
import Button from "../../../components/ui/button/Button.vue";
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Form} from '@/Components/ui/form'
import { Input } from "@/Components/ui/input";


const showDialog = ref(false);
const error = ref(false);
const props = defineProps({
    editor: Object,
    node: Object,
    editable: Boolean,
    form: Object,
    onSubmit: Function,
    updateAttributes: {
        type: Function,
        required: true,
    },
})
function deleteNode() {
    const { editor, getPos, node } = props;

    const from = getPos()
    const to = from + node.nodeSize

    editor.commands.deleteRange({ from, to })
}

const {
    editable,
} = useRoomPage();
const currentContact = ref(null);
const loading = ref(true);

const handleEditNode = (event) => {
    const {type, id} = event.detail;

    if (props.node.attrs.id === id && props.node.type.name === type) {
        openDialog();
    }
};

onMounted(() => {
    window.addEventListener('edit-node', handleEditNode);
})

onUnmounted(() => {
    window.removeEventListener('edit-node', handleEditNode);
})

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Email is not valid').notRequired(),
});

const form = useValidatedForm({
    avatar: undefined,
    name: undefined,
    position: undefined,
    description: undefined,
    email: undefined,
    phone: undefined,
}, schema);

onMounted(() => {
    getContact(props.node.attrs.id)
});

function onClose() {
    showDialog.value = false;
    form.avatar = currentContact.value.avatar
    form.name = currentContact.value.name
    form.position = currentContact.value.position
    form.description = currentContact.value.description
    form.email = currentContact.value.email
    form.phone = currentContact.value.phone
}


watch(() => showDialog.value, (value) => {
    if (value) {
        getContact(props.node.attrs.id)
    }
})
async function getContact(id) {
    try {
        const {data} = await axios.get('/contacts/' + id)
        currentContact.value = data
        form.vee.setValues(data);
    } catch (e) {
        error.value = true;
        console.error(e)
    } finally {
        loading.value = false
    }
}

function openDialog() {
    if (!editable) {
        return;
    }

    showDialog.value = true;
}

async function onSubmit() {
    return new Promise((resolve, reject) => {
        if (!editable) {
            resolve();
            return;
        }

        const formData = form.data();

        const transformedData = {
            ...formData,
            _method: 'put',
            avatar: formData.avatar instanceof File ? formData.avatar : undefined,
        };

        const requestData = new FormData();
        Object.keys(transformedData).forEach(key => {
            requestData.append(key, transformedData[key]);
        });

        axios({
            method: 'post',
            url: `/contacts/${currentContact.value.id}`,
            data: transformedData,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(response => {
            const contactData = response.data.contact || {};
            currentContact.value = {
                ...currentContact.value,
                ...contactData
            };
            props.updateAttributes({
                id: currentContact.value.id,
                data: JSON.stringify(currentContact.value)
            });
            showDialog.value = false;
        }).catch(error => {
            showDialog.value = false;
            displayErrorMessagesAsToast(error);
        });
    })
}

</script>
<style lang="scss">
</style>
