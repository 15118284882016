<template>
    <AuthLayout>
        <h1 class="text-center">
            Reset Your Password
        </h1>
        <div class="flex justify-center space-x-2 mt-2">
            <p>Changed your mind?</p>
            <a :href="`${page.props.baseUrl}/login`">Sign In</a>
        </div>
        <form class="w-full mt-16" @submit.prevent="submit">
            <FormField v-slot="{ componentField }" name="password">
                <FormItem v-auto-animate>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                        <InputWithIcon type="password" @input="e => form.password = e.target.value"name="password" :icon="LockIcon" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="password_confirmation">
                <FormItem v-auto-animate>
                    <FormLabel>Confirm password</FormLabel>
                    <FormControl>
                        <InputWithIcon type="password" @input="e => form.password_confirmation = e.target.value" :icon="LockIcon" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <div class="mt-10">
                <Button class="w-full" size="small" type="submit" :loading="form.processing">
                    Reset Password
                </Button>
            </div>
        </form>
    </AuthLayout>
</template>
<script setup>
import { useForm, usePage } from '@inertiajs/vue3';
import AuthLayout from '../../Layouts/AuthLayout.vue';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import { LockIcon } from 'lucide-vue-next';
import Button from '@/tiptap/components/ui/button/Button.vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { InputWithIcon } from '@/Components/ui/input'

const urlParams = new URLSearchParams(window.location.search);
const email = urlParams.get('email');
import { useToast } from 'primevue/usetoast';
const toast = useToast();

const form = useForm({
    email: email,
    password: '',
    password_confirmation: '',
});

const page = usePage();
const submit = () => {
    form
        .transform((data) => {
            return {
                ...data,
                token: page.props.request.token,
                email: page.props.request.email,
            };
        })
        .post('/reset-password', {
            onFinish: () => form.reset(),
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
};
</script>
