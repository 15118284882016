<template>
    <AuthenticatedLayout>
        <template #title>Edit member</template>
        <BaseFormCard
            :loading="form.processing"
            :save-text="'Save'"
            :form="form"
            @submit="submit">
            <FormCardSection title="Member details">
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="first_name">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormLabel>First name</FormLabel>
                            <FormControl>
                                <Input v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="last_name">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormLabel>Last name</FormLabel>
                            <FormControl>
                                <Input v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="email">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input v-bind="componentField" :disabled="true"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="role_id">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormLabel>Role</FormLabel>
                            <FormControl>
                                <Select v-bind="componentField">
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select the role"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem v-for="role in roleOptions" :value="role.id">
                                                {{ role.name }}
                                            </SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
            </FormCardSection>
        </BaseFormCard>
    </AuthenticatedLayout>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import * as yup from 'yup';
import { router, usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'
import FormCardSection from '@/Components/forms/FormCardSection.vue';
import { capitalize } from 'lodash-es';
import { computed } from 'vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { Input } from '@/Components/ui/input'
import useValidatedForm from '@/hooks/useValidatedFormNew';
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const page = usePage();
const user = page.props.user;

const roleOptions = computed(() => {
    const roles = page.props.roles || []
    return roles.map(r => {
        return {
            ...r,
            name: capitalize(r.name)
        }
    })
})

const schema = yup.object().shape({
    last_name: yup.string().required('Last name is required'),
    first_name: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    role_id: yup.number().required(),
});

const form = useValidatedForm({
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    role_id: user.roles[0].id,
}, schema);

const submit = () => {
    form.put(`/users/${user.id}`, {
        onSuccess: () => {
            router.visit('/invitations')
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
};
</script>
