import Pusher from 'pusher-js';

export function usePusher() {
    window.Pusher = Pusher;

    window.pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER
    });
}

