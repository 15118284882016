<script setup lang="ts">
import { ref } from 'vue'
// @ts-ignore
import VueApexCharts from 'vue3-apexcharts'
import { PlayIcon } from "lucide-vue-next";

const chartData = {
    series: [
        {
            name: 'Avg time spent over time',
            data: [9, 14, 22, 17, 53, 42, 27, 12]
        },
    ],
    labels: ['12pm', '3pm', '6pm','9pm','12am','3am','6am','9am'],
}

const chart = ref(null)

const apexOptions = {
    series: [{
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
    }],
    chart: {
        height: 350,
        type: 'line',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth'
    },
    subtitle: {
        text: '',
        align: 'left',
        offsetY: 0,
        style: {
            fontSize: '18px',
        }
    },
    title: {
        text: '',
        align: 'left',
        offsetY: 30,
        style: {
            fontSize: '24px'
        }
    },
    fill: {
        type: 'solid',
        colors: ['transparent', 'transparent', 'transparent']
    },
    colors: ['#f97316'],
    grid: {
        show: false,
        row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        categories: ['12pm', '3pm', '6pm','9pm','12am','3am','6am','9am'],
    },
}
</script>

<template>
    <div
        class="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-4"
    >
        <div>
            <div class="flex items-left flex-row w-full mb-4">
                <div class="flex items-left flex-col w-full">
                    <h4 class="text-title-md font-bold text-black dark:text-white">$34,657</h4>
                    <p class="text-sm font-medium text-gray-450 mt-2">Avg Time Spent Over Time</p>
                </div>

                <div class="text-lg p-2 bg-orange-500 text-white rounded-full flex items-center justify-center">
                    -14%
                </div>
            </div>
            <div id="chartOne" class="-ml-5">
                <VueApexCharts
                    type="area"
                    height="350"
                    :options="apexOptions"
                    :series="chartData.series"
                    ref="chart"
                />
            </div>
            <button class="border-t border-gray-400 pt-3 mt-3.5 text-sm w-full justify-between flex items-center">
                <span>
                    View All Members
                </span>
                <div class="p-1.5 bg-orange-500 rounded-full">
                    <PlayIcon class="w-4 h-4 text-white" />
                </div>
            </button>
        </div>
    </div>
</template>
