<template>
  <Surface class="p-2">
      <form @submit.prevent="handleSubmit" class="flex items-end w-full">
          <FormField name="name">
              <FormItem v-auto-animate class="w-full">
                  <FormControl>
                      <div class="flex">
                          <Input
                              placeholder="Insert your message"
                              v-model="url"
                              class="rounded-r-none border-r-0"
                          />
                          <Button
                              class="rounded-l-none !border-l-0"
                              variant="secondary"
                              type="submit"
                          >
                              Set link
                          </Button>
                      </div>
                  </FormControl>
                  <FormMessage/>
              </FormItem>
          </FormField>
      </form>
    <div class="mt-3">
      <label
          class="flex items-center justify-start gap-2 text-sm font-semibold cursor-pointer select-none text-neutral-500 dark:text-neutral-400">
        Open in new tab
        <Switch
            v-model:checked="openInNewTab"
        />
      </label>
    </div>
  </Surface>
</template>
<script setup>
import { computed, ref } from 'vue'
import Surface from "@/tiptap/components/ui/Surface.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import Toggle from "@/tiptap/components/ui/toggle/Toggle.vue";
import Button from "@/tiptap/components/ui/button/Button.vue";
import Switch from "@/tiptap/components/ui/switch/Switch.vue";
import { Input } from '@/Components/ui/input'
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'

const props = defineProps({
  initialUrl: String,
  initialOpenInNewTab: Boolean,
})

const url = ref(props.initialUrl || '')
const openInNewTab = ref(props.initialOpenInNewTab || false)

const isValidUrl = computed(() => /^(\S+):(\/\/)?\S+$/.test(url.value))
const emit = defineEmits(['set'])
const handleSubmit = (e) => {
  if (isValidUrl.value) {
    emit('set', url.value, openInNewTab.value)
  }
}
</script>
