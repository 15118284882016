<template>
    <AuthenticatedLayout>
        <template #title>Edit account</template>
        <AccountForm
            @submit="submit"
        />
    </AuthenticatedLayout>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import {displayErrorMessagesAsToast} from '../../utils/errorUtils.js';
import AccountForm from "@/Components/accounts/AccountForm.vue";
import {router, usePage} from "@inertiajs/vue3";
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const page = usePage();
const account = page.props?.account || {};
const submit = (form) => {
    form
        .transform((data) => ({
            ...data,
            _method: 'put',
            avatar: data.avatar ? data.avatar : undefined,
        }))
        .post(`/accounts/${account.ulid}`, {
            forceFormData: true,
            onSuccess: () => {
                router.visit('/accounts')
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
};
</script>
