import { usePage } from '@inertiajs/vue3';

export function useOpenAi() {
    const OPENAI_TYPE = {
        gemeni: 'gemeni',
        chatgpt: 'chatgpt'
    };

    const pageProps = usePage().props;
    const chatModel= pageProps.auth.tenant.openai_type;

    return {
        OPENAI_TYPE: OPENAI_TYPE,
        chatModel,
        isGemeni: chatModel === OPENAI_TYPE.gemeni,
        isChatGPT: chatModel === OPENAI_TYPE.chatgpt,
    }
}
