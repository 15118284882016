import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import HeaderNodeTemplate from './components/HeaderNodeTemplate.vue';
import { NodeSelection } from "@tiptap/pm/state";

export const headerSuggestion = Node.create({
    name: 'header',

    group: 'block',

    addAttributes() {
        return {
            id: {
                default: null,
            },

            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            inline: {
                default: false,
            },
            fullWidth: {
                default: false,
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'header'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        return ['header', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(HeaderNodeTemplate);
    },

    addCommands() {
        return {
            setHeader: ({id}) => ({ commands, tr, dispatch, chain }) => {
                const { selection } = tr

                const node = this.type.create({id})

                if (dispatch) {
                    tr.replaceRangeWith(selection.from, selection.to, node)
                }

                return true;
            },
            toggleFullScreen: () => ({ tr, state, dispatch }) => {

                if (!(tr.selection instanceof NodeSelection)) {
                    console.error('Selection is not a node.');
                    return false;
                }

                const { node, from } = tr.selection;

                if (!node.type.spec.attrs.fullWidth) {
                    console.error('Selected node does not support `fullWidth` attribute.');
                    return false;
                }

                if (dispatch) {
                    const attrs = { ...node.attrs, fullWidth: !node.attrs.fullWidth };
                    tr.setNodeMarkup(from, null, attrs);
                    dispatch(tr);
                }

                return true;
            }
        }
    },
});

export default headerSuggestion;
