<template>
    <div class="col-span-full flex items-center">
        <label :for="id" class="block text-sm font-medium leading-6 text-gray-900 group">
            <template v-if="$slots.trigger">
                <slot name="trigger"/>
            </template>

            <span class=" flex justify-center items-center flex-col" v-else>
            <template v-if="$slots.default && !src">
                <slot name="default"/>
            </template>
            <div v-else class="group relative">
                <BaseAvatar
                    :src="src"
                    :name="props.name"
                    class="w-[100px] rounded-full object-cover object-top"
                    aria-hidden="true"
                    v-bind="$attrs"
                />
                <div class="hidden group-hover:flex absolute inset-0 items-center justify-center">
                    <Button tag="span" class="bg-white" variant="secondary">Replace</Button>
                </div>
            </div>

            <span class="mt-2" v-if="showButton">
              <span
                  class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  v-tooltip="'Upload PNG, JPG, at 1:1. 400x400 or larger is recommended, max 5MB'"
              >
                Change photo
              </span>
            </span>
          </span>
        </label>
        <input
            v-if="!disabled"
            :id="id"
            :name="props.name"
            type="file"
            accept="image/*"
            class="w-0 opacity-0"
            @change="onFileChange"
        >

        <Dialog v-model:visible="cropSrc" modal header="Edit block">
            <cropper
                ref="cropperEl"
                class="cropper"
                :src="cropSrc"
                v-bind="cropperProps"
                :transitions="false"
                :debounde="false"
            />
            <Slider :zoom="zoom" @change="onZoom" class="w-full"/>

            <div v-if="description" class="text-xs text-gray-500 text-center">{{ description }}</div>
            <div class="flex gap-2">
                <Button class="w-full mt-4" size="small" severity="secondary" label="Cancel"
                        @click="cropSrc = null"></Button>
                <Button class="w-full mt-4" :loading="loading" size="small" label="Save" @click="onSaveCrop"></Button>
            </div>
        </Dialog>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { base64ToFile, blobToBase64, fileToDataURL } from '../../utils/fileUtils';
import BaseAvatar from './BaseAvatar.vue';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import { uniqueId } from 'lodash-es';
import Slider from '../Slider.vue';
import Button from "@/tiptap/components/ui/button/Button.vue";


const props = defineProps({
    name: {
        type: String,
        default: 'photo'
    },
    description: {
        type: String,
        default: null
    },
    modelValue: {
        type: [String],
    },
    showButton: {
        type: Boolean,
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    showHelp: {
        type: Boolean,
        default: true
    },
    canCrop: {
        type: Boolean,
        default: true
    },
    cropperProps: {
        type: Object,
        default: () => ({
            'stencil-props': {
                handlers: {},
                movable: false,
                resizable: false,
            },
            'image-restriction': 'stencil',
            'stencil-size': {
                width: 200,
                height: 200
            }
        })
    },
    submit: {
        type: Function,
        default: null
    }
})

const zoom = ref(0);
const id = uniqueId();
const cropSrc = ref(null);
const cropperEl = ref(null);
const cropName = ref(null);
const emit = defineEmits(['update:modelValue'])

const src = ref(props.modelValue)
const loading = ref(false);


// https://codesandbox.io/p/sandbox/vue-advanced-cropper-twitter-suoyc?file=%2Fsrc%2FApp.vue%3A96%2C24
function onZoom(value) {
    const cropper = cropperEl.value;
    if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
            const minHeight = cropper.sizeRestrictions.minHeight;
            const imageHeight = cropper.imageSize.height;
            // Determine the current absolute zoom and the new absolute zoom
            // to calculate the sought relative zoom value
            cropper.zoom(
                (imageHeight - zoom.value * (imageHeight - minHeight)) /
                (imageHeight - value * (imageHeight - minHeight))
            );
        } else {
            const minWidth = cropper.sizeRestrictions.minWidth;
            const imageWidth = cropper.imageSize.width;
            cropper.zoom(
                (imageWidth - zoom.value * (imageWidth - minWidth)) /
                (imageWidth - value * (imageWidth - minWidth))
            );
        }
    }
    zoom.value = value;
}

async function onFileChange(event) {
    const target = event.target
    const file = target.files?.[0]
    if (!file) {
        return
    }

    if (!props.canCrop) {
        emit('update:modelValue', file)
        src.value = await fileToDataURL(file)
    } else {
        blobToBase64(file, function (b) {
            cropSrc.value = b
            cropName.value = file.name
        });
    }
}

function onSaveCrop() {
    loading.value = true;
    const {canvas} = cropperEl.value.getResult();
    const file = base64ToFile(canvas.toDataURL(), cropName.value);

    if (props.submit) {
        return props.submit(file)
            .then(() => {
                cropSrc.value = null

                src.value = canvas.toDataURL()
            }).catch((err) => {
                console.log('Error', err)
            }).finally(() => {
                loading.value = false;
                zoom.value = 0;
            })
    } else {
        emit('update:modelValue', file)
        cropSrc.value = null
        src.value = canvas.toDataURL()
        loading.value = false;
        zoom.value = 0;
    }
}

watch(() => props.modelValue, (value) => {
    if (value !== src.value && !(value instanceof File)) {
        src.value = value
    }
})
</script>
<style>
.cropper {
    height: 600px;
    background: #DDD;
}
</style>
