import global from './global.js';
import autocomplete from './autocomplete';
import calendar from './calendar';
import dropdown from './dropdown';
import checkbox from './checkbox';
import inputgroup from './inputgroup';
import inputnumber from './inputnumber';
import inputswitch from './inputswitch';
import inputtext from './inputtext';
import multiselect from './multiselect';
import password from './password';
import radiobutton from './radiobutton';
import rating from './rating';
import slider from './slider';
import selectbutton from './selectbutton';
import textarea from './textarea';
import togglebutton from './togglebutton';
import button from './button';
import splitbutton from './splitbutton';
import datatable from './datatable';
import dataview from './dataview';
import orderlist from './orderlist';
import paginator from './paginator';
import picklist from './picklist';
import card from './card';
import accordion from './accordion';
import divider from './divider';
import panel from './panel';
import tabview from './tabview';
import confirmpopup from './confirmpopup';
import dialog from './dialog';
import overlaypanel from './overlaypanel';
import sidebar from './sidebar';
import tooltip from './tooltip';
import breadcrumb from './breadcrumb';
import contextmenu from './contextmenu';
import menu from './menu';
import menubar from './menubar';
import megamenu from './megamenu';
import steps from './steps';
import tabmenu from './tabmenu';
import avatar from './avatar';
import badge from './badge';
import scrolltop from './scrolltop';
import skeleton from './skeleton';
import progressbar from './progressbar';
import tag from './tag';
import inlinemessage from './inlinemessage';
import toast from './toast';
import message from './message';

export default {
    global,
    directives: {
        tooltip
    },
    autocomplete,
    calendar,
    dropdown,
    checkbox,
    inputgroup,
    inputnumber,
    inputswitch,
    inputtext,
    multiselect,
    password,
    radiobutton,
    rating,
    slider,
    selectbutton,
    textarea,
    togglebutton,
    button,
    splitbutton,
    datatable,
    dataview,
    orderlist,
    paginator,
    picklist,
    card,
    accordion,
    divider,
    panel,
    tabview,
    confirmpopup,
    dialog,
    overlaypanel,
    sidebar,
    breadcrumb,
    contextmenu,
    menu,
    menubar,
    megamenu,
    steps,
    tabmenu,
    avatar,
    badge,
    scrolltop,
    skeleton,
    progressbar,
    tag,
    inlinemessage,
    message,
    toast,
}
