<template>
    <AuthenticatedLayout>
        <template #title>Account planner</template>

        <div class="flex flex-col items-center justify-center h-[calc(100vh-120px)] text-center font-sans max-w-4xl mx-auto">
            <div class="flex flex-col items-center justify-center flex-grow w-full">
                <div v-if="displayedMessages.length === 0">
                    <div class="mb-5 mt-24">
                        <img class="h-12 w-auto mx-auto" src="/images/logo_symbol.png" alt="Roombriks"/>
                    </div>
                    <h2 class="text-xl mb-8">How can I help you today?</h2>
                    <div class="grid grid-cols-2 gap-3 w-full">
                        <button @click="handleClick('Summarize My Active Rooms')" class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                            <span>Summarize My Active Rooms</span>
                            <span class="text-sm text-gray-600">Display a summary of information for your active rooms</span>
                        </button>

                        <button @click="handleClick('Overview of Software Adoption by Accounts')" class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                            <span>Overview of Software Adoption by Accounts</span>
                            <span class="text-sm text-gray-600">Displays the count and status of software adoption</span>
                        </button>

                        <button @click="handleClick('Which customers require more attention?')" class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                            <span>Which customers require more attention?</span>
                            <span class="text-sm text-gray-600">Find out where you need to spend your time</span>
                        </button>

                        <button @click="handleClick('Which customer questions remain unanswered?')" class="px-5 py-2 bg-gray-50 border rounded hover:bg-gray-100 flex flex-col text-left text-md gap-1">
                            <span>Which customer questions remain unanswered?</span>
                            <span class="text-sm text-gray-600">Displays any unresolved questions in your rooms</span>
                        </button>

                    </div>
                </div>
                <div class="flex flex-col gap-2 items-start justify-start w-full">
                    <ChatMessage
                        v-for="(message, index) in displayedMessages"
                        :key="index"
                        :comment="message.comment"
                        :onComplete="message.onComplete"
                    />
                </div>
                <div class="flex items-center p-4 pt-0 gap-2 mt-auto w-full">
                    <form @submit.prevent="sendMessage" class="flex items-end w-full">
                        <FormField v-slot="{ componentField }" name="password" class="w-full">
                            <FormItem v-auto-animate class="w-full">
                                <FormControl>
                                    <div class="flex">
                                        <Input type="text" @input="e => form.password = e.target.value"name="password" v-bind="componentField" class="rounded-r-none border-r-0"/>
                                        <Button
                                            class="rounded-l-none !border-l-0"
                                            variant="secondary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        </FormField>
                    </form>
                </div>
            </div>
        </div>
    </AuthenticatedLayout>
</template>
<script setup>

import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import Input from '@/Components/ui/input/Input.vue';
import { useForm } from '@inertiajs/vue3';
import { FormControl, FormField, FormItem, FormMessage, } from '@/Components/ui/form'
import { ref } from 'vue';
import ChatMessage from './ChatMessage.vue';
import { useAuth } from '../../hooks/useAuth.js';
import Button from '../../../js/tiptap/components/ui/button/Button.vue';

const form = useForm({
    input: ''
});

const { user } = useAuth()

const allMessages = [
    {
        comment: {
            comment: `<p>Could I get an update on the status of the POV for <span href="" class="text-blue-600">@contoso</span> in <span class="text-blue-600">@Contoso POV</span>?</p>`,
            created_at: new Date().toISOString(),
            creator: {
                name: user.name,
                avatar: user.avatar
            }
        },
        onComplete: null
    },
    {
        comment: {
            comment: `Here is the latest information in the project plans:\n
Phase 1 is partially complete and the remaining tasks are:
<ul class="list-disc ml-12 my-1.5"><li>Register for Contoso ID.</li><li>Download Edge Gateway.</li></ul>
Phase 2 has no tasks completed and the tasks remaining are:
<ul class="list-disc ml-12 my-1.5"><li>Create org name.</li><li>Assign permissions.</li><li>Select a region.</li><li>Configuration of the edge appliance.</li></ul>\n
`,
            created_at: new Date().toISOString(),
            creator: {
                name: '',
                avatar: '/images/logo2.png'
            }
        },
        onComplete: null
    },
    {
        comment: {
            comment: 'In the same room are there any unanswered questions?',
            created_at: new Date().toISOString(),
            creator: {
                name: user.name,
                avatar: user.avatar
            }
        },
        onComplete: null
    },
    {
        comment: {
            comment: 'Yes there is currently one question not answered. " What are your compliance requirements? "',
            created_at: new Date().toISOString(),
            creator: {
                name: '',
                avatar: '/images/logo2.png'
            }
        },
        onComplete: null
    },
    {
        comment: {
            comment: 'Do we have the technical win in the room yet?',
            created_at: new Date().toISOString(),
            creator: {
                name: user.name,
                avatar: user.avatar
            }
        },
        onComplete: null
    },
    {
        comment: {
            comment: 'No',
            created_at: new Date().toISOString(),
            creator: {
                name: '',
                avatar: '/images/logo2.png'
            }
        },
        onComplete: null
    },
    {
        comment: {
            comment: 'Is there any technical blockers that we should be concerned about?',
            created_at: new Date().toISOString(),
            creator: {
                name: user.name,
                avatar: user.avatar
            }
        },
        onComplete: null
    },
    {
        comment: {
            comment: 'Yes there is a technical blocker.  We do not support the customer’s current IDP solution.',
            created_at: new Date().toISOString(),
            creator: {
                name: '',
                avatar: '/images/logo2.png'
            }
        },
        onComplete: null
    },
];

const messages = ref(allMessages);
const displayedMessages = ref([]);

let currentMessageIndex = 0;
let displayingMessages = false;

function displayNextMessage() {
    if (currentMessageIndex < messages.value.length) {
        const nextMessage = messages.value[currentMessageIndex];
        nextMessage.onComplete = () => {
            currentMessageIndex++;
            displayNextMessage();
        };
        displayedMessages.value.push(nextMessage);
    } else {
        displayingMessages = false;
    }
}

function sendMessage() {
    if (form.input.trim()) {
        // const userMessage = {
        //     comment: {
        //         comment: form.input,
        //         created_at: new Date().toISOString(),
        //         creator: {
        //             name: user.name,
        //             avatar: user.avatar
        //         }
        //     },
        //     onComplete: handleUserMessageComplete
        // };
        // messages.value.push(userMessage);
        form.input = '';
        if (!displayingMessages) {
            displayingMessages = true;
            displayNextMessage();
        }
    }
}

function handleUserMessageComplete() {
    setTimeout(() => {
        const responseMessage = {
            comment: {
                comment: 'I can help you with that. What else would you like to do?',
                created_at: new Date().toISOString(),
                creator: {
                    name: '',
                    avatar: '/images/logo2.png'
                }
            },
            onComplete: null
        };
        messages.value.push(responseMessage);
        if (!displayingMessages) {
            displayingMessages = true;
            displayNextMessage();
        }
    }, 100); // Adjust this delay if necessary
}

function handleClick(option) {
    const userMessage = {
        comment: {
            comment: `You selected: ${option}`,
            created_at: new Date().toISOString(),
            creator: {
                name: user.name,
                avatar: user.avatar
            }
        },
        onComplete: () => {
            setTimeout(() => {
                const systemMessage = {
                    comment: {
                        comment: `Let's proceed with ${option.toLowerCase()}.`,
                        created_at: new Date().toISOString(),
                        creator: {
                            name: '',
                            avatar: '/images/logo2.png'
                        }
                    },
                    onComplete: null
                };
                messages.value.push(systemMessage);
                if (!displayingMessages) {
                    displayingMessages = true;
                    displayNextMessage();
                }
            }, 150); // Adjust this delay if necessary
        }
    };
    // messages.value.push(userMessage);
    if (!displayingMessages) {
        displayingMessages = true;
        displayNextMessage();
    }
}

</script>
