<template>
    <AuthLayout>
        <FormExample/>
        <h1 class="text-center">
            Sign In
        </h1>
        <div class="flex justify-center space-x-2 mt-2">
            <p>Don't have an account yet?</p>
            <a :href="`${page.props.baseUrl}/register`">Sign Up</a>
        </div>
        <form @submit.prevent="onSubmit" class="mt-4">
            <FormField v-slot="{ componentField }" name="email">
                <FormItem v-auto-animate>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                        <InputWithIcon type="email" :icon="MailIcon" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <FormField v-slot="{ componentField }" name="password">
                <FormItem v-auto-animate>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                        <InputWithIcon type="password" :icon="LockIcon" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>
            <FormField v-slot="{ value, handleChange }" type="checkbox" name="remember">
                <FormItem v-auto-animate class="flex items-start space-x-2 mt-6">
                    <FormControl>
                        <Checkbox :checked="value" @update:checked="handleChange"/>
                    </FormControl>
                    <div class="!mt-0 flex w-full justify-between">
                        <div class="mt-1">
                            <FormLabel class="pt-0">Remember me</FormLabel>
                            <FormMessage/>
                        </div>
                        <div class="text-sm leading-6">
                            <Link href="/forgot-password">
                                Forgot password?
                            </Link>
                        </div>
                    </div>
                </FormItem>
            </FormField>
            <Button type="submit" class="w-full mt-12" :loading="form.processing">
                Sign in
            </Button>
        </form>
    </AuthLayout>
</template>
<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import AuthLayout from "@/Layouts/AuthLayout.vue";
import {displayErrorMessagesAsToast} from "../../utils/errorUtils.js";
import { LockIcon, MailIcon } from "lucide-vue-next";
import { vAutoAnimate } from '@formkit/auto-animate/vue'
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { InputWithIcon } from '@/Components/ui/input'
import { Checkbox } from '@/Components/ui/checkbox'
import Button from "@/tiptap/components/ui/button/Button.vue";
import * as yup from "yup";
import useValidatedForm from "@/hooks/useValidatedFormNew";
import { useToast } from 'primevue/usetoast';
const toast = useToast();
defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const page = usePage();

const schema = yup.object().shape({
    email: yup.string().email().required('Please enter your email address.'),
    password: yup.string().min(6).required('Please enter your password.'),
    remember: yup.boolean(),
});

const form = useValidatedForm({
    email: '',
    password: '',
    remember: false,
}, schema);

const onSubmit = () => {
    form.post('/login', {
        onFinish: () => form.reset('password'),
        onSuccess:(data) => {
            localStorage.setItem('isSidebarVisible', 'true');
            window.location.href = data.props.flash.redirectUrl;
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
};
</script>
