<template>
    <AuthenticatedLayout>
        <template #title>Billing</template>
        <StripeAppsumoCard v-if="is_app_sumo" />
        <StripePlanCard v-else/>
    </AuthenticatedLayout>
</template>
<script setup lang="ts">
import StripePlanCard from "@/Components/StripePlanCard.vue";
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import { usePage } from "@inertiajs/vue3";
import StripeAppsumoCard from "@/Components/StripeAppsumoCard.vue";

const is_app_sumo = usePage().props.auth.is_appsumo;
</script>
