<template>
    <AuthenticatedLayout>
        <template #title>Invite member</template>
        <BaseFormCard
            :loading="form.processing"
            :form="form"
            :save-text="'Invite'"
            @submit="submit">
            <FormCardSection title="Invitation details">
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="email">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <div class="col-span-6">
                    <FormField v-slot="{ componentField }" name="role_id">
                        <FormItem v-auto-animate class="col-span-6">
                            <FormLabel>Role</FormLabel>
                            <FormControl>
                                <Select v-bind="componentField">
                                    <FormControl>
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select the role"/>
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem v-for="role in roleOptions" :value="role.id">
                                                {{ role.name }}
                                            </SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
            </FormCardSection>
        </BaseFormCard>
    </AuthenticatedLayout>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import * as yup from 'yup';
import { usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import BaseFormCard from "@/Components/forms/BaseFormCard.vue";
import InputText from "@/Components/forms/InputText.vue";
import Dropdown from "@/Components/forms/Dropdown.vue";
import FormCardSection from "@/Components/forms/FormCardSection.vue";
import useValidatedForm from '../../hooks/useValidatedFormNew';
import {capitalize} from "lodash-es";
import {computed} from "vue";
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/Components/ui/select'
import { Input } from "@/Components/ui/input";
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const page = usePage();
const roles = page.props.roles;

const roleOptions = computed(() => {
    const roles = page.props.roles || []
    return roles.map(r => {
        return {
            ...r,
            name: capitalize(r.name)
        }
    })
})

const schema = yup.object().shape({
    email: yup.string().email().required(),
    role_id: yup.number().required(),
});

const form = useValidatedForm({
    email: '',
    role_id: roles[0].id,
}, schema);

const submit = () => {
    form.post('/invitations', {
        onSuccess: () => form.reset(),
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
};
</script>
