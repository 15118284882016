<template>
  <button v-bind="$attrs"
          :class="wrapperClassName"
          @click="onClick"
  >
    <div :style="{ backgroundColor: color, color: color }" :class="bubbleClassName"></div>
  </button>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  color: String,
  active: Boolean,
});

const wrapperClassName = computed(() => [
  'flex items-center justify-center rounded group',
  props.active ? 'bg-neutral-100' : 'hover:bg-neutral-100',
]);

const bubbleClassName = computed(() => [
  'w-6 h-6 rounded bg-slate-100 shadow-sm ring-offset-2 ring-current',
  props.active ? 'ring-1' : 'hover:ring-1',
]);

const emit = defineEmits(['change'])
function onClick() {
  emit('change', props.color);
}
</script>
