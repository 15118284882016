<template>
  <BubbleMenu
      :editor="editor"
      pluginKey="textMenu"
      :shouldShow="shouldShow"
      :updateDelay="0"
      :tippyOptions="tippyOptions"
  >
    <div v-if="showEdit">
      <LinkEditorPanel
          :initialUrl="link"
          :initialOpenInNewTab="target === '_blank'"
          @set="onSetLink"/>
    </div>
    <div v-else>
      <LinkPreviewPanel :url="link" @clear="onUnsetLink" @edit="handleEdit"/>
    </div>
  </BubbleMenu>
</template>

<script setup>
import { computed, ref } from 'vue'
import { BubbleMenu } from '@tiptap/vue-3'
import LinkEditorPanel from "@/tiptap/components/menus/panels/LinkEditor/LinkEditorPanel.vue";
import LinkPreviewPanel from "@/tiptap/components/menus/panels/LinkPreviewPanel/LinkPreviewPanel.vue";

const props = defineProps({
  editor: Object,
  appendTo: Object
})

const tippyOptions = {
  popperOptions: {
    modifiers: [{ name: 'flip', enabled: false }],
  },
  appendTo: () => {
    return props.appendTo
  },
  onHidden: () => {
    showEdit.value = false
  },
}
const showEdit = ref(false)

const shouldShow = () => {
  const isActive = props.editor.isActive('link')
  return isActive
}

const linkAttributes = computed(() => {
  return props.editor.getAttributes('link')
})
const link = computed(() => {
  return linkAttributes.value.href
})
const target = computed(() => {
  return linkAttributes.value.target
})

const handleEdit = () => {
  showEdit.value = true
}

const onSetLink = (url, openInNewTab) => {
  props.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url, target: openInNewTab ? '_blank' : '' })
      .run()
  showEdit.value = false
}

const onUnsetLink = () => {
  props.editor.chain().focus().extendMarkRange('link').unsetLink().run()
  showEdit.value = false
}

const onShowEdit = () => {
  showEdit.value = true
}

const onHideEdit = () => {
  showEdit.value = false
}
</script>
