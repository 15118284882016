<template>
  <component
      :is="computedComponent"
      :class="className"
      :disabled="disabled"
      v-bind="$attrs"
  >
    <component
        v-if="iconComponent"
        :is="iconComponent"
        class="w-4 h-4"
    />
    <Icon
        v-else-if="icon"
        :name="icon"
        class="w-4 h-4"
    />
    {{ label }}
  </component>
</template>

<script setup>
import { computed } from 'vue';
import { PopoverClose } from "radix-vue";
import Icon from "@/tiptap/components/ui/Icon.vue"; // Assuming you have a Vue equivalent of your icons

const props = defineProps({
  label: [String, Object], // Support for string or slot
  icon: String,
  iconComponent: Object, // This could be a component object
  close: {
    type: Boolean,
    default: true,
  },
  disabled: Boolean,
  isActive: Boolean,
});

const className = computed(() => {
  return [
    'flex items-center gap-2 p-1.5 text-sm font-medium text-neutral-500 text-left bg-transparent w-full rounded',
    {
      'hover:bg-neutral-100 hover:text-neutral-800 dark:hover:bg-neutral-900 dark:hover:text-neutral-200': !props.isActive && !props.disabled,
      'bg-neutral-100 text-neutral-800 dark:bg-neutral-900 dark:text-neutral-200': props.isActive && !props.disabled,
      'text-neutral-400 cursor-not-allowed dark:text-neutral-600': props.disabled,
    }
  ];
});

const computedComponent = computed(() => (props.close ? PopoverClose : 'div'));
</script>
