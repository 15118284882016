import { ref } from 'vue';

export function useRoomStatusOptions() {
    const roomStatusOptions = ref([
        {label: 'Assigned', value: 'assigned'},
        {label: 'Discovery', value: 'discovery'},
        {label: 'Demo', value: 'demo'},
        {label: 'Proof of concept', value: 'proof_of_concept'},
        {label: 'Scoping', value: 'scoping'},
        {label: 'Technical Win', value: 'technical_win'},
        {label: 'Technical Loss', value: 'technical_loss'},
        {label: 'Closed / Lost', value: 'closed_lost'},
    ])

    return {
        roomStatusOptions
    }
}
