import {Editor} from "@tiptap/vue-3";
import { router, usePage } from "@inertiajs/vue3";
import {useTipTapStore} from "../../stores/useTipTapStore.js";
import axios from "axios";
export function prepareEditor(editor: Editor) {
    return editor.chain().focus()
}
export function onInsertBulletList(editor: Editor) {
    prepareEditor(editor).toggleBulletList().run()
}
export function onInsertColumns(editor: Editor) {
    prepareEditor(editor)
        .focus()
        .setColumns()
        // .focus(editor.state.selection.head - 1)
        .setTextSelection(editor.state.selection.head + 2)
        .run()
}

export function onInsertTable(editor: Editor) {
    prepareEditor(editor).insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run()
}

export function onInsertTaskList(editor: Editor) {
    prepareEditor(editor).toggleTaskList().run()
}

export function openSlashCommand(editor: Editor) {
    prepareEditor(editor).insertContent('/').run()
}

export function onInsertProjectPlan(editor: Editor) {
    const roomUlid = useTipTapStore().currentRoomUlid;
    const currentPage = usePage().props.currentPage;
    const node = editor.chain().focus().setProjectPlanSpinner({
        id: 'project-plan-spinner'
    }).run();

    axios.post(`/plans`, {
        room_ulid: roomUlid,
        page_ulid: currentPage.ulid,
    }).then((response) => {
        const { plan } = response.data;

        editor.chain().focus().setProjectPlan({
            id: plan.id,
        }).run();
    }).catch((error) => {
        console.log(error);
    })
}
