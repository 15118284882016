<template>
    <div class="flex items-center min-h-[60px] space-x-5 h-full file-icon-info cursor-pointer no-underline text-base-content">
        <FileIcon
            :icon-class="typeColors.text"
            :background-class="typeColors.background"
            :extension="extension"
            :loading="loading"
            :data="data"
            :size="iconSize"
        />
        <div class="flex flex-col leading-none text-sm max-w-full w-auto truncate">
            <span v-if="loading" class="h-[14px] w-20 bg-gray-100 rounded animate-pulse mb-0.5"></span>
            <span v-else class="font-semibold leading-tight mb-0.5 truncate w-auto">
            {{ fileName }}
          </span>
            <span v-if="loading" class="h-4 w-12 bg-gray-100 rounded animate-pulse"></span>
            <span v-else class="text-base-300 text-xs text-left">
            {{ fileSize }}
          </span>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { getFileExtension, getFileTypeColor, getHumanFileSize } from "@/utils/documentUtils";
import FileIcon from "./FileIcon.vue";

const props = defineProps({
    data: {
        type: Object,
        default: () => ({})
    },
    loading: Boolean,
    iconSize: {
        type: String,
        default: 'lg',
    }
})

const id = computed<string>(() => props.data.id || props.data?.attributes?.id)

const typeColors = computed(() => {
    return getFileTypeColor(props.data?.file_name, props.loading)
})

const fileSize = computed(() => {
    return getHumanFileSize(props.data?.size)
})

const extension = computed(() => {
    return getFileExtension(props.data?.file_name)
})

const fileName = computed(() => {
    return `${props.data?.name}.${extension.value}`
})
</script>
