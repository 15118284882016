import { router, useForm, usePage } from '@inertiajs/vue3';
import { useAuth } from './useAuth.js';
import { nextTick, ref, watch, watchEffect } from 'vue';

export function useRoomComments(scrollContainer, isInternalRoom = true) {
    const url = isInternalRoom ? '/room-comments' : '/room-external-comments';
    const event = isInternalRoom ? 'comment.created' : 'external.comment.created';
    const page = usePage();
    const {user} = useAuth()
    const room = ref(page.props.room);

    const comments = ref(
        isInternalRoom ? room.value.comments : room.value.external_comments
    );
    const form = useForm({
        input: '',
    });

    watch(comments.value, () => {
        scrollToBottom()
    });

    watchEffect(() => {
        scrollToBottom()
    });

    window.pusher.subscribe(`room.${room.value.id}`)
        .bind(event, ({comment}) => {
            if (comment.email !== user.email) {
                comments.value.push(comment);
            }
        });

    function scrollToBottom() {
        nextTick(() => {
            if (scrollContainer.value) {
                // Scroll to the bottom of the container
                const {scrollHeight, clientHeight} = scrollContainer.value;
                scrollContainer.value.scrollTop = scrollHeight - clientHeight;
            }
        });
    }

    function sendMessage() {
        if (!form.input) {
            return;
        }

        const value = form.input;

        comments.value.push({
            comment: value,
            creator: {
                id: user.id,
                name: user.name,
                avatar: user.avatar,
                email: user.email,
            },
            email: user.email,
            created_at: new Date().toISOString(),
        });

        form.reset();

        router.post(`${url}/${room.value.ulid}`, {input: value}, {
            preserveScroll: true,
            onSuccess: () => {

            },
        });
    }

    return {
        sendMessage,
        form,
        comments,
    }
}
