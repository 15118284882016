<template>
    <AuthenticatedLayout>
        <template #title>Edit room</template>
        <RoomForm @submit="submit" :data="page.props.room"/>
    </AuthenticatedLayout>
</template>
<script setup>
import AuthenticatedLayout from '../../Layouts/AuthenticatedLayout.vue';
import { router, useForm, usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';
import RoomForm from './RoomForm.vue';
import { formatDateToISO } from '../../utils/formatDate.js';
import { useToast } from 'primevue/usetoast';

const page = usePage();
const form = useForm({})
const toast = useToast()
const submit = (submittedForm) => {
    form
        .transform((data) => {
            data = {
                ...submittedForm
            }
            data.users = data.users.map(user => user.id);
            data.start_date = data.start_date ? formatDateToISO(Array.isArray(data.start_date) ? data.start_date[0] : data.start_date) : null;
            data.end_date = data.end_date ? formatDateToISO(Array.isArray(data.end_date) ? data.end_date[0] : data.end_date) : null;

            return data;
        })
        .put('/rooms/' + page.props.room.ulid, {
            onSuccess: () => {
                form.reset()
                router.visit('/rooms')
            },
            onError: (err) => {
                displayErrorMessagesAsToast(err, toast)
            },
        });
};
</script>
