<template>
    <Dialog v-bind="$attrs"
            modal header="Add embed block" >
            <p class="text-sm text-gray-12 mb-4 mt-2">
                Roombriks allows embedding content from various applications including but not limited to the ones below. Please make sure that the people you are sharing it with have access to the external link
            </p>
            <div class="flex flex-col gap-2">
                <div class="flex items-center gap-2 text-sm">
                    Presentations:
                    <img src="@/Layouts/icons/logos/Microsoft Power Point.png" title="Microsoft Power Point" alt="Microsoft Power Point" width="25" heigh="25"/>
                    <img src="@/Layouts/icons/logos/google slides.png" title="Google Slides" alt="Google Slides" style="height: 25px;"/>
                </div>
                <div class="flex items-center gap-2 text-sm">
                    Documents and Forms:
                    <img src="@/Layouts/icons/logos/Microsoft Forms.png" title="Microsoft Forms" alt="Microsoft Forms" width="25" heigh="25"/>
                    <img src="@/Layouts/icons/logos/Microsoft Office Word.png" title="Microsoft Office Word" alt="Microsoft Office Word" width="25" heigh="25"/>
                    <img src="@/Layouts/icons/logos/google docs.png" title="Google Docs" alt="Google Docs" style="height: 25px;"/>
                    <img src="@/Layouts/icons/logos/google forms icon.png" title="Google Forms" alt="Google Forms" width="25" heigh="25"/>
                </div>
                <div class="flex items-center gap-2 text-sm">
                    Videos:
                    <img src="@/Layouts/icons/logos/youtube.png" title="Youtube" alt="Youtube" width="25"/>
                    <Zoom width="25" height="25" title="Zoom" alt="Zoom"/>
                    <img src="@/Layouts/icons/logos/loom.png" title="Loom" alt="Loom" width="25"/>
                </div>
                <div class="flex items-center gap-2 text-sm">
                    Spreadsheets:
                    <img src="@/Layouts/icons/logos/Microsoft Excel.png" title="Microsoft Excel" alt="Microsoft Excel" width="25"/>
                    <img src="@/Layouts/icons/logos/google sheets.png" title="Google Sheets" alt="Google Sheets" style="height: 25px;"/>
                </div>
                <div class="flex items-center gap-2 text-sm">
                    Design:
                    <img src="@/Layouts/icons/logos/mirro.png" title="Mirro" alt="Mirro" width="25"/>
                    <img src="@/Layouts/icons/logos/figma.png" title="Figma" alt="Figma" style="height: 25px;"/>
                </div>
                <div class="flex items-center gap-2 text-sm">
                    File:
                    <img src="@/Layouts/icons/logos/Microsoft Office.png" title="Microsoft Office" alt="Microsoft Office" width="25"/>
                    <img src="@/Layouts/icons/logos/google drive.png" title="Google Drive" alt="Google Drive" width="25"/>
                    <DropboxIcon width="25" height="25"/>
                </div>
                <div class="flex items-center gap-2 text-sm">
                    Reporting:
                    <TableauSoftware width="25" height="25" title="asdasd"/>
                </div>
            </div>

            <BaseFormCard :form="form" class="w-full mt-4" @submit="onSubmit">
                <div class="flex align-items-center gap-3 mb-3 ">
                    <FormField v-slot="{ componentField }" name="src" >
                        <FormItem v-auto-animate class="w-full">
                            <FormLabel>URL</FormLabel>
                            <FormControl>
                                <Input placeholder="Insert the url" v-bind="componentField"/>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    </FormField>
                </div>
                <template #footer>
                    <div class="flex justify-center gap-2">
                        <Button class="w-full mt-4" size="small" variant="secondary " @click="emit('close')"
                        >
                            Cancel
                        </Button>
                        <Button
                            class="w-full mt-4"size="small" type="submit"
                            :loading="form.processing"
                        >
                            Save
                        </Button>
                    </div>
                </template>
            </BaseFormCard>
    </Dialog>
</template>
<script setup lang="ts">
import DropboxIcon from '@/Layouts/icons/logos/DropboxIcon.vue';
import BaseFormCard from '@/Components/forms/BaseFormCard.vue';
import TableauSoftware from '@/Layouts/icons/logos/TableauSoftware.vue';
import Zoom from '@/Layouts/icons/logos/zoom.vue';
import useValidatedForm from '@/hooks/useValidatedFormNew';
import * as yup from "yup";
import { FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/Components/ui/form'
import { Input } from "@/Components/ui/input";
import Button from '../../../components/ui/button/Button.vue';

const emit = defineEmits(['close', 'submit'])

const schema = yup.object().shape({
    src: yup.string().required('Please enter a valid URL.')
        .url('Please enter a valid URL.'),
});

const form = useValidatedForm({
    src: ''
}, schema);

function onSubmit() {
    emit('submit', form.data().src)
}

</script>
