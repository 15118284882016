<template>
    <AuthLayout>
        <h1 class="text-center">
            Forgot Password
        </h1>
        <div class="flex justify-center space-x-2 mt-2">
            <p>Changed your mind?</p>
            <a :href="`${page.props.baseUrl}/login`">Sign In</a>
        </div>
        <form class="w-full mt-16" @submit.prevent="submit">
            <FormField v-slot="{ componentField }" name="email">
                <FormItem v-auto-animate>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                        <InputWithIcon type="email" name="email" :icon="MailIcon" v-bind="componentField"/>
                    </FormControl>
                    <FormMessage/>
                </FormItem>
            </FormField>

            <div class="mt-10">
                <Button class="w-full"
                        size="small"
                        type="submit"
                >
                    Send Email
                </Button>
            </div>
        </form>
    </AuthLayout>
</template>
<script setup>
import * as yup from 'yup';
import { router, usePage } from '@inertiajs/vue3';
import { displayErrorMessagesAsToast } from '../../utils/errorUtils.js';

import { useForm } from 'vee-validate';
import AuthLayout from '../../Layouts/AuthLayout.vue';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, } from '@/Components/ui/form'
import { InputWithIcon } from '@/Components/ui/input'
import Button from '@/tiptap/components/ui/button/Button.vue';
import { MailIcon } from 'lucide-vue-next';
import { useToast } from 'primevue/usetoast';
const toast = useToast();

const schema = yup.object().shape({
    email: yup.string().email().required('Please enter your email address.'),
});

const form = useForm({
    validationSchema: schema,
    initialValues: {
        email: '',
    },
});

const page = usePage();
const submit = () => {
    router.post('/forgot-password', {email: form.values.email}, {
        onFinish: () => form.reset('email'),
        onSuccess: (data) => {
            toast({
                title: 'Email Sent',
                description: 'We have emailed you a link to reset your password.',
                life: 3000,
            });
        },
        onError: (err) => {
            displayErrorMessagesAsToast(err, toast)
        },
    });
};
</script>
