<template>
  <DragHandle
      pluginKey="ContentItemMenu"
      :editor="editor"
      :tippyOptions="{
        offset: [-2, 16],
        zIndex: 99,
      }"
      @node-change="data.handleNodeChange"
  >
    <div class="flex items-center gap-0.5">
      <ToolbarButton @click="actions.handleFullScreen" v-if="isHeaderComponent">
        <i class="pi pi-arrows-h" ></i>
      </ToolbarButton>
      <ToolbarButton @click="actions.handleAdd">
        <Icon name="Plus"/>
      </ToolbarButton>
      <PopoverRoot v-model.open="menuOpen">
        <PopoverTrigger asChild>
          <ToolbarButton>
            <Icon name="GripVertical"/>
          </ToolbarButton>
        </PopoverTrigger>
        <PopoverContent side="bottom" align="start" :sideOffset="8">
          <Surface class="p-2 flex flex-col min-w-[16rem]">
          <PopoverClose v-if="editable && (isPriceComponent || isContactComponent)">
              <DropdownButton @click="actions.editNode()">
                  <Icon name="Pencil"/>
                  Edit
              </DropdownButton>
          </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.resetTextFormatting">
                <Icon name="RemoveFormatting"/>
                Clear formatting
              </DropdownButton>
            </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.copyNodeToClipboard">
                <Icon name="Clipboard"/>
                Copy to clipboard
              </DropdownButton>
            </PopoverClose>
            <PopoverClose>
              <DropdownButton @click="actions.duplicateNode">
                <Icon name="Copy"/>
                Duplicate
              </DropdownButton>
            </PopoverClose>
            <ToolbarDivider horizontal/>
            <PopoverClose>
              <DropdownButton
                  @click="actions.deleteNode"
                  class="text-red-500 bg-red-500 dark:text-red-500 hover:bg-red-500 dark:hover:text-red-500 dark:hover:bg-red-500 bg-opacity-10 hover:bg-opacity-20 dark:hover:bg-opacity-20"
              >
                <Icon name="Trash2"/>
                Delete
              </DropdownButton>
            </PopoverClose>
          </Surface>
        </PopoverContent>
      </PopoverRoot>
    </div>
  </DragHandle>
</template>
<script setup>
import { useData } from "./composables/useData.ts";
import { Editor } from "@tiptap/vue-3";
import useContentItemActions from "./composables/useContentItemActions.ts";
import DropdownButton from "@/tiptap/components/ui/dropdown/DropdownButton.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import Surface from "@/tiptap/components/ui/Surface.vue";
import ToolbarDivider from "@/tiptap/components/ui/toolbar/ToolbarDivider.vue";
import { PopoverRoot, PopoverClose, PopoverTrigger, PopoverContent } from "radix-vue";
import { computed, ref, watch } from 'vue';
import DragHandle from "@/tiptap/components/menus/ContentItemMenu/DragHandle.vue";
import { useRoomPage } from '../../../../pages/RoomDetail/hooks/useRoomPage.js';

const props = defineProps({
  editor: Editor
})
const data = useData()
const actions = useContentItemActions(props.editor)

const { editable } = useRoomPage();

const menuOpen = ref(false)

const isHeaderComponent = computed(() => {
    return data.currentNode.value?.type?.name === 'header';
})

const isPriceComponent = computed(() => {
    return data.currentNode.value?.type?.name === 'price-card';
})

const isContactComponent = computed(() => {
    return data.currentNode.value?.type?.name === 'contact-card';
})

watch(() => menuOpen.value, () => {
  if (menuOpen) {
    props.editor?.commands.setMeta('lockDragHandle', true)
  } else {
    props.editor?.commands.setMeta('lockDragHandle', false)
  }
})
</script>
