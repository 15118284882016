<template>
  <DropdownMenuRoot>
    <DropdownMenuTrigger v-slot="{ attrs }">
      <ToolbarButton :active="!!currentValue?.value">
        {{ currentFontLabel }}
        <Icon name="ChevronDown" class="w-2 h-2"/>
      </ToolbarButton>
    </DropdownMenuTrigger>
    <DropdownMenuContent v-slot="{ attrs }">
      <Surface class="flex flex-col gap-1 px-2 py-4">
        <div v-for="group in FONT_FAMILY_GROUPS" class="mt-2.5 first:mt-0 gap-0.5 flex flex-col" :key="group.label">
          <DropdownButton
              v-for="font in group.options"
              :key="`${font.label}_${font.value}`"
              :isActive="modelValue === font.value"
              @click="selectFont(font.value)"
          >
            <span :style="{ fontFamily: font.value }">{{ font.label }}</span>
          </DropdownButton>
        </div>
      </Surface>
    </DropdownMenuContent>
  </DropdownMenuRoot>
</template>

<script setup>
import { computed } from 'vue';
import { DropdownMenuContent, DropdownMenuRoot, DropdownMenuTrigger } from "radix-vue";
import Surface from "@/tiptap/components/ui/Surface.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import DropdownButton from "@/tiptap/components/ui/dropdown/DropdownButton.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";

const props = defineProps({
  modelValue: String,
});

const FONT_FAMILY_GROUPS = [
  {
    label: 'Sans Serif',
    options: [
      { label: 'Inter', value: '' },
      { label: 'Arial', value: 'Arial' },
      { label: 'Helvetica', value: 'Helvetica' },
    ],
  },
  {
    label: 'Serif',
    options: [
      { label: 'Times New Roman', value: 'Times' },
      { label: 'Garamond', value: 'Garamond' },
      { label: 'Georgia', value: 'Georgia' },
    ],
  },
  {
    label: 'Monospace',
    options: [
      { label: 'Courier', value: 'Courier' },
      { label: 'Courier New', value: 'Courier New' },
    ],
  },
]
const FONT_FAMILIES = FONT_FAMILY_GROUPS.flatMap(group => [group.options]).flat()

const currentValue = computed(() =>
    FONT_FAMILIES.find((size) => size.value === props.modelValue)
);
const currentFontLabel = computed(() =>
    currentValue.value?.label.split(' ')[0] || 'Inter'
);

const emit = defineEmits(['update:modelValue']);
const selectFont = (size) => {
  emit('update:modelValue', size)
};
</script>
