<template>
  <BubbleMenu
      :editor="editor"
      :pluginKey="pluginKey"
      :shouldShow="shouldShow"
      :updateDelay="0"
      :tippyOptions="tippyOptions"
  >
    <ToolbarWrapper :shouldShowContent="shouldShow()">
      <ToolbarButton
          tooltip="Align image left"
          :active="editor.isActive('imageBlock', { align: 'left' })"
          @click="onAlignImageLeft"
      >
        <Icon name="AlignHorizontalDistributeStart"/>
      </ToolbarButton>
      <ToolbarButton
          tooltip="Align image center"
          :active="editor.isActive('imageBlock', { align: 'center' })"
          @click="onAlignImageCenter"
      >
        <Icon name="AlignHorizontalDistributeCenter"/>
      </ToolbarButton>
      <ToolbarButton
          tooltip="Align image right"
          :active="editor.isActive('imageBlock', { align: 'right' })"
          @click="onAlignImageRight"
      >
        <Icon name="AlignHorizontalDistributeEnd"/>
      </ToolbarButton>
      <ToolbarDivider/>
      <ImageBlockWidth
          :value="parseInt(editor.getAttributes('imageBlock').width)"
          @change="onWidthChange"
      />
    </ToolbarWrapper>
  </BubbleMenu>
</template>
<script setup>
import { computed, ref } from 'vue'
import getRenderContainer from "@/tiptap/lib/utils/getRenderContainer";
import { BubbleMenu } from "@tiptap/vue-3";
import { sticky } from "tippy.js";
import ToolbarWrapper from "@/tiptap/components/ui/toolbar/ToolbarWrapper.vue";
import Icon from "@/tiptap/components/ui/Icon.vue";
import ToolbarDivider from "@/tiptap/components/ui/toolbar/ToolbarDivider.vue";
import ToolbarButton from "@/tiptap/components/ui/toolbar/ToolbarButton.vue";
import ImageBlockWidth from "@/tiptap/extensions/ImageBlock/components/ImageBlockWidth.vue";

const props = defineProps({
  editor: Object,
  appendTo: Object
})

const tippyInstance = ref(null)

const getReferenceClientRect = () => {
  const renderContainer = getRenderContainer(props.editor, 'node-imageBlock')
  const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)
  return rect
}

const pluginKey = computed(() => {
  return `imageBlockMenu-${crypto.randomUUID()}`
})
const shouldShow = () => {
  const isActive = props.editor.isActive('imageBlock')
  return isActive
}

const onAlignImageLeft = () => {
  props.editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('left').run()
}

const onAlignImageCenter = () => {
  props.editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('center').run()
}

const onAlignImageRight = () => {
  props.editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('right').run()
}

const onWidthChange = (value) => {
  props.editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockWidth(value).run()
}

const onCreate = (instance) => {
  tippyInstance.value = instance
}

const tippyOptions = {
  offset: [0, 8],
  popperOptions: {
    modifiers: [{ name: 'flip', enabled: false }],
  },
  getReferenceClientRect,
  onCreate,
  appendTo: () => props.appendTo,
  plugins: [sticky],
  sticky: 'popper',
}

</script>
