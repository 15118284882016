import {mergeAttributes, Range} from '@tiptap/core'

import {Image} from '../Image'
import {VueNodeViewRenderer} from "@tiptap/vue-3";
import ResizableNodeTemplate from '../Embed/components/ResizableNodeTemplate.vue';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        imageBlock: {
            setImageBlock: (attributes: { src: string }) => ReturnType
            setImageBlockAt: (attributes: { src: string; pos: number | Range }) => ReturnType
            setImageBlockAlign: (align: 'left' | 'center' | 'right') => ReturnType
            setImageBlockWidth: (width: number) => ReturnType
        }
    }
}

export const ImageBlock = Image.extend({
    name: 'imageBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            ...this.parent?.(),
            width: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        width: attributes.width
                    };
                }
            },
            height: {
                default: 'auto',
                renderHTML: (attributes) => {
                    return {
                        height: attributes.height
                    };
                }
            },
            isDraggable: {
                default: true,
                renderHTML: (attributes) => {
                    return {};
                }
            },
            uuid: {
                default: '',
            }
        };
    },

    renderHTML({HTMLAttributes}) {
        return ['resizable-node', mergeAttributes(HTMLAttributes)];
    },

    addCommands() {
        return {
            setImageBlock:
                attrs =>
                    ({commands}) => {
                        return commands.insertContent({type: 'imageBlock', attrs: {src: attrs.src}})
                    },

            setImageBlockAt:
                attrs =>
                    ({commands}) => {
                        return commands.insertContentAt(attrs.pos, {type: 'imageBlock', attrs: {src: attrs.src}})
                    },

            setImageBlockAlign:
                align =>
                    ({commands}) =>
                        commands.updateAttributes('imageBlock', {align}),

            setImageBlockWidth:
                width =>
                    ({commands}) =>
                        commands.updateAttributes('imageBlock', {width: `${Math.max(0, Math.min(100, width))}%`}),
        }
    },

    addNodeView() {
        return VueNodeViewRenderer(ResizableNodeTemplate);
    }
})

export default ImageBlock
