<template>
    <div>
        <div v-if="loading" class="h-[60px] bg-gray-100 animate-pulse rounded w-full"/>
        <div v-else-if="media && !loading && !canPreview"
             class="flex w-full justify-between items-center"
             @click="previewFile"
        >
            <FileInfo :data="media" :loading="false"/>
            <div>
                <Button variant="ghost" size="iconLarge" @click.stop="downloadFile">
                    <Icon name="Download"/>
                </Button>
            </div>
        </div>
        <div v-if="loading && canPreview" class="h-[600px] bg-gray-100 animate-pulse rounded w-full"/>
        <div v-else-if="media && !loading && canPreview">
            <div class="relative group">
                <FilePreviewDetail
                    :data="media"
                    :show-toolbar="false"
                    detail-class="w-full min-h-[600px] h-[600px] rounded"
                />
                <div
                    class="absolute rounded opacity-0 group-hover:opacity-100 bg-black bg-opacity-40 inset-0 h-full w-full flex items-center justify-center">
                    <Button
                        variant="tertiary"
                        class="w-[120px]"
                        @click="previewFile">
                        <Icon name="Eye"></Icon>
                        <span>View</span>
                    </Button>
                </div>
            </div>
        </div>
        <FilePreviewDialog
            v-model:visible="showPreviewDialog"
            :media="media"
        />
    </div>
</template>
<script setup lang="ts">

import FileInfo from "../../../../Components/FileInfo.vue";
import Button from "../button/Button.vue";
import Icon from "../Icon.vue";
import {computed, ref} from "vue";
import {isPreViewable} from '../../../../utils/documentUtils'
import FilePreviewDialog from "../../../../Components/FilePreviewDialog.vue";
import FilePreviewDetail from "../../../../Components/FilePreviewDetail.vue";

const props = defineProps({
    loading: Boolean,
    media: Object,
    canPreview: {
        type: Boolean,
        default: true,
    }
})

const showPreviewDialog = ref(false)

const downloadUrl = computed(() => {
    return `/media/download/${props.media?.id}`
})

const canPreview = computed(() => {
    if (! props.canPreview) {
        return false;
    }

    return isPreViewable(props.media)
})

function previewFile() {
    showPreviewDialog.value = true
}

function downloadFile() {
    window.open(downloadUrl.value, '_blank')
}
</script>
